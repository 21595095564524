import type { ReactNode } from "react";
import {
  Button,
  CircularProgress,
  Container,
  Fade,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import { authenticationService } from "@sdeapps/react-core";

/**
 * Page appelée si l'utilisateur n'est pas connecté; lance la connexion
 */
function ConnectionPage(): ReactNode {
  return (
    <Container maxWidth="md">
      <Grid
        container
        direction="column"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}>
        <Grid sx={{ marginTop: 2 }}>
          <CircularProgress />
        </Grid>
        <Grid>
          <Typography>Connexion en cours...</Typography>
        </Grid>
        <Grid>
          {/* Petite sécurité supplémentaire : Un bouton qui apparaît
              au bout de 3s pour lancer manuellement la connexion */}
          <Fade style={{ transitionDelay: `3000ms` }} timeout={1000} easing="ease-out" in>
            <Button
              onClick={() => {
                void authenticationService.login();
              }}
              variant="contained">
              Connexion manuelle
            </Button>
          </Fade>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ConnectionPage;
