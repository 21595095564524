import { type ReactNode } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  Typography,
} from "@mui/material";
import type { SelectChangeEvent, SxProps, Theme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTransfertsEditionContext } from "../store/transfertsEditionContext";
import {
  TransfertsEditionActionType,
  TransfertsEditionReducerActionType,
} from "../store/transfertsEditionTypes";
import type { TransfertsEditionActionData } from "../store/transfertsEditionTypes";
import SaveIcon from "@mui/icons-material/Save";
import EpciAutocompleteWithErrorBoundary from "components/inputs/EpciAutocomplete";
import LoadingButton from "components/LoadingButton";
import { RaisonRetraitTransfertLibelles } from "models";
import type { RaisonRetraitTransfert } from "models";
import PerimetreAutoComplete from "components/inputs/PerimetreAutoComplete";

function getInputDisplayAndValidator(type?: TransfertsEditionActionType): {
  displays: {
    epci: boolean;
    perimetre: boolean;
    dateEffet: boolean;
    raisonDuRetrait: boolean;
  };
  areInputsValids: (params: TransfertsEditionActionData, selectionLength: number) => boolean;
} {
  switch (type) {
    case TransfertsEditionActionType.ReaffectationEPCI:
    case TransfertsEditionActionType.DirectIndirect:
      return {
        displays: {
          epci: true,
          perimetre: false,
          dateEffet: true,
          raisonDuRetrait: false,
        },
        areInputsValids: (params, selection) =>
          selection > 0 && params.dateEffet != null && params.idCible != null,
      };
    case TransfertsEditionActionType.IndirectDirect:
      return {
        displays: {
          epci: false,
          perimetre: false,
          dateEffet: true,
          raisonDuRetrait: false,
        },
        areInputsValids: (params, selection) =>
          selection > 0 && params.dateEffet != null && params.idCible == null,
      };
    case TransfertsEditionActionType.RetraitTransfert:
      return {
        displays: {
          epci: false,
          perimetre: false,
          dateEffet: true,
          raisonDuRetrait: true,
        },
        areInputsValids: (params, selection) =>
          selection > 0 && params.dateEffet != null && params.raisonDuRetrait != null,
      };
    case TransfertsEditionActionType.SuppressionTransfert:
      return {
        displays: {
          epci: false,
          perimetre: false,
          dateEffet: false,
          raisonDuRetrait: false,
        },
        areInputsValids: (_, selection) => selection > 0,
      };
    case TransfertsEditionActionType.ReaffectationPerimetre:
      return {
        displays: {
          epci: false,
          perimetre: true,
          dateEffet: true,
          raisonDuRetrait: false,
        },
        areInputsValids: (params, selection) =>
          selection > 0 && params.dateEffet != null && params.idCible != null,
      };
    default:
      return {
        displays: { epci: false, perimetre: false, dateEffet: false, raisonDuRetrait: false },
        areInputsValids: () => false,
      };
  }
}

function getFormControlStyle(
  value: TransfertsEditionActionType,
  actionUtilisateur: TransfertsEditionActionType | undefined
): SxProps<Theme> {
  let color = "";
  switch (value) {
    case TransfertsEditionActionType.SuppressionTransfert:
      color = "error.main";
      break;
    case actionUtilisateur:
      color = "info.main";
      break;
  }

  return {
    ".MuiFormControlLabel-label": {
      color,
      fontWeight: actionUtilisateur === value ? 600 : undefined,
    },
  };
}

/**
 * Clés/Valeurs de l'énum `TransfertsEditionActionType` utilisées pour construire la liste d'actions disponibles.
 */
const actionsPossibles = Object.entries(TransfertsEditionActionType).map(
  ([action, actionString]) => ({
    key: action,
    value: actionString,
  })
);

interface TransfertEditionToolbarProps {
  onSave: () => void;
  isSending: boolean;
}
function TransfertEditionToolbar({
  onSave,
  isSending,
}: Readonly<TransfertEditionToolbarProps>): ReactNode {
  const {
    state: {
      filteredTransferts,
      selectedTransferts,
      actionUtilisateur,
      actionParametres,
      sameCompetencePerimetres,
      isLoading,
    },
    dispatch,
  } = useTransfertsEditionContext();

  const { displays, areInputsValids } = getInputDisplayAndValidator(actionUtilisateur);

  const transfertLength = filteredTransferts.length;
  const selectedLength = selectedTransferts.length;

  const canSave = areInputsValids(actionParametres, selectedLength);

  return (
    <Grid
      container
      size={12}
      sx={{
        position: "sticky",
        top: "164px",
        zIndex: 99,
        backgroundColor: "white",
        borderRadius: 1,
        border: "1px solid rgba(224, 224, 224, 1)",
        px: 2,
        py: 1,
      }}>
      <Grid container spacing={1} size={12}>
        {actionsPossibles.map(({ key, value }) => (
          <Grid key={key} size={{ xs: 6, sm: 4, lg: 2 }}>
            <FormControlLabel
              value={key}
              label={value}
              sx={getFormControlStyle(value, actionUtilisateur)}
              control={
                <Radio
                  color={
                    value === TransfertsEditionActionType.SuppressionTransfert ? "error" : undefined
                  }
                  name="action-selectionnee"
                  checked={actionUtilisateur === value}
                  onChange={() => {
                    dispatch({
                      type: TransfertsEditionReducerActionType.USER_ACTION,
                      payload: value,
                    });
                  }}
                />
              }
            />
          </Grid>
        ))}
        {actionUtilisateur != null && (
          <>
            <Grid
              container
              sx={{ order: { xs: 3, lg: 1 }, alignContent: "center" }}
              size={{ xs: 6, lg: 2 }}>
              <Grid size={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      indeterminate={selectedLength > 0 && selectedLength < transfertLength}
                      checked={selectedLength > 0 && selectedLength === transfertLength}
                      onClick={() => {
                        const type =
                          selectedTransferts.length < filteredTransferts.length
                            ? TransfertsEditionReducerActionType.SELECT_ALL
                            : TransfertsEditionReducerActionType.UNSELECT_ALL;
                        dispatch({ type });
                      }}
                      sx={{ py: 0 }}
                    />
                  }
                  label={selectedLength < transfertLength ? "Tout cocher" : "Tout décocher"}
                />
              </Grid>
              <Grid size={12}>
                <Typography variant="caption">
                  {selectedLength} sélectionné{selectedLength > 1 ? "s" : ""} sur {transfertLength}
                </Typography>
              </Grid>
            </Grid>

            <Grid sx={{ order: { xs: 1, lg: 2 } }} size={{ xs: 12, sm: 6, lg: 4 }}>
              {displays.epci && (
                <EpciAutocompleteWithErrorBoundary
                  key={actionUtilisateur}
                  onChange={(epci) => {
                    dispatch({
                      type: TransfertsEditionReducerActionType.USER_PARAMETRES,
                      payload: { idCible: epci?.id },
                    });
                  }}
                />
              )}
              {displays.perimetre && (
                <PerimetreAutoComplete
                  key={actionUtilisateur}
                  onChange={(perimetre) => {
                    dispatch({
                      type: TransfertsEditionReducerActionType.USER_PARAMETRES,
                      payload: { idCible: perimetre?.id },
                    });
                  }}
                  perimetres={sameCompetencePerimetres}
                  isLoading={isLoading}
                />
              )}
              {displays.raisonDuRetrait && (
                <FormControl key={actionUtilisateur} fullWidth>
                  <InputLabel variant="outlined">Raison de retrait</InputLabel>
                  <Select
                    value={actionParametres.raisonDuRetrait ?? ""}
                    input={<OutlinedInput label="Raison de retrait" />}
                    onChange={({
                      target: { value },
                    }: SelectChangeEvent<RaisonRetraitTransfert>) => {
                      dispatch({
                        type: TransfertsEditionReducerActionType.USER_PARAMETRES,
                        payload: { raisonDuRetrait: value as RaisonRetraitTransfert | undefined },
                      });
                    }}>
                    {RaisonRetraitTransfertLibelles.map((raison) => (
                      <MenuItem key={raison} value={raison}>
                        {raison}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid sx={{ order: { xs: 2, lg: 3 } }} size={{ xs: 12, sm: 6, lg: 3 }}>
              {displays.dateEffet && (
                <DatePicker
                  key={actionUtilisateur}
                  label="Date d'effet *"
                  format="dd/MM/yyyy"
                  onChange={(dateEffet: Date | null) => {
                    dispatch({
                      type: TransfertsEditionReducerActionType.USER_PARAMETRES,
                      payload: { dateEffet: dateEffet ?? undefined },
                    });
                  }}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              )}
            </Grid>
            <Grid
              container
              sx={{ justifyContent: "flex-end", order: { xs: 4, lg: 4 } }}
              size={{ xs: 6, lg: "grow" }}>
              <Grid>
                <LoadingButton
                  size="large"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  fullWidth={true}
                  onClick={onSave}
                  disabled={!canSave}
                  loading={isSending}>
                  Confirmer
                </LoadingButton>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default TransfertEditionToolbar;
