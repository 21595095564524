import type { ReactNode } from "react";
import { useController } from "react-hook-form";
import type { FieldValues, FieldPath } from "react-hook-form";
import { Autocomplete, TextField, Typography } from "@mui/material";
import InputSkeleton from "components/InputSkeleton";
import type Fonction from "../models/Fonction";
import type { ControlledInput } from "utils";

interface NewMandatFonctionsAutoCompleteProps<T extends FieldValues, Name extends FieldPath<T>>
  extends ControlledInput<T, Name> {
  label: string;
  fonctions: Array<Fonction>;
  isLoading?: boolean;
}

function NewMandatFonctionsAutoComplete<T extends FieldValues, Name extends FieldPath<T>>({
  name,
  label,
  control,
  rules,
  fonctions,
  isLoading = false,
}: Readonly<NewMandatFonctionsAutoCompleteProps<T, Name>>): ReactNode {
  const {
    field: { onChange, ref },
    fieldState: { error },
  } = useController({ name, control, rules });

  if (isLoading) {
    return <InputSkeleton />;
  }

  return (
    <Autocomplete
      fullWidth
      autoHighlight
      openOnFocus
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={fonctions}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      isOptionEqualToValue={(v1: Fonction, v2: Fonction): boolean => v1.id === v2.id}
      getOptionLabel={(fonction: Fonction) => fonction.libelle}
      renderOption={(props, option) => (
        <Typography component="li" {...props} sx={{ display: "block" }} key={option.id}>
          {option.libelle}
        </Typography>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ?? "Fonction"}
          inputRef={ref}
          error={error != null}
          helperText={error?.message}
        />
      )}
    />
  );
}

export default NewMandatFonctionsAutoComplete;
