import type { ReactNode } from "react";
import { Grid2 as Grid, Link, Typography, useTheme } from "@mui/material";
import type { RangeTuple, FuseResultMatch } from "fuse.js";

// eslint-disable-next-line react-refresh/only-export-components
export const SEARCH_HIGHTLIGHT_CLASS_NAME = "highlight-matches";

function fuseOverlappingTuples(
  tuplesToFuse: ReadonlyArray<RangeTuple>,
  quotesIndexes: Array<number> = []
): Array<RangeTuple> {
  if (tuplesToFuse.length === 0) {
    return [];
  }

  let _tuplesTofuse = [...tuplesToFuse];
  [...quotesIndexes].reverse().forEach((apoIndex) => {
    _tuplesTofuse = _tuplesTofuse.map(([start, end]) => [
      start >= apoIndex ? start - 1 : start,
      end >= apoIndex ? end - 1 : end,
    ]);
  });

  const fusedTuples: Array<RangeTuple> = [_tuplesTofuse[0]];

  _tuplesTofuse.forEach(([start1, end1]) => {
    let fused = false;
    fusedTuples.forEach(([start2, end2], index) => {
      if (end1 >= start2 && start1 <= end2) {
        fusedTuples[index] = [Math.min(start1, start2), Math.max(end1, end2)];
        fused = true;
      }
    });
    if (!fused) {
      fusedTuples.push([start1, end1]);
    }
  });

  return fusedTuples;
}

function HighlightedText(
  inputText: string,
  matchesIndices: ReadonlyArray<RangeTuple> = []
): ReactNode {
  const content: Array<ReactNode> = [];
  let nextUnhighlightedRegionStartingIndex = 0;

  const sortedIndices = fuseOverlappingTuples(
    matchesIndices.toSorted((indices1, indices2) => indices1[0] - indices2[0])
  );

  sortedIndices.forEach((region, i) => {
    const lastRegionNextIndex = region[1] + 1;

    content.push(inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]));
    content.push(
      <span key={`${inputText}highlight${i}`} className={SEARCH_HIGHTLIGHT_CLASS_NAME}>
        {inputText.substring(region[0], lastRegionNextIndex)}
      </span>
    );

    nextUnhighlightedRegionStartingIndex = lastRegionNextIndex;
  });

  content.push(inputText.substring(nextUnhighlightedRegionStartingIndex));

  return content;
}

interface SearchResultCardProps {
  icon: ReactNode;
  name: string;
  departement?: string;
  url: string;
  type: string;
  subtitle?: ReactNode;
  matches?: ReadonlyArray<FuseResultMatch>;
}

/**
 * Affiche une Card représentant le résultat de la recherche.
 * @param icon ReactNode
 * @param name string
 * @param departement?: string
 * @param url string: adresse vers laquelle rediriger au clic
 * @param type string
 */
export function SearchResultCard({
  icon,
  name,
  departement,
  url,
  type,
  subtitle,
  matches,
}: Readonly<SearchResultCardProps>): ReactNode {
  const theme = useTheme();

  const searchResultCardStyles = {
    borderRadius: 2,
    position: "relative",
    p: 2,
    background: theme.palette.info.light,
    textDecoration: "none",
    cursor: "pointer",
    "&::after": {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      borderRadius: 2,
      content: "' '",
      boxShadow: theme.shadows[2],
      opacity: 0,
      transition: "opacity 0.3s ease-out",
    },
    "&:hover::after": {
      opacity: 1,
    },
  };

  return (
    <Grid
      container
      component={Link}
      href={url}
      underline="none"
      size={12}
      sx={{
        ...searchResultCardStyles,
        alignContent: "center",
        columnGap: 2,
        textDecoration: "none",
      }}>
      <Grid container sx={{ color: "text.primary" }} size="auto">
        {icon}
      </Grid>
      <Grid container direction="column" size="grow">
        <Typography sx={{ fontWeight: 600 }}>
          {HighlightedText(name, matches?.at(0)?.indices)}{" "}
          {departement != null && (
            <Typography component="span" sx={{ fontWeight: "normal" }}>
              ({departement})
            </Typography>
          )}
        </Typography>
        <Typography variant="caption" sx={{ fontStyle: "italic" }}>
          {type}
        </Typography>
      </Grid>
      <Grid container size={12}>
        {subtitle}
      </Grid>
    </Grid>
  );
}
