import { useEffect, useState, type ReactNode } from "react";
import { Autocomplete, TextField, Typography, createFilterOptions } from "@mui/material";
import type { Perimetre } from "models";
import CompetenceChip from "components/Typography/CompetenceChip";
import { stringUtil } from "@sdeapps/react-core";
import InputSkeleton from "components/InputSkeleton";
import { perimetresService } from "services";
import { useErrorHandler } from "utils/errorHandling";

const filterOptions = createFilterOptions({
  trim: true,
  stringify: (option: Perimetre) => stringUtil.normalize(option.libelle),
});

interface PerimetreAutoCompleteProps {
  label?: string;
  perimetres?: Array<Perimetre>;
  onChange: (selectedPerimetre?: Perimetre | null) => void;
  isLoading?: boolean;
  noOptionsText?: string;
}

function PerimetreAutoComplete({
  label,
  perimetres: surchargedPerimetres,
  onChange,
  isLoading: surchargedIsLoading = false,
  noOptionsText,
}: Readonly<PerimetreAutoCompleteProps>): ReactNode {
  const [perimetres, setPerimetres] = useState<Array<Perimetre>>(surchargedPerimetres ?? []);
  const { catchErrors, isLoading } = useErrorHandler();

  useEffect(() => {
    void catchErrors(async function getPerimetres(): Promise<void> {
      setPerimetres(
        (surchargedPerimetres == null
          ? await perimetresService.getAll()
          : [...surchargedPerimetres]
        ).sort((p1, p2) =>
          stringUtil.normalize(p1.libelle).localeCompare(stringUtil.normalize(p2.libelle))
        )
      );
    });
  }, [catchErrors, surchargedPerimetres]);

  if (surchargedIsLoading || (isLoading && surchargedPerimetres == null)) {
    return <InputSkeleton />;
  }

  return (
    <Autocomplete
      fullWidth
      autoHighlight
      openOnFocus
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={perimetres}
      filterOptions={filterOptions}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      isOptionEqualToValue={(v1: Perimetre, v2: Perimetre): boolean => v1.id === v2.id}
      getOptionLabel={(perimetre: Perimetre) => `${perimetre.libelle} - ${perimetre.competence}`}
      renderOption={(props, option) => (
        <Typography component="li" {...props} sx={{ display: "block" }} key={option.id}>
          {option.libelle} <CompetenceChip sx={{ marginLeft: 1 }} competence={option.competence} />
        </Typography>
      )}
      renderInput={(params) => (
        <TextField {...params} label={label ?? "Perimetre *"} placeholder="Nom du Périmètre" />
      )}
      noOptionsText={noOptionsText}
    />
  );
}

export default PerimetreAutoComplete;
