import type { ReactNode } from "react";
import { Link, Typography } from "@mui/material";
import TypographySkeleton from "components/Links/TypographySkeleton";

interface AdresseSpanProps {
  mail: string | null | undefined;
  label?: string;
  isLink?: boolean;
  isLoading?: boolean;
  error?: boolean;
}

function MailSpan({
  mail,
  label,
  isLink = true,
  isLoading = false,
  error = false,
}: Readonly<AdresseSpanProps>): ReactNode {
  if (error) {
    return <Typography>Impossible de charger l'e-mail</Typography>;
  }

  if (isLoading) {
    return <TypographySkeleton />;
  }

  if (mail == null || mail === "") {
    return <Typography component="span">{label ?? "E-mail"} non renseigné</Typography>;
  }

  return (
    <Typography component="span">
      {label} {isLink ? <Link href={"mailto:" + mail?.trim()}>{mail}</Link> : mail}
    </Typography>
  );
}

export default MailSpan;
