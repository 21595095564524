import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import EtablissementPublicInformation from "components/Informations/EtablissementPublic/EtablissementPublicInformation";
import EtablissementPublicMap from "components/Maps/EtablissementPublicMap";
import PopulationSpan from "components/Span/PopulationSpan";
import type { Commune, EtablissementPublic } from "models";
import { etablissementsPublicsService } from "services";
import { etablissementUtil } from "utils";
import { useErrorHandler } from "utils/errorHandling";
import CommuneCommentaireWithErrorBoundary from "./CommuneCommentaireWithErrorBoundary";
import EtablissementPublicForm from "components/forms/EtablissementPublicForm";
import SigLink from "components/Links/SigLink";
import { HorairesWithErrorBoundary } from "components/Informations/HorairesWithErrorBoundary";

interface CommuneEtablissementPublicInformationsProps {
  commune: Commune;
  open: boolean;
  setOpen: (value: boolean) => void;
}

function CommuneEtablissementPublicInformations({
  commune,
  open,
  setOpen,
}: Readonly<CommuneEtablissementPublicInformationsProps>): ReactNode {
  const [etablissementPublicOpenData, setEtablissementPublicOpenData] = useState<
    EtablissementPublic | undefined
  >();
  const [etablissementPublicSdea, setEtablissementPublicSdea] = useState<
    EtablissementPublic | undefined
  >();

  const { error, catchErrors, isLoading } = useErrorHandler(true);

  useEffect(() => {
    async function getEtablissementPublic(): Promise<void> {
      const _etablissementsPublics = await etablissementsPublicsService.getMairiesByCodeInsee(
        commune.id
      );
      const [openData, sdea] =
        etablissementUtil.getEtablissementPublicFromSource(_etablissementsPublics);
      setEtablissementPublicOpenData(openData);
      setEtablissementPublicSdea(sdea);
    }

    void catchErrors(getEtablissementPublic);
  }, [catchErrors, commune]);

  return (
    <Grid
      container
      rowSpacing={4}
      columnSpacing={2}
      size={12}
      sx={{ alignItems: "flex-start", alignContent: "flex-start" }}>
      <Grid container spacing={4} size={{ md: 7 }}>
        {[etablissementPublicOpenData, etablissementPublicSdea].map(
          (e) =>
            e != null && (
              <EtablissementPublicInformation
                etablissementPublic={e}
                isLoading={isLoading}
                error={error != null}
                key={e.source}
              />
            )
        )}
        <Grid container spacing={1} size={12}>
          <Grid size={12}>
            <Typography>SIREN : {commune.siren}</Typography>
          </Grid>
          <Grid size={12}>
            <Typography>Canton : {commune.canton.libelle ?? ""}</Typography>
          </Grid>
          <Grid size={12}>
            <Typography>Arrondissement : {commune.arrondissement.libelle ?? ""}</Typography>
          </Grid>
          <Grid size={12}>
            <Typography>
              Population municipale aux dernières élections :{" "}
              <PopulationSpan
                population={commune.population.derniereElection.populationMunicipale}
                annee={commune.population.derniereElection.anneeMiseEnVigueur}
              />
            </Typography>
            <Typography>
              Population totale au dernier recensement :{" "}
              <PopulationSpan
                population={commune.population.actuelle.populationTotale}
                annee={commune.population.actuelle.anneeMiseEnVigueur}
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} size={{ xs: 12, md: 5 }} sx={{ justifyContent: "flex-end" }}>
        {commune.codeSdea != null && commune.codeSdea !== "" && (
          <Grid container size={12} sx={{ justifyContent: "flex-end" }}>
            <SigLink collectivite={commune} />
          </Grid>
        )}
        {etablissementPublicOpenData?.adresse != null && (
          <Grid size={12}>
            <EtablissementPublicMap
              adresse={etablissementPublicOpenData.adresse}
              isLoading={isLoading}
              error={error != null}
            />
          </Grid>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 6, xl: 8 }}>
        <CommuneCommentaireWithErrorBoundary
          idCommune={commune.id}
          commentaire={commune.commentaire}
        />
      </Grid>
      {etablissementPublicOpenData?.horaires != null &&
        etablissementPublicOpenData.horaires.length > 0 && (
          <Grid size={{ xs: 12, md: 6, xl: 4 }}>
            <HorairesWithErrorBoundary
              horaires={etablissementPublicOpenData.horaires}
              isLoading={isLoading}
              error={error != null}
            />
          </Grid>
        )}
      {!isLoading && (
        <EtablissementPublicForm
          etablissementPublic={
            etablissementPublicSdea ??
            ({
              id: etablissementPublicOpenData?.id,
              nom: etablissementPublicOpenData?.nom,
            } satisfies Partial<EtablissementPublic>)
          }
          open={open}
          setOpen={setOpen}
          idCollectivite={commune.id}
          upsertEtablissementCoordonnees={etablissementsPublicsService.putMairie}
          suppEtablissementCoordonnees={etablissementsPublicsService.deleteSurchargeSdeaMairie}
        />
      )}
    </Grid>
  );
}

export default CommuneEtablissementPublicInformations;
