import { useState, useEffect } from "react";
import type { ReactNode } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { grey } from "@mui/material/colors";
import type { Transfert } from "models";
import { porteesUtil } from "utils";
import PorteeAccordionDetailWithIcon from "./PorteeAccordionDetailWithIcon";

interface EauAssGepuPorteesAccordionProps {
  transferts: Array<Transfert>;
  porteeIds?: Array<number>;
  porteeAdditionnellesIds?: Array<number>;
  sousCompetenceId: string;
}

function EauAssGepuPorteesAccordion({
  transferts,
  porteeIds,
  porteeAdditionnellesIds,
  sousCompetenceId,
}: Readonly<EauAssGepuPorteesAccordionProps>): ReactNode {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [competence, setCompetence] = useState<"EAU" | "ASS">(
    porteesUtil.getCompetence(transferts[0]) as "EAU" | "ASS"
  );

  useEffect(() => {
    const comp = porteesUtil.getCompetence(transferts[0]) as "EAU" | "ASS";
    setCompetence(comp);
  }, [transferts]);

  if (porteeIds == null && porteeAdditionnellesIds == null) {
    return null;
  }

  return (
    <Grid size={12}>
      <Accordion
        sx={{
          width: "100%",
          border: "none",
          background: "transparent",
        }}
        disableGutters
        variant="outlined"
        expanded={isAccordionOpen}
        onChange={() => {
          setIsAccordionOpen(!isAccordionOpen);
        }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ flexDirection: "row-reverse", background: "transparent" }}>
          <Typography sx={{ ml: 1 }}>
            {!isAccordionOpen &&
              porteeIds?.map((porteeId, i) => {
                const compCode = `${competence}-${sousCompetenceId}-${porteeId}`;
                const isPorteeAdheree = transferts.find((t) => t.code === compCode) != null;
                return (
                  <Typography
                    key={porteeId}
                    color={isPorteeAdheree ? undefined : grey[400]}
                    component="span">
                    {i > 0 && ","} {porteesUtil.getPorteeLabel(compCode)}
                  </Typography>
                );
              })}
            {!isAccordionOpen &&
              porteeAdditionnellesIds?.map((porteeId) => {
                const compCode = `${competence}-${sousCompetenceId}-${porteeId}`;
                const isPorteeAdheree = transferts.find((t) => t.code === compCode) != null;
                if (!isPorteeAdheree) {
                  return null;
                }
                return (
                  <Typography key={porteeId} component="span">
                    , {porteesUtil.getPorteeLabel(compCode)}
                  </Typography>
                );
              })}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginTop: -6.5, marginLeft: 3, paddingBottom: 0 }}>
          <Grid container>
            {porteeIds?.map((porteeId) => {
              const compCode = `${competence}-${sousCompetenceId}-${porteeId}`;
              const isPorteeAdheree = transferts.find((t) => t.code === compCode) != null;
              return (
                <PorteeAccordionDetailWithIcon
                  size={12}
                  key={porteeId}
                  marginLeft={1}
                  isPorteeAdheree={isPorteeAdheree}
                  transfert={compCode}
                />
              );
            })}
            {porteeAdditionnellesIds?.map((porteeId) => {
              const compCode = `${competence}-${sousCompetenceId}-${porteeId}`;
              const isPorteeAdheree = transferts.find((t) => t.code === compCode) != null;

              return (
                isPorteeAdheree && (
                  <PorteeAccordionDetailWithIcon
                    size={12}
                    key={porteeId}
                    marginLeft={1}
                    isPorteeAdheree={isPorteeAdheree}
                    transfert={compCode}
                  />
                )
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

export default EauAssGepuPorteesAccordion;
