import type { ReactNode } from "react";
import { Autocomplete, TextField, Typography, createFilterOptions } from "@mui/material";
import { useController } from "react-hook-form";
import type { FieldValues, FieldPath } from "react-hook-form";
import type { Perimetre } from "models";
import CompetenceChip from "components/Typography/CompetenceChip";
import { stringUtil } from "@sdeapps/react-core";
import InputSkeleton from "components/InputSkeleton";
import type { ControlledInput } from "utils";

const filterOptions = createFilterOptions({
  trim: true,
  stringify: (option: Perimetre) => stringUtil.normalize(option.libelle),
});

interface ControlledPerimetreAutoCompleteProps<T extends FieldValues, Name extends FieldPath<T>>
  extends ControlledInput<T, Name> {
  label: string;
  perimetres: Array<Perimetre>;
  isLoading?: boolean;
  noOptionsText?: string;
}

function ControlledPerimetreAutoComplete<T extends FieldValues, Name extends FieldPath<T>>({
  name,
  label,
  control,
  rules,
  perimetres,
  isLoading = false,
  noOptionsText,
}: Readonly<ControlledPerimetreAutoCompleteProps<T, Name>>): ReactNode {
  const {
    field: { onChange, ref, value },
    fieldState: { error },
  } = useController({ name, control, rules });

  if (isLoading) {
    return <InputSkeleton />;
  }

  return (
    <Autocomplete
      fullWidth
      autoHighlight
      openOnFocus
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={perimetres}
      filterOptions={filterOptions}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      value={value}
      isOptionEqualToValue={(v1: Perimetre, v2: Perimetre): boolean => v1.id === v2.id}
      getOptionLabel={(perimetre: Perimetre) => `${perimetre.libelle} - ${perimetre.competence}`}
      renderOption={(props, option) => (
        <Typography component="li" {...props} sx={{ display: "block" }} key={option.id}>
          {option.libelle} <CompetenceChip sx={{ marginLeft: 1 }} competence={option.competence} />
        </Typography>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ?? "Perimetre *"}
          placeholder="Nom du Périmètre"
          inputRef={ref}
          error={error != null}
          helperText={error?.message}
        />
      )}
      noOptionsText={noOptionsText}
    />
  );
}

export default ControlledPerimetreAutoComplete;
