import type { ReactNode } from "react";
import { Collapse, Grid2 as Grid, collapseClasses } from "@mui/material";
import { SearchInput, SearchResults, SearchCategories } from "components/Search/";
import TitleImage from "assets/titre-collectivites-minified.svg";
import { useSearch } from "providers";

function MainSearch(): ReactNode {
  const { searchString } = useSearch();

  return (
    <Grid container>
      <Collapse
        in={searchString.length <= 1}
        sx={{ [`&.${collapseClasses.root}`]: { width: "100%" } }}>
        <Grid container size={12} sx={{ justifyContent: "center" }}>
          <Grid container sx={{ m: 5 }} size={{ xs: 11, sm: 8, md: 4 }}>
            <img src={TitleImage} alt="Collectivités" width="100%" height="100%" />
          </Grid>
        </Grid>
      </Collapse>
      <Grid container size={12} sx={{ justifyContent: "center" }}>
        <Grid container rowSpacing={2} size={{ xs: 12, md: 6 }} sx={{ justifyContent: "center" }}>
          <Grid
            container
            sx={{ position: "sticky", zIndex: 2, top: "56px", background: "white", pb: 1 }}
            size={12}>
            <SearchCategories size={{ sm: "auto", md: 4 }} sx={{ minWidth: "70px" }} />
            <SearchInput size={{ xs: "grow", md: 8 }} autoFocus />
          </Grid>
          <SearchResults size={12} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MainSearch;
