import { Endpoints } from "constants/endpoints";
import type {
  Personne,
  PersonneInfosPrivees,
  PatchData,
  DelegueAssembleeGenerale,
  Perimetre,
} from "models";
import { elusUtil } from "utils";
import { elusApiClient } from "./apiClients";

/**
 * Récupère toutes les personnes
 * @returns Array<Personne>
 */
async function getAllPersonnes(): Promise<Array<Personne>> {
  const url = `/${Endpoints.PERSONNES}`;
  return elusApiClient.get(url);
}

/**
 * Récupère une personne à partir de son id
 * @param personneId l'id de la personne à chercher
 * @returns La personne dont l'id a été passé en paramètre
 */
async function getPersonneById(personneId: string): Promise<Personne> {
  const url = `/${Endpoints.PERSONNES}/${personneId}`;
  return elusApiClient.get(url);
}

/**
 * Récupère les données privées d'une personne à partir de son id
 * @param personneId l'id de la personne à chercher
 * @returns La personne dont l'id a été passé en paramètre
 */
async function getPersonneInfosPriveesById(personneId: string): Promise<PersonneInfosPrivees> {
  const url = `/${Endpoints.PERSONNES}/${personneId}/privees`;
  return elusApiClient.get(url);
}

/**
 * Récupère les personnes qui possèdent des mandats en cours liés à une Commune
 * @param codeCollectivite l'id de la Commune
 * @returns une liste de personnes
 */
async function getPersonnesByCommune(idCommune: string): Promise<Array<Personne>> {
  const url = `/${Endpoints.PERSONNES}/collectivites/commune/${idCommune}`;
  return elusApiClient.get(url);
}

/**
 * Récupère les personnes qui possèdent des mandats en cours liés à une Commune
 * @param codeCollectivite l'id de la Commune
 * @returns une liste de personnes
 */
async function getPersonnesByEpci(idEpci: string): Promise<Array<Personne>> {
  const url = `/${Endpoints.PERSONNES}/collectivites/epci/${idEpci}`;
  return elusApiClient.get(url);
}

/**
 * Récupère les personnes qui possèdent des mandats en cours liés à un type de collectivité
 * @param typeCollectivite le type de collectivité
 * @returns une liste de personnes
 */
async function getPersonnesByTypeCollectivite(typeCollectivite: string): Promise<Array<Personne>> {
  const url = `/${Endpoints.PERSONNES}/collectivites/${typeCollectivite}`;
  return elusApiClient.get(url);
}

/**
 * **TODO remplacer par un vrai endpoint dès que possible, voir US [#9247](https://dev.azure.com/SDEA/SDEApps/_workitems/edit/9247)
 * et US [#10796](https://dev.azure.com/SDEA/SDEApps/_workitems/edit/10796)**
 *
 * Récupère des personnes qui possèdent des mandats en cours liés à un Périmètre
 * @param idPerimetre le Périmètre
 * @returns une liste de personnes
 */
async function getPersonnesByPerimetre(perimetre: Perimetre): Promise<Array<Personne>> {
  const elus = await Promise.all([
    getPersonnesByTypeCollectivite("CGEO"),
    getPersonnesByTypeCollectivite("TRR"),
  ]);

  const _perimetreElus = elusUtil.fuseMandatsIntoPersonnes(elus.flat());

  const perimetreElus: Array<Personne> = _perimetreElus.filter(
    (e) =>
      e.mandats?.some(
        (m) => m.idPerimetre === perimetre.id && m.competence === perimetre.competence
      ) ?? false
  );

  return perimetreElus;
}

/**
 * **TODO remplacer par un vrai endpoint dès que possible, voir US [#9247](https://dev.azure.com/SDEA/SDEApps/_workitems/edit/9247)
 * et US [#10796](https://dev.azure.com/SDEA/SDEApps/_workitems/edit/10796)**
 *
 * Récupère des personnes qui possèdent des mandats en cours liés à un Territoire
 * @param idPerimetre le Territoire
 * @returns une liste de personnes
 */
async function getPersonnesByTerritoire(idTerritoire: string): Promise<Array<Personne>> {
  const elus = await Promise.all([
    getPersonnesByTypeCollectivite("SDEA"),
    getPersonnesByTypeCollectivite("CGEO"),
    getPersonnesByTypeCollectivite("TRR"),
  ]);

  const territoireElus = elusUtil.fuseMandatsIntoPersonnes(
    elus.flat().filter((p) => p.mandats?.some((m) => m.idTerritoire === idTerritoire) ?? false)
  );

  return territoireElus;
}

/**
 * Modifie les données d'un élus
 * @param personneId l'identifiant de la personne à modifier
 * @param patchData la liste des propriétés à modifier
 * @returns la personne modifiée
 */
async function patchElu(personneId: string, patchData: Array<PatchData>): Promise<Personne> {
  const url = `/${Endpoints.PERSONNES}/${personneId}/privees`;
  return elusApiClient.patch(url, patchData);
}

/**
 * Récupère les personnes qui ont des mandats de délégués à l'Assemblée Générale, avec seulement ces mandats.
 * @returns une liste de personnes et leurs informations privées
 */
async function getDeleguesAssembleeGenerale(): Promise<Array<DelegueAssembleeGenerale>> {
  const url = `/${Endpoints.DELEGUES}`;
  return elusApiClient.get(url);
}

export const elusService = {
  getAllPersonnes,
  getPersonneById,
  getPersonneInfosPriveesById,
  getPersonnesByCommune,
  getPersonnesByEpci,
  getPersonnesByTypeCollectivite,
  getPersonnesByPerimetre,
  getPersonnesByTerritoire,
  patchElu,
  getDeleguesAssembleeGenerale,
};
