import type { ReactNode } from "react";
import { CircularProgress, Grid2 as Grid } from "@mui/material";

function LoadingScreen(): ReactNode {
  return (
    <Grid container sx={{ justifyContent: "center", flexGrow: 1 }}>
      <Grid sx={{ marginY: "20vh" }}>
        <CircularProgress size="15vh" />
      </Grid>
    </Grid>
  );
}

export default LoadingScreen;
