import type { ReactNode } from "react";
import { Grid2 as Grid, Skeleton, type SxProps } from "@mui/material";

interface ListSkeletonProps {
  lines?: number;
  sx?: SxProps;
}

function ListSkeleton({ lines = 4, sx }: Readonly<ListSkeletonProps>): ReactNode {
  return (
    <Grid size={12}>
      {[...Array(lines).keys()].map((i) => (
        <Skeleton variant="text" animation="wave" key={i} sx={sx} />
      ))}
    </Grid>
  );
}

export default ListSkeleton;
