import { useState, useEffect } from "react";
import type { ReactNode } from "react";
import { SearchResultCard } from "./";
import { routesConfig } from "config/app-config";
import type { Personne } from "models";
import PersonIcon from "@mui/icons-material/Person";
import HigherMandatsSubtitle from "pages/Elu/components/HigherMandatsSubtitle";
import { elusService } from "services";
import { useErrorHandler } from "utils/errorHandling";
import type { FuseResultMatch } from "fuse.js";

interface EluSearchResultCardProps {
  elu: Personne;
  name: string;
  matches?: ReadonlyArray<FuseResultMatch>;
}

/**
 * Affiche une Card représentant le résultat de la recherche.
 * @param elu Personne
 * @param name string
 */
export function EluSearchResultCard({
  elu,
  name,
  matches,
}: Readonly<EluSearchResultCardProps>): ReactNode {
  const [fullElu, setFullElu] = useState<Personne>();

  const { error, catchErrors, isLoading } = useErrorHandler({
    dontThrow: true,
  });

  useEffect(() => {
    async function getFullElu(): Promise<void> {
      const _fullElu = await elusService.getPersonneById(elu.id);

      setFullElu(_fullElu);
    }

    void catchErrors(getFullElu);
  }, [catchErrors, elu]);

  return (
    <SearchResultCard
      icon={<PersonIcon />}
      name={name}
      url={routesConfig.elu.getParameterPath(elu.id)}
      type={elu.sexe === "F" ? "Élue" : "Élu"}
      subtitle={<HigherMandatsSubtitle elu={fullElu} error={error} isLoading={isLoading} noLink />}
      matches={matches}
    />
  );
}
