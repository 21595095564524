import { useState, useEffect, Fragment } from "react";
import type { ReactNode } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableBodyClasses,
  tableCellClasses,
  tableContainerClasses,
  tableRowClasses,
} from "@mui/material";
import type { Transfert } from "models";
import { porteesUtil } from "utils";
import { AssContrats, EauContrats, GepuContrats } from "constants/EauAssContrats";
import type { EauAssGepuContrat } from "constants/EauAssContrats";
import Competence from "constants/Competence";
import EauAssGepuSousCompetencesAccordionDetailsRowWithDates from "./SousCompetenceAccordionRow/EauAssGepuSousCompetencesAccordionDetailsRowWithDates";
import EauAssPorteesAccordionDetailsRowWithDates from "./SousCompetenceAccordionRow/EauAssGepuPorteesAccordionDetailsRowWithDates";

function getCompetenceContrats(competence: Competence): Array<EauAssGepuContrat> {
  switch (competence) {
    case Competence.EAU:
      return EauContrats;
    case Competence.ASS:
      return AssContrats;
    default:
      return GepuContrats;
  }
}

interface EauAssGepuSousCompetencesAccordionDetailsWithDatesProps {
  transferts: Array<Transfert>;
}

function EauAssGepuSousCompetencesAccordionDetailsWithDates({
  transferts: originalTransferts,
}: Readonly<EauAssGepuSousCompetencesAccordionDetailsWithDatesProps>): ReactNode {
  const [competence, setCompetence] = useState<Competence>(
    porteesUtil.getCompetence(originalTransferts[0])
  );
  const [contrats, setContrats] = useState<Array<EauAssGepuContrat>>([]);
  const [transferts, setTransferts] = useState<Array<Transfert>>([]);

  useEffect(() => {
    const _competence = porteesUtil.getCompetence(originalTransferts[0]);
    setCompetence(_competence);
    setTransferts(originalTransferts);
    setContrats(getCompetenceContrats(_competence));
  }, [originalTransferts]);

  return (
    <TableContainer
      sx={{
        [`&.${tableContainerClasses.root} .${tableBodyClasses.root} .${tableRowClasses.root}:last-child .${tableCellClasses.root}`]:
          { border: "none" },
      }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width="40px" />
            <TableCell>
              <Typography sx={{ fontWeight: "600" }}>Compétences et portées</Typography>
            </TableCell>
            <TableCell width="200px">
              <Typography sx={{ fontWeight: "600" }}>Délibération</Typography>
            </TableCell>
            <TableCell width="200px">
              <Typography sx={{ fontWeight: "600" }}>Début de transfert</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contrats.map((sousCompetence) => {
            const sousCompetenceTransferts = transferts.filter(
              (transfert) => porteesUtil.getSousCompetenceId(transfert) === sousCompetence.code
            );

            return (
              <Fragment key={sousCompetence.code}>
                <EauAssGepuSousCompetencesAccordionDetailsRowWithDates
                  competence={competence}
                  sousCompetence={sousCompetence}
                  transferts={sousCompetenceTransferts}
                />

                {sousCompetence.portees?.map((portee) => (
                  <EauAssPorteesAccordionDetailsRowWithDates
                    key={portee}
                    competence={competence}
                    sousCompetence={sousCompetence}
                    portee={portee}
                    transferts={sousCompetenceTransferts}
                  />
                ))}

                {sousCompetence.porteesAdditionnelles?.map((portee) => (
                  <EauAssPorteesAccordionDetailsRowWithDates
                    key={portee}
                    competence={competence}
                    sousCompetence={sousCompetence}
                    portee={portee}
                    transferts={sousCompetenceTransferts}
                    isPorteesAdditionnelles
                  />
                ))}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default EauAssGepuSousCompetencesAccordionDetailsWithDates;
