import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Button, Container, Grid2 as Grid, MenuItem, Typography } from "@mui/material";
import { Authorization, dateUtil } from "@sdeapps/react-core";
import { useNavigate } from "react-router-dom";
import { ApplicationRole } from "constants/ApplicationRole";
import PageTitle from "components/PageTitle";
import { routesConfig } from "config/app-config";
import ControlledDateTime from "components/inputs/ControlledDateTime";
import { useForm, useWatch } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import LoadingButton from "components/LoadingButton";
import type { FusionPerimetres, Perimetre } from "models";
import { competencesAssociationList } from "constants/CompetenceMap";
import { perimetresService } from "services";
import { useErrorHandler, withPageErrorBoundary, ErrorNames } from "utils/errorHandling";
import ControlledPerimetreAutoComplete from "components/inputs/ControlledPerimetreAutoComplete";
import ControlledTextField from "components/inputs/ControlledTextField";
import CompetenceChip from "components/Typography/CompetenceChip";
import type Competence from "constants/Competence";
import { enqueueSnackbar } from "notistack";
import ToastMessages from "constants/ToastMessages";
import InformationBox from "components/InformationBox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MergeTypeIcon from "@mui/icons-material/MergeType";

/**
 * Type _local_ d'objet à transmettre à l'API, mais avec une propriété "competence"
 * afin de permettre de conserver dans un state local la sélection de l'utilisateur.
 */
type FusionPerimetresFormData = {
  dateFusion: Date | null;
  competence: Competence | null;
  perimetrePrincipal: Perimetre | null;
  perimetreDissous: Perimetre | null;
};

const DEFAULT_VALUES: FusionPerimetresFormData = {
  dateFusion: null,
  competence: null,
  perimetrePrincipal: null,
  perimetreDissous: null,
};

const MAX_DATE: Date = dateUtil.addDuration(new Date(), { days: -1 });

function FusionPerimetresForm(): ReactNode {
  const navigate = useNavigate();
  const { catchErrors } = useErrorHandler();
  const { catchErrors: catchMergeErrors, isLoading: isSending } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: false,
    [ErrorNames.BadRequest]: (error) => {
      // Les périmètres n'ont pas été fusionnés à cause d'une faute dans la requête,
      // on précise laquelle à l'utilisateur
      enqueueSnackbar({
        variant: "error",
        message: error.message,
      });
    },
    default: () => {
      // Les périmètres n'ont pas été fusionnés, on affiche une snackbar d'erreur à l'utilisateur
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  const [perimetresExistants, setPerimetresExistants] = useState<Array<Perimetre>>([]);
  const [perimetresFiltres, setPerimetresFiltres] = useState<Array<Perimetre>>([]);
  useEffect(() => {
    async function getPerimetres(): Promise<void> {
      const _perimetres = await perimetresService.getAll();
      _perimetres.sort((a, b) => a.libelle.localeCompare(b.libelle));
      setPerimetresExistants(_perimetres);
    }
    void catchErrors(getPerimetres);
  }, [catchErrors]);

  const {
    control,
    formState: { isValid },
    handleSubmit,
    resetField,
  } = useForm<FusionPerimetresFormData>({
    shouldFocusError: false,
    defaultValues: DEFAULT_VALUES,
    mode: "onChange",
  });
  const { competence, perimetrePrincipal, perimetreDissous } = useWatch<FusionPerimetresFormData>({
    control,
  });

  useEffect(() => {
    setPerimetresFiltres(
      competence != null ? perimetresExistants.filter((p) => p.competence === competence) : []
    );
    resetField("perimetrePrincipal");
    resetField("perimetreDissous");
  }, [competence, perimetresExistants, resetField]);

  const sendData: SubmitHandler<FusionPerimetresFormData> = async function ({
    dateFusion,
    perimetrePrincipal,
    perimetreDissous,
  }: FusionPerimetresFormData): Promise<void> {
    if (dateFusion == null || perimetrePrincipal == null || perimetreDissous == null) {
      return;
    }

    await catchMergeErrors(async () => {
      const payload: FusionPerimetres = {
        dateFusion: dateUtil.format(dateFusion, "yyyy-MM-dd"),
        idPerimetreAbsorbant: perimetrePrincipal.id,
        idPerimetreAbsorbe: perimetreDissous.id,
      };
      await perimetresService.fusion(payload);

      enqueueSnackbar({
        variant: "success",
        message: ToastMessages.MERGED_PERIMETRE,
      });
      navigate(routesConfig.admin.path);
    });
  };

  return (
    <Container>
      <Authorization roles={ApplicationRole.ADMINISTRATOR}>
        <PageTitle title="Fusion de périmètres" />
        <Grid
          container
          spacing={2}
          component="form"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(sendData)}
          size={12}>
          <InformationBox icon={VisibilityIcon} articleTitle="Informations générales">
            <Grid size={{ xs: 12, sm: 6 }}>
              <ControlledDateTime
                name="dateFusion"
                control={control}
                maxDate={MAX_DATE}
                rules={{
                  required: "Choisir une date d'effet de fusion est obligatoire",
                  validate: (value: Date | null) =>
                    (dateUtil.isValid(value) && dateUtil.isBefore(value, MAX_DATE)) ||
                    "Veuillez renseigner une date valide",
                }}
                label="Date d'effet de la fusion *"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <ControlledTextField select name="competence" control={control} label="Compétence">
                {competencesAssociationList.map(({ competence, label }) => (
                  <MenuItem key={competence} value={competence}>
                    <CompetenceChip competence={competence} sx={{ marginRight: 1 }} />
                    {label}
                  </MenuItem>
                ))}
              </ControlledTextField>
            </Grid>
          </InformationBox>

          <InformationBox icon={MergeTypeIcon} articleTitle="Sélection des périmètres à fusionner">
            <Grid container size={{ xs: 12, md: 6 }}>
              <Grid size={12}>
                <Typography variant="subtitle2">1. Choisir le périmètre principal</Typography>
              </Grid>
              <Grid size={12}>
                <ControlledPerimetreAutoComplete
                  name="perimetrePrincipal"
                  label="Périmètre principal *"
                  rules={{
                    required: "Ce champ est obligatoire",
                    validate: (principal) =>
                      (principal != null && principal.id !== perimetreDissous?.id) ||
                      "Le périmètre principal doit être différent du périmètre dissous",
                  }}
                  control={control}
                  perimetres={perimetresFiltres}
                  noOptionsText="Veuillez d'abord sélectionner une compétence"
                />
              </Grid>
            </Grid>

            <Grid container size={{ xs: 12, md: 6 }}>
              <Grid size={12}>
                <Typography variant="subtitle2">2. Choisir le périmètre dissous</Typography>
              </Grid>
              <Grid size={12}>
                <ControlledPerimetreAutoComplete
                  name="perimetreDissous"
                  label="Périmètre dissous *"
                  rules={{
                    required: "Ce champ est obligatoire",
                    validate: (dissous) =>
                      (dissous != null && dissous.id !== perimetrePrincipal?.id) ||
                      "Le périmètre dissous doit être différent du périmètre principal",
                  }}
                  control={control}
                  perimetres={perimetresFiltres}
                  noOptionsText="Veuillez d'abord sélectionner une compétence"
                />
              </Grid>
            </Grid>
          </InformationBox>

          <Grid container size={12} sx={{ justifyContent: "flex-end" }}>
            <Button href={routesConfig.admin.path} color="error">
              Annuler
            </Button>
            <LoadingButton variant="contained" loading={isSending} disabled={!isValid}>
              Fusionner les périmètres
            </LoadingButton>
          </Grid>
        </Grid>
      </Authorization>
    </Container>
  );
}

const FusionPerimetresWithErrorBoundary = withPageErrorBoundary(FusionPerimetresForm);

export default FusionPerimetresWithErrorBoundary;
