import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Container, Grid2 as Grid, Skeleton } from "@mui/material";
import InformationBox from "components/InformationBox";
import PageTitle from "components/PageTitle";
import type { Epci, Commune, Transfert } from "models";
import { epcisService, communesService, transfertsService } from "services";
import { epcisUtil, competencesUtil } from "utils";
import { useErrorHandler, withPageErrorBoundary } from "utils/errorHandling";
import PersonIcon from "@mui/icons-material/Person";
import AddHomeIcon from "@mui/icons-material/AddHome";
import LoadingScreen from "components/Template/LoadingScreen";
import EpciEtablissementPublicInformations from "./components/EpciEtablissementPublicInformations";
import EpciElusInformations from "./components/EpciElusInformations";
import { PerimetreIcon, CollectiviteIcon } from "icons";
import CollectiviteNonAdherenteSubtitle from "components/CollectiviteNonAdherenteSubtitle";
import ListSkeleton from "components/ListSkeleton";
import CommunesListWithErrorBoundary from "components/CommunesList";
import EtablissementPublicInformationBox from "components/Informations/EtablissementPublic/EtablissementPublicInformationBox";
import PerimetresInformationsWithErrorBoundary from "components/PerimetresInformationsWithErrorBoundary";
import { useRequiredParams } from "hooks";
import EpciDissousPage from "./components/EpciDissousPage";

function EpciPage(): ReactNode {
  const { id } = useRequiredParams<{ id: string }>();
  const [epci, setEpci] = useState<Epci>();
  const [transferts, setTransferts] = useState<Array<Transfert>>([]);
  const [communes, setCommunes] = useState<Array<Commune>>([]);
  const [open, setOpen] = useState(false);

  const { catchErrors } = useErrorHandler();
  const {
    error: communesError,
    catchErrors: catchCommunesError,
    isLoading: isCommunesLoading,
  } = useErrorHandler(true);
  const {
    error: transfertsError,
    catchErrors: catchTransfertsError,
    isLoading: isTransfertsLoading,
  } = useErrorHandler(true);

  useEffect(() => {
    async function getTransferts(_epci: Epci): Promise<void> {
      setTransferts([]);
      const _transferts = await transfertsService.getByEpci(id);
      _transferts.sort(competencesUtil.sortTransfertByCompetence);

      setTransferts(_transferts);
    }

    async function getCommunes(): Promise<void> {
      setCommunes([]);
      const _communes = await communesService.getByEpci(id);

      setCommunes(_communes);
    }

    async function getEpci(): Promise<void> {
      setEpci(undefined);
      const _epci = await epcisService.getById(id);
      document.title = _epci.libelle;

      void catchTransfertsError(async () => {
        await getTransferts(_epci);
      });

      setEpci(_epci);
    }

    void catchErrors(getEpci);
    void catchCommunesError(getCommunes);
  }, [catchCommunesError, catchErrors, catchTransfertsError, id]);

  function handleClickOpen(): void {
    setOpen(true);
  }

  if (epci == null) {
    return <LoadingScreen />;
  }

  if (epci.estDissous === true) {
    return <EpciDissousPage epci={epci} />;
  }

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <PageTitle
          title={epcisUtil.ToFullName(epci.libelle)}
          icon={<CollectiviteIcon />}
          subtitle="EPCI"
        />

        {isTransfertsLoading ? (
          <Grid size={12}>
            <Skeleton
              variant="rectangular"
              animation="wave"
              sx={{ maxWidth: "250px", height: "35px" }}
            />
          </Grid>
        ) : (
          transferts.length === 0 && (
            <Grid container size={12}>
              <CollectiviteNonAdherenteSubtitle message="Cet EPCI n'a pas adhéré au SDEA" />
            </Grid>
          )
        )}

        <Grid container size={{ xs: 12, lg: 9 }}>
          <EtablissementPublicInformationBox openModal={handleClickOpen}>
            <EpciEtablissementPublicInformations epci={epci} open={open} setOpen={setOpen} />
          </EtablissementPublicInformationBox>
        </Grid>

        <Grid container size={{ xs: 12, lg: 3 }}>
          <InformationBox hasBackground icon={PersonIcon} articleTitle="Élus">
            <EpciElusInformations epci={epci} />
          </InformationBox>
        </Grid>

        <Grid size={12}>
          <InformationBox hasBackground icon={AddHomeIcon} articleTitle="Communes adhérentes">
            <CommunesListWithErrorBoundary
              communes={communes}
              isLoading={isCommunesLoading}
              error={communesError}
            />
          </InformationBox>
        </Grid>

        {(isTransfertsLoading || transferts.length > 0) && (
          <Grid size={12}>
            <InformationBox hasBackground icon={PerimetreIcon} articleTitle="Perimètres SDEA">
              {isTransfertsLoading ? (
                <ListSkeleton lines={3} />
              ) : (
                <PerimetresInformationsWithErrorBoundary
                  transferts={transferts}
                  error={transfertsError}
                  pointOfView="epci"
                />
              )}
            </InformationBox>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

const EpciPageWithErrorBoundary = withPageErrorBoundary(EpciPage);

export default EpciPageWithErrorBoundary;
