import type Competence from "constants/Competence";
import type { Epci } from "./Epci";
import type { MinimalPerimetre } from "./Perimetre";

export interface TransfertDto {
  id: string;
  code: string;
  bassinVersant?: string;

  dateDebut: string;
  dateFin: string;
  dateDeliberation: string;
  datePremierTransfert: string;
  dateAssembleeGenerale: string;

  idCommune: string;
  libelleCommune: string;

  idPerimetre: string;
  libellePerimetre: string;
  competence: Competence;

  idTerritoire: string;
  libelleTerritoire: string;

  idEpci?: string;
  libelleEpci?: string;

  affectationsCommunesAssocieesDeleguees?: Array<string>;
}

export interface Transfert extends TransfertDto {
  perimetre: MinimalPerimetre;
  epci?: Epci;
}

export interface TransfertCreateData {
  code: string;
  idPerimetre: string;
  bassinVersant?: string;
  idEpci?: string;
  dateDebut: string;
  dateDeliberation: string;
  dateAssembleeGenerale: string;
  raisonCreation: RaisonCreationTransfert;
}

export interface TransfertChangeEpciData {
  idEpci: string | undefined;
  dateEffet: string;
  transfertIds: Array<string>;
}

export interface TransfertChangePerimetreData {
  idPerimetre: string;
  dateEffet: string;
  transfertIds: Array<string>;
}

/** Liste des raisons de retrait disponibles */
export const RaisonRetraitTransfertLibelles = [
  "Retrait de la compétence du SDEA",
  "Changement de l'entité de transfert",
] as const;
/** Raisons de retrait sous forme d'union de type (enum like) */
export type RaisonRetraitTransfert = (typeof RaisonRetraitTransfertLibelles)[number];

/** Liste des raisons de création de transferts disponibles */
export const RaisonCreationTransfertLibelles = [
  "Nouveau transfert",
  "Transfert Complémentaire",
  "Changement de type de transfert",
  "Correction d'une erreur de saisie pour un futur transfert",
  "Correction d'une erreur de saisie dans le passé",
  "Retour en arrière suite à un transfert non abouti",
] as const;
export type RaisonCreationTransfert = (typeof RaisonCreationTransfertLibelles)[number];

export interface RetraitTransfertData {
  raisonDuRetrait: RaisonRetraitTransfert;
  dateEffet: string;
  transfertIds: Array<string>;
}

export interface SuppressionTransfertData {
  idCommune: string;
  idTransfert: string;
}

export type SuppressionTransfertsData = Array<SuppressionTransfertData>;
