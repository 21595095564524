import { useEffect, useRef } from "react";
import type { ReactNode } from "react";
import { Box } from "@mui/material";
import type { SxProps } from "@mui/material";
import { useIsVisible } from "hooks";

interface LazyLoaderProps {
  lazyLoad: VoidFunction;
  sx?: SxProps;
}

/**
 * Composant invisible qui lance une fonction de callback (par exemple, de lazy loading)
 * lorsque l'utilisateur scrolle jusqu'à lui.
 * @param sx?: SxProps
 */
export function LazyLoader({ lazyLoad, sx }: Readonly<LazyLoaderProps>): ReactNode {
  const ref = useRef<Element>(null);
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    if (isVisible) {
      lazyLoad();
    }
  }, [isVisible, lazyLoad]);

  return (
    <Box
      ref={ref}
      sx={{
        visibility: "hidden",
        position: "absolute",
        bottom: "75vh",
        ...sx,
      }}
    />
  );
}
