import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { CircularProgress, IconButton, MenuItem, TableCell, TableRow } from "@mui/material";
import { dateUtil } from "@sdeapps/react-core";
import ControlledDateTime from "components/inputs/ControlledDateTime";
import ControlledTextField from "components/inputs/ControlledTextField";
import type Competence from "constants/Competence";
import ToastMessages from "constants/ToastMessages";
import type { Perimetre, Territoire, Commune, MandatSdeaCreateModel } from "models";
import { enqueueSnackbar } from "notistack";
import ControlledPerimetreAutoComplete from "components/inputs/ControlledPerimetreAutoComplete";
import { useForm, useWatch } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { perimetresService, mandatsService } from "services";
import { competencesUtil, timeUtil } from "utils";
import { useErrorHandler } from "utils/errorHandling";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import TerritoireAutoComplete from "pages/NewTransfert/components/TerritoireAutoComplete";
import CompetenceChip from "components/Typography/CompetenceChip";
import type Fonction from "../models/Fonction";
import fonctions from "../constants/fonctions";
import NewMandatFonctionsAutoComplete from "./NewMandatFunctionAutoComplete";
import oracleFonctions from "constants/fonctions_oracle.json";
import CommuneAutoCompleteWithErrorBoundary from "components/inputs/CommuneAutoCompleteWithErrorBoundary";
import { competencesAssociationList } from "constants/CompetenceMap";

interface MandatSdeaFormModel {
  dateDebutMandat: string;
  dateFinMandat?: string;
  fonction: Fonction;
  competence?: Competence;
  commune?: Commune;
  territoire?: Territoire;
  perimetre?: Perimetre;
}

interface NewMandatSdeaTableRowProps {
  idElu: string;
  cancel?: () => void;
}

function toCreateData(data: MandatSdeaFormModel, idElu: string): MandatSdeaCreateModel {
  const createData: MandatSdeaCreateModel = {
    idPersonne: idElu,
    dateDebut: dateUtil.format(data.dateDebutMandat, "yyyy-MM-dd"),
    idFonction: data.fonction.id,
    libelleFonction: data.fonction.libelle,
    typeMandat: oracleFonctions.find((f) => f.ID === data.fonction.id)?.TYPE ?? "SDEA",
    competence: data.competence,
    idTerritoire: data.territoire?.id,
    libelleTerritoire: data.territoire?.libelle,
    idCommune: data.commune?.id,
    libelleCommune: data.commune?.libelle,
    idPerimetre: data.perimetre?.id,
    libellePerimetre: data.perimetre?.libelle,
  };

  return createData;
}

function NewMandatSdeaTableRow({ idElu, cancel }: Readonly<NewMandatSdeaTableRowProps>): ReactNode {
  const [perimetres, setPerimetres] = useState<Array<Perimetre>>([]);
  const [availablePerimetres, setAvailablePerimetres] = useState<Array<Perimetre>>([]);
  const { handleSubmit, control } = useForm<MandatSdeaFormModel>({
    shouldFocusError: false,
    shouldUnregister: true,
  });
  const navigate = useNavigate();

  const watchThings = useWatch({ control });
  const { competence, territoire, fonction } = watchThings;

  const { catchErrors: catchPerimetresErrors, isLoading: isPerimetresLoading } = useErrorHandler();
  const { catchErrors: catchCreateErrors, isLoading: isCreating } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: false,
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  const submitMandat: SubmitHandler<MandatSdeaFormModel> = async function (
    data: MandatSdeaFormModel
  ): Promise<void> {
    async function createMandat(): Promise<void> {
      const createData = toCreateData(data, idElu);
      await mandatsService.create(createData);

      enqueueSnackbar({
        variant: "success",
        message: ToastMessages.CREATED_MANDAT,
      });

      await timeUtil.sleep(1000);
      navigate(0);
    }

    await catchCreateErrors(createMandat);
  };

  useEffect(() => {
    let tempPerimetres = [...perimetres];

    if (competence != null) {
      tempPerimetres = tempPerimetres.filter((p) => p.competence === competence);
    }
    if (territoire != null) {
      tempPerimetres = tempPerimetres.filter((p) => p.territoire.id === territoire.id);
    }

    setAvailablePerimetres(tempPerimetres);
  }, [competence, perimetres, territoire]);

  useEffect(() => {
    async function getPerimetres(): Promise<void> {
      const _perimetres = await perimetresService.getAll();
      _perimetres.sort(competencesUtil.sortPerimetresByCompetence);
      _perimetres.sort((a, b) => a.libelle.localeCompare(b.libelle));
      setPerimetres(_perimetres);
    }

    void catchPerimetresErrors(getPerimetres);
  }, [catchPerimetresErrors]);

  return (
    <TableRow>
      <TableCell>
        <NewMandatFonctionsAutoComplete
          fonctions={fonctions}
          name="fonction"
          label="Fonction *"
          control={control}
          rules={{ required: "Choisir une fonction est obligatoire" }}
        />
      </TableCell>
      <TableCell>
        {fonction?.displayFields?.competence === true && (
          <ControlledTextField select name="competence" control={control} label="Compétence">
            {competencesAssociationList.map((c) => (
              <MenuItem key={c.competence} value={c.competence}>
                <CompetenceChip competence={c.competence} />
              </MenuItem>
            ))}
          </ControlledTextField>
        )}
      </TableCell>
      <TableCell>
        {fonction?.displayFields?.territoire === true && (
          <TerritoireAutoComplete name="territoire" label="Territoire" control={control} />
        )}

        {fonction?.displayFields?.perimetre === true && (
          <ControlledPerimetreAutoComplete
            name="perimetre"
            label="Périmètre"
            control={control}
            perimetres={availablePerimetres}
            isLoading={isPerimetresLoading}
          />
        )}

        {fonction?.displayFields?.commune === true && (
          <CommuneAutoCompleteWithErrorBoundary
            name="commune"
            control={control}
            variant="commune"
          />
        )}
      </TableCell>
      <TableCell width="250px">
        <ControlledDateTime
          name="dateDebutMandat"
          label="Début du mandat *"
          control={control}
          rules={{
            required: "Choisir une date de début de mandat est obligatoire",
            validate: (value) => dateUtil.isValid(value) || "Veuillez renseigner une date valide",
          }}
        />
      </TableCell>

      <TableCell width="120px">
        {isCreating ? (
          <CircularProgress />
        ) : (
          <IconButton
            onClick={() => {
              void handleSubmit(submitMandat)();
            }}
            color="primary"
            title="Enregistrer">
            <SaveIcon />
          </IconButton>
        )}
        <IconButton onClick={cancel} color="error" title="Annuler">
          <ClearIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default NewMandatSdeaTableRow;
