import type { Transfert } from "models";
import type { TransfertEditionViewModel } from "../models/TransfertEditionViewModel";
import {
  type TransfertsEditionState,
  type TransfertsEditionReducerAction,
  TransfertsEditionActionType,
} from "./transfertsEditionTypes";
import { TransfertsEditionReducerActionType } from "./transfertsEditionTypes";

function isIdEpciNullOrEmpty(transert: Transfert): boolean {
  return transert.idEpci == null || transert.idEpci === "";
}

function isDateFinNullOrEmpty(transert: Transfert): boolean {
  return transert.dateFin == null || transert.dateFin === "";
}

export function transfertsEditionReducer(
  state: TransfertsEditionState,
  action: TransfertsEditionReducerAction
): TransfertsEditionState {
  const { type, payload } = action;

  switch (type) {
    case TransfertsEditionReducerActionType.INIT_DATA: {
      return {
        ...state,
        isLoading: payload === true,
      };
    }
    case TransfertsEditionReducerActionType.DATA_LOADED: {
      const { perimetre, sameCompetencePerimetres, transferts: _transferts = [] } = payload ?? {};
      return {
        ...state,
        perimetre,
        sameCompetencePerimetres,
        _transferts,
        isLoading: false,
      };
    }
    case TransfertsEditionReducerActionType.USER_ACTION: {
      const { _transferts } = state;
      const actionUtilisateur = payload ?? TransfertsEditionActionType.ReaffectationEPCI;
      let filteredTransferts: Array<TransfertEditionViewModel> = [];
      switch (actionUtilisateur) {
        case TransfertsEditionActionType.ReaffectationEPCI:
        case TransfertsEditionActionType.IndirectDirect:
          filteredTransferts = _transferts
            .filter(
              (transfert) => !isIdEpciNullOrEmpty(transfert) && isDateFinNullOrEmpty(transfert)
            )
            .map((transfert) => ({ ...transfert, selected: false }));
          break;
        case TransfertsEditionActionType.DirectIndirect:
          filteredTransferts = _transferts
            .filter(
              (transfert) => isIdEpciNullOrEmpty(transfert) && isDateFinNullOrEmpty(transfert)
            )
            .map((transfert) => ({ ...transfert, selected: false }));
          break;
        case TransfertsEditionActionType.RetraitTransfert:
        case TransfertsEditionActionType.SuppressionTransfert:
        case TransfertsEditionActionType.ReaffectationPerimetre:
          filteredTransferts = _transferts.map((transfert) => ({ ...transfert, selected: false }));
          break;
        default:
          break;
      }

      return {
        ...state,
        actionUtilisateur,
        actionParametres: {},
        filteredTransferts,
        selectedTransferts: [],
      };
    }
    case TransfertsEditionReducerActionType.USER_PARAMETRES: {
      if (payload != null) {
        const { actionParametres } = state;
        return {
          ...state,
          actionParametres: {
            ...actionParametres,
            ...payload,
          },
        };
      } else {
        return state;
      }
    }
    case TransfertsEditionReducerActionType.SELECT_TOGGLE: {
      if (payload != null) {
        let { filteredTransferts, selectedTransferts } = state;
        let selected = false;
        filteredTransferts = filteredTransferts.map((transert) => {
          if (transert.id === payload) {
            selected = !transert.selected;
            return {
              ...transert,
              selected,
            };
          }
          return transert;
        });
        selectedTransferts = selected
          ? [...selectedTransferts, payload]
          : selectedTransferts.filter((id) => id !== payload);

        return {
          ...state,
          filteredTransferts,
          selectedTransferts,
        };
      } else {
        return state;
      }
    }
    case TransfertsEditionReducerActionType.SELECT_ALL: {
      let { filteredTransferts } = state;
      filteredTransferts = filteredTransferts.map((transert) => ({
        ...transert,
        selected: true,
      }));
      const selectedTransferts: Array<string> = filteredTransferts.map((transfert) => transfert.id);
      return {
        ...state,
        filteredTransferts,
        selectedTransferts,
      };
    }
    case TransfertsEditionReducerActionType.UNSELECT_ALL: {
      let { filteredTransferts } = state;
      filteredTransferts = filteredTransferts.map((transert) => ({
        ...transert,
        selected: false,
      }));
      const selectedTransferts: Array<string> = [];
      return {
        ...state,
        filteredTransferts,
        selectedTransferts,
      };
    }
    default:
      return state;
  }
}
