import { Grid2 as Grid } from "@mui/material";
import { useEffect, type ReactNode, useCallback } from "react";
import CheckboxForArray from "./CheckboxForArray";

interface NewTransfertPorteesGepuProps {
  value: Array<string>;
  onChange: (a: Array<string>) => void;
}

const porteesGepu = [
  {
    code: "GEPU-0-0",
    libelle: "Collecte, transport, stockage et traitement des eaux pluviales des aires urbaines.",
  },
];

function NewTransfertPorteesGepu({
  value,
  onChange,
}: Readonly<NewTransfertPorteesGepuProps>): ReactNode {
  const resetAllPortees = useCallback((): void => {
    onChange([]);
  }, [onChange]);

  useEffect(() => {
    resetAllPortees();
  }, [onChange, resetAllPortees]);

  return (
    <Grid container size={12}>
      {porteesGepu.map((sousCompetence) => (
        <Grid container key={sousCompetence.libelle} size={12} sx={{ alignItems: "center" }}>
          <Grid size={12} sx={{ alignItems: "center" }}>
            <CheckboxForArray
              value={value}
              onChange={onChange}
              codes={sousCompetence.code}
              label={sousCompetence.libelle}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default NewTransfertPorteesGepu;
