import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Grid2 as Grid } from "@mui/material";
import { arrayUtil } from "@sdeapps/react-core";
import { competencesUtil } from "utils";
import type { Transfert } from "models";
import CommuneSousCompetencesAccordionSummary from "./CommuneSousCompetencesAccordionSummary";
import GceSousCompetencesAccordion from "components/Informations/SousCompetences/GceSousCompetencesAccordion";

interface CommuneGceSousCompetencesProps {
  transferts: Array<Transfert>;
}

function CommuneGceSousCompetences({
  transferts,
}: Readonly<CommuneGceSousCompetencesProps>): ReactNode {
  /** Sous-Compétences rangées par :
   * [Perimetre + Transferts
   *   [Bassin Versants
   *     [Sous-Compétences]
   *   ]]
   */
  const [transfertsByPerimetreAndBassinVersant, setTransfertsByPerimetreAndBassinVersant] =
    useState<Array<Array<Array<Transfert>>>>([]);

  useEffect(() => {
    // Groupe les sous-compétences par (Périmètre et Type de transfert), puis (Bassin versant),
    // et enfin les range par ordre de (Périmètre et Type de transfert) puis (sous-compétences).

    const transfertsByPerimetre = arrayUtil.groupByArray(
      transferts,
      (t) => `${t.idPerimetre}${t.idEpci}`
    );
    const result: Array<Array<Array<Transfert>>> = transfertsByPerimetre.map((sCA) =>
      arrayUtil.groupByArray(sCA, (t) => t.bassinVersant ?? "")
    );

    result.forEach((r) => {
      r.forEach((_r) => {
        _r.sort(competencesUtil.sortGceTransferts);
      });
    });
    result.sort((r1, r2) =>
      `${r1[0][0].perimetre.libelle}${r1[0][0].idEpci}`.localeCompare(
        `${r2[0][0].perimetre.libelle}${r2[0][0].idEpci}`
      )
    );
    setTransfertsByPerimetreAndBassinVersant(result);
  }, [transferts]);

  return (
    <Grid container spacing={2} size={12}>
      {transfertsByPerimetreAndBassinVersant.map((transfertsByBassinVersant) => (
        <Grid key={transfertsByBassinVersant[0][0].id} size={12}>
          <GceSousCompetencesAccordion
            transfertsByBassinVersants={transfertsByBassinVersant}
            defaultExpanded={transfertsByPerimetreAndBassinVersant.length === 1}
            summary={
              <CommuneSousCompetencesAccordionSummary
                epci={transfertsByBassinVersant[0][0].epci}
                perimetre={transfertsByBassinVersant[0][0].perimetre}
              />
            }
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default CommuneGceSousCompetences;
