import type { ReactNode } from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import CompetenceTypography from "components/Typography/CompetenceTypography";
import TypeTransfert from "constants/TypeTransfert";
import type { Perimetre } from "models";

interface PerimetreSubTitleProps {
  perimetre: Perimetre;
}

function PerimetreSubTitle({ perimetre }: Readonly<PerimetreSubTitleProps>): ReactNode {
  return (
    <Grid container direction="row" size={12} sx={{ columnGap: 2, alignItems: "center", mt: 1 }}>
      <CompetenceTypography competence={perimetre.competence} variant="caption" fontSize="1rem" />
      <Typography variant="caption" sx={{ fontSize: "1rem" }}>
        {perimetre.typeTransfert === TypeTransfert.PI ? "Transfert complet" : "Transfert partiel"}
      </Typography>
    </Grid>
  );
}

export default PerimetreSubTitle;
