import type { ReactNode } from "react";
import { Grid2 as Grid, Skeleton } from "@mui/material";

interface ListSkeletonProps {
  lines?: number;
}

function InputSkeleton({ lines = 1 }: Readonly<ListSkeletonProps>): ReactNode {
  return (
    <Grid size={12}>
      {[...Array(lines).keys()].map((i) => (
        <Skeleton key={i} variant="rounded" sx={{ height: "56px" }} />
      ))}
    </Grid>
  );
}

export default InputSkeleton;
