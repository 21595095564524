import { useState, useEffect } from "react";
import type { ReactNode } from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import TerritoireLink from "components/Links/TerritoireLink";
import { dateUtil } from "@sdeapps/react-core";
import SigLink from "components/Links/SigLink";
import type { Perimetre, Populations } from "models";
import PopulationSpan from "components/Span/PopulationSpan";
import { useErrorHandler } from "utils/errorHandling";
import { populationsService } from "services";

interface PerimetreInformationsProps {
  perimetre: Perimetre;
}

function PerimetreInformations({ perimetre }: Readonly<PerimetreInformationsProps>): ReactNode {
  const [populations, setPopulations] = useState<Populations>();

  const { error, catchErrors, isLoading } = useErrorHandler(true);

  useEffect(() => {
    async function getPopulations(): Promise<void> {
      setPopulations(undefined);
      const _populations = await populationsService.getByPerimetre(perimetre.id);
      setPopulations(_populations);
    }

    void catchErrors(getPopulations);
  }, [perimetre, catchErrors]);

  return (
    <Grid container spacing={2} size={12}>
      <Grid size={12}>
        <TerritoireLink id={perimetre.territoire.id} libelle={perimetre.territoire.libelle} />
      </Grid>
      <Grid size={12}>
        {perimetre.dateMembre != null && (
          <Typography>
            Membre du SDEA depuis le : {dateUtil.format(perimetre.dateMembre, "dd/MM/yyyy")}
          </Typography>
        )}
        {perimetre.dateTransfertComplet != null && (
          <Typography>
            Transfert complet le : {dateUtil.format(perimetre.dateTransfertComplet, "dd/MM/yyyy")}
          </Typography>
        )}
      </Grid>
      <Grid size={12}>
        <Typography>
          Population municipale aux dernières élections :{" "}
          <PopulationSpan
            population={populations?.derniereElection.populationMunicipale}
            annee={populations?.derniereElection.anneeMiseEnVigueur}
            isLoading={isLoading}
            error={error != null}
          />
        </Typography>
        <Typography>
          Population totale au dernier recensement :{" "}
          <PopulationSpan
            population={populations?.actuelle.populationTotale}
            annee={populations?.actuelle.anneeMiseEnVigueur}
            isLoading={isLoading}
            error={error != null}
          />
        </Typography>
      </Grid>
      <Grid size={12}>
        <SigLink collectivite={perimetre} />
      </Grid>
    </Grid>
  );
}

export default PerimetreInformations;
