import type { ReactNode } from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import type { TypographyProps } from "@mui/material";
import type Competence from "constants/Competence";
import competenceMap from "constants/CompetenceMap";

interface CompetenceTypographyProps extends TypographyProps {
  competence: Competence;
  noIcon?: boolean;
}

function CompetenceTypography({
  competence,
  noIcon = false,
  ...typographyProps
}: Readonly<CompetenceTypographyProps>): ReactNode {
  const competenceAssociation = competenceMap.get(competence);

  if (competenceAssociation != null)
    return (
      <Grid container direction="row" size="auto" sx={{ gap: 1, alignItems: "center" }}>
        {!noIcon && <competenceAssociation.icon />}
        <Typography {...typographyProps}>{competenceAssociation.label}</Typography>
      </Grid>
    );

  return null;
}

export default CompetenceTypography;
