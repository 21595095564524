import type { ReactNode } from "react";
import { Grid2 as Grid, IconButton, Typography } from "@mui/material";
import type { Grid2Props as GridProps } from "@mui/material";
import type { SvgIconComponent } from "@mui/icons-material";
import { Authorization } from "@sdeapps/react-core";
import type { ApplicationRole } from "@sdeapps/react-core";
import ContextualMenu from "./Template/ContextualMenu";
import { useContextualMenu } from "hooks";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface InformationBoxContextualMenuProps {
  actions: ReactNode;
  handleClose: VoidFunction;
  handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
  isOpen: boolean;
  menuAnchor: HTMLElement | null;
  closeOnClick: boolean;
}

function InformationBoxContextualMenu({
  actions,
  handleClose,
  handleOpen,
  isOpen,
  menuAnchor,
  closeOnClick,
}: Readonly<InformationBoxContextualMenuProps>): ReactNode {
  return (
    <Grid container sx={{ mr: -2 }} size="auto">
      <IconButton color="inherit" onClick={handleOpen} sx={{ my: -1 }}>
        <MoreVertIcon />
      </IconButton>
      <ContextualMenu
        open={isOpen}
        anchorEl={menuAnchor}
        onClose={handleClose}
        closeOnClick={closeOnClick}>
        {actions}
      </ContextualMenu>
    </Grid>
  );
}

interface InformationBoxProps extends GridProps {
  hasBackground?: boolean;
  icon?: SvgIconComponent;
  articleTitle?: ReactNode;
  subTitle?: ReactNode;
  actions?: ReactNode;
  actionsRoles?: ApplicationRole | Array<ApplicationRole>;
  closeOnClick?: boolean;
  noChildrenPadding?: boolean;
}

function InformationBox({
  hasBackground = false,
  icon: SvgIcon,
  articleTitle: title,
  subTitle,
  actions,
  actionsRoles,
  children,
  closeOnClick = true,
  noChildrenPadding = false,
}: Readonly<InformationBoxProps>): ReactNode {
  const { isOpen, handleOpen, handleClose, menuAnchor } = useContextualMenu();

  return (
    // MAGIE PAS TOUCHER
    <Grid container spacing={2} direction="column" size={12}>
      <Grid container sx={{ alignItems: "center" }}>
        <Grid
          container
          size={12}
          spacing={0}
          sx={{
            alignItems: "center",
            borderRadius: 1,
            backgroundColor: "primary.main",
            color: "white",
            px: 2,
            py: 1,
          }}>
          <Grid container spacing={1} size="grow" sx={{ alignItems: "center" }}>
            {SvgIcon != null && (
              <Grid container size="auto">
                <SvgIcon fontSize="small" />
              </Grid>
            )}
            <Typography
              component={Grid}
              container
              size={{ xs: "auto" }}
              color="inherit"
              variant="h5">
              {title}
            </Typography>
          </Grid>
          <Typography
            component={Grid}
            container
            size={{ xs: "auto" }}
            color="inherit"
            sx={{ alignItems: "center", fontWeight: "600" }}>
            {subTitle}
          </Typography>
          {actions != null &&
            (actionsRoles != null ? (
              <Authorization roles={actionsRoles}>
                <InformationBoxContextualMenu
                  actions={actions}
                  handleClose={handleClose}
                  handleOpen={handleOpen}
                  isOpen={isOpen}
                  menuAnchor={menuAnchor}
                  closeOnClick={closeOnClick}
                />
              </Authorization>
            ) : (
              <InformationBoxContextualMenu
                actions={actions}
                handleClose={handleClose}
                handleOpen={handleOpen}
                isOpen={isOpen}
                menuAnchor={menuAnchor}
                closeOnClick={closeOnClick}
              />
            ))}
        </Grid>
      </Grid>
      <Grid container size={12} sx={{ flexGrow: 1 }}>
        <Grid
          container
          size={12}
          sx={{
            backgroundColor: hasBackground ? "#f6fafc" : "initial",
            borderRadius: 1,
          }}>
          <Grid container sx={{ p: noChildrenPadding ? 0 : 2 }} spacing={2} size={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InformationBox;
