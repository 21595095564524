import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Grid2 as Grid, Link, Typography } from "@mui/material";
import EtablissementPublicInformation from "components/Informations/EtablissementPublic/EtablissementPublicInformation";
import { HorairesWithErrorBoundary } from "components/Informations/HorairesWithErrorBoundary";
import EtablissementPublicMap from "components/Maps/EtablissementPublicMap";
import PopulationSpan from "components/Span/PopulationSpan";
import EtablissementPublicForm from "components/forms/EtablissementPublicForm";
import type { Epci, EtablissementPublic, Populations } from "models";
import { etablissementsPublicsService, populationsService } from "services";
import { etablissementUtil } from "utils";
import { useErrorHandler } from "utils/errorHandling";

const BANATIC_URL = "https://www.banatic.interieur.gouv.fr/intercommunalite/";

interface EpciEtablissementPublicInformationsProps {
  epci: Epci;
  open: boolean;
  setOpen: (value: boolean) => void;
}

function EpciEtablissementPublicInformations({
  epci,
  open,
  setOpen,
}: Readonly<EpciEtablissementPublicInformationsProps>): ReactNode {
  const [etablissementPublicOpenData, setEtablissementPublicOpenData] = useState<
    EtablissementPublic | undefined
  >();
  const [etablissementPublicSdea, setEtablissementPublicSdea] = useState<
    EtablissementPublic | undefined
  >();

  const [population, setPopulation] = useState<Populations>();

  const { error, catchErrors, isLoading } = useErrorHandler({
    dontThrow: true,
  });
  const {
    error: populationsError,
    catchErrors: catchPopulationsError,
    isLoading: isPopulationsLoading,
  } = useErrorHandler(true);

  useEffect(() => {
    async function getEtablissementPublic(): Promise<void> {
      const _etablissementsPublics = await etablissementsPublicsService.getEpciSiegesBySiren(
        epci.id
      );
      const [openData, sdea] =
        etablissementUtil.getEtablissementPublicFromSource(_etablissementsPublics);
      setEtablissementPublicOpenData(openData);
      setEtablissementPublicSdea(sdea);
    }

    async function getPopulations(): Promise<void> {
      setPopulation(undefined);
      const _populations = await populationsService.getByEpci(epci.id);
      setPopulation(_populations);
    }

    void catchErrors(getEtablissementPublic);
    void catchPopulationsError(getPopulations);
  }, [catchErrors, catchPopulationsError, epci]);

  return (
    <Grid
      container
      spacing={2}
      size={12}
      sx={{ alignItems: "flex-start", alignContent: "flex-start" }}>
      <Grid container spacing={4} sx={{ mb: 4 }} size={{ xs: 12, md: 7 }}>
        {[etablissementPublicOpenData, etablissementPublicSdea].map(
          (e) =>
            e != null && (
              <EtablissementPublicInformation
                etablissementPublic={e}
                isLoading={isLoading}
                error={error != null}
                key={e.source}
              />
            )
        )}
        <Grid container spacing={1} size={12}>
          <Grid size={12}>
            <Typography>SIREN : {epci.id}</Typography>
          </Grid>
          <Grid size={12}>
            <Typography>Région : {epci?.regionSiege?.libelle}</Typography>
          </Grid>
          <Grid size={12}>
            <Typography>Département : {epci?.departementSiege?.libelle}</Typography>
          </Grid>
          <Grid size={12}>
            <Typography>
              Population totale au dernier recensement :{" "}
              <PopulationSpan
                population={population?.actuelle.populationTotale}
                annee={population?.actuelle.anneeMiseEnVigueur}
                isLoading={isPopulationsLoading}
                error={populationsError != null}
              />
            </Typography>
          </Grid>
          <Grid size={12}>
            <Link target="_blank" href={`${BANATIC_URL}${epci.id}`}>
              Fiche banatic
            </Link>
          </Grid>
        </Grid>
      </Grid>
      {etablissementPublicOpenData?.adresse != null && (
        <Grid size={{ xs: 12, md: 5 }}>
          <EtablissementPublicMap
            adresse={etablissementPublicOpenData?.adresse}
            isLoading={isLoading}
            error={error != null}
          />
        </Grid>
      )}
      <Grid size={{ xs: 12, md: 6, xl: 8 }}></Grid>
      {etablissementPublicOpenData?.horaires != null &&
        etablissementPublicOpenData.horaires.length > 0 && (
          <Grid size={{ xs: 12, md: 6, xl: 4 }}>
            <HorairesWithErrorBoundary
              horaires={etablissementPublicOpenData?.horaires}
              isLoading={isLoading}
              error={error != null}
            />
          </Grid>
        )}
      <EtablissementPublicForm
        etablissementPublic={
          etablissementPublicSdea ??
          ({
            id: etablissementPublicOpenData?.id,
            nom: etablissementPublicOpenData?.nom,
          } satisfies Partial<EtablissementPublic>)
        }
        open={open}
        setOpen={setOpen}
        idCollectivite={epci.id}
        upsertEtablissementCoordonnees={etablissementsPublicsService.putEpci}
        suppEtablissementCoordonnees={etablissementsPublicsService.deleteSurchargeSdeaEpci}
      />
    </Grid>
  );
}

export default EpciEtablissementPublicInformations;
