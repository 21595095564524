import type { ReactNode } from "react";
import { Box } from "@mui/material";
import House1Full from "./assets/house1_full.svg";
import House1Linear from "./assets/house1_linear.svg";
import House2Full from "./assets/house2_full.svg";
import House2Linear from "./assets/house2_linear.svg";
import House3Full from "./assets/house3_full.svg";
import House3Linear from "./assets/house3_linear.svg";
import House4Full from "./assets/house4_full.svg";
import House4Linear from "./assets/house4_linear.svg";
import House5Full from "./assets/house5_full.svg";
import House5Linear from "./assets/house5_linear.svg";
import House6Full from "./assets/house6_full.svg";
import House6Linear from "./assets/house6_linear.svg";
import House7Full from "./assets/house7_full.svg";
import House7Linear from "./assets/house7_linear.svg";
import { routesConfig } from "config/app-config";
import { useLocation } from "react-router-dom";

const houses = [
  {
    full: House1Full,
    linear: House1Linear,
  },
  {
    full: House2Full,
    linear: House2Linear,
  },
  {
    full: House3Full,
    linear: House3Linear,
  },
  {
    full: House4Full,
    linear: House4Linear,
  },
  {
    full: House5Full,
    linear: House5Linear,
  },
  {
    full: House6Full,
    linear: House6Linear,
  },
  {
    full: House7Full,
    linear: House7Linear,
  },
];

function getRandomArray(): Array<number> {
  let precedenteValue = 1;
  return Array.from({ length: 40 }, () => {
    let newValue = Math.floor(Math.random() * max);
    if (newValue === precedenteValue) {
      newValue = newValue === 1 ? 2 : 1;
    }
    precedenteValue = newValue;
    return newValue;
  });
}

const height = "280px";
const max = houses.length;
const randValues = getRandomArray();

function Footer(): ReactNode {
  const location = useLocation();

  return (
    <Box
      sx={{
        marginTop: "auto",
        paddingTop: "80px",
        height,
        width: "100%",
        overflow: "hidden",
      }}>
      <Box
        sx={{
          height: "100%",
          width: "200%",
          overflow: "hidden",
          marginTop: "7px",
        }}>
        {randValues.map((v, i) => (
          <img
            alt=""
            key={`${v}${i}`}
            src={location.pathname === routesConfig.home.path ? houses[v].full : houses[v].linear}
            style={{ height: "100%", marginLeft: "-15px" }}
          />
        ))}
      </Box>
    </Box>
  );
}

export default Footer;
