import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Typography } from "@mui/material";
import InlineErrorChip from "components/Errors/InlineErrorChip";
import TypographySkeleton from "components/Links/TypographySkeleton";

interface PopulationSpanProps {
  population?: string | number;
  annee?: string | number;
  isLoading?: boolean;
  error?: boolean;
}

function PopulationSpan({
  population,
  annee,
  isLoading = false,
  error = false,
}: Readonly<PopulationSpanProps>): ReactNode {
  const [populationNumber, setPopulationNumber] = useState<number>();

  useEffect(() => {
    setPopulationNumber(undefined);
    if (population != null) {
      setPopulationNumber(typeof population === "number" ? population : Number(population));
    }
  }, [population]);

  if (error) {
    return <InlineErrorChip />;
  }

  if (isLoading) {
    return <TypographySkeleton sx={{ width: "200px", display: "inline-block" }} />;
  }

  if (populationNumber == null || Number.isNaN(populationNumber)) {
    return <Typography component="span">inconnue</Typography>;
  } else {
    return (
      <Typography component="span">
        {new Intl.NumberFormat("fr-FR").format(populationNumber)} habitants
        {annee != null && ` (${annee})`}
      </Typography>
    );
  }
}

export default PopulationSpan;
