import type { ReactNode } from "react";
import { Grid2 as Grid } from "@mui/material";
import { competencesUtil } from "utils";
import { withComponentErrorBoundary } from "utils/errorHandling";
import type { Transfert, Commune } from "models";
import GceSousCompetencesAccordion from "components/Informations/SousCompetences/GceSousCompetencesAccordion";
import { usePerimetreTransfertsGrouping } from "../../hooks/usePerimetreTransfertsGrouping";
import PerimetrePorteesAccordionSummary from "./PerimetrePorteesAccordionSummary";
import { arrayUtil } from "@sdeapps/react-core";
import { useDisplayDates } from "hooks";

interface PerimetreGceSousCompetencesProps {
  communes: Array<Commune>;
  transferts: Array<Transfert>;
}

function PerimetreGceSousCompetences({
  communes,
  transferts,
}: Readonly<PerimetreGceSousCompetencesProps>): ReactNode {
  const { isDisplayingDates } = useDisplayDates();
  const currentGroupedTransferts = usePerimetreTransfertsGrouping(transferts, isDisplayingDates);

  return (
    <Grid container spacing={2} size={12}>
      {currentGroupedTransferts.map((transfertsByCommune) => {
        const transfertsByBassinVersants = arrayUtil.groupByArray(
          transfertsByCommune[0],
          (t) => t.bassinVersant ?? ""
        );
        transfertsByBassinVersants.forEach((_r) => {
          _r.sort(competencesUtil.sortGceTransferts);
        });

        return (
          <GceSousCompetencesAccordion
            key={transfertsByBassinVersants[0]?.[0]?.idCommune}
            defaultExpanded={currentGroupedTransferts.length === 1}
            transfertsByBassinVersants={transfertsByBassinVersants}
            summary={
              <PerimetrePorteesAccordionSummary
                communes={communes.filter((c) =>
                  transfertsByCommune.some((gT) => gT[0].idCommune === c.id)
                )}
                idEpci={transfertsByBassinVersants[0]?.[0]?.idEpci}
              />
            }
          />
        );
      })}
    </Grid>
  );
}

const PerimetreGceSousCompetencesWithErrorBoundary = withComponentErrorBoundary(
  PerimetreGceSousCompetences
);

export default PerimetreGceSousCompetencesWithErrorBoundary;
