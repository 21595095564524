import type { SvgIconComponent } from "@mui/icons-material";
import Competence from "./Competence";
import { AssainissementIcon, EauPotableIcon, EauxPluvialesIcon, GrandCycleDeEauIcon } from "icons";

export interface CompetenceAssociation {
  color: "gce" | "eau" | "ass" | "gepu";
  label: string;
  smallLabel: string;
  icon: SvgIconComponent;
  competence: Competence;
  keySigPerimetre?: string;
  keySigTerritoire?: string;
  contextSigPerimetre?: string;
  index: number;
}

const competenceEau: CompetenceAssociation = {
  color: "eau",
  label: "Eau Potable",
  smallLabel: "EAU",
  icon: EauPotableIcon,
  competence: Competence.EAU,
  contextSigPerimetre: "EAU%20-%20WEB",
  keySigPerimetre: "PE_E_COLLECT",
  keySigTerritoire: "PE_E_TERRIT",
  index: 0,
};
const competenceAss: CompetenceAssociation = {
  color: "ass",
  label: "Assainissement",
  smallLabel: "ASS",
  icon: AssainissementIcon,
  competence: Competence.ASS,
  contextSigPerimetre: "ASS%20-%20WEB",
  keySigPerimetre: "PE_A_COLLECT",
  keySigTerritoire: "PE_A_TERRIT",
  index: 1,
};
const competenceGCE: CompetenceAssociation = {
  color: "gce",
  label: "Grand Cycle de l'Eau",
  smallLabel: "GCE",
  icon: GrandCycleDeEauIcon,
  competence: Competence.GCE,
  contextSigPerimetre: "WEB_DEFAUT",
  keySigPerimetre: "PE_GCE_COLLECT",
  keySigTerritoire: "PE_GCE_TERRIT",
  index: 2,
};
const competenceGepu: CompetenceAssociation = {
  color: "gepu",
  label: "Gestion des Eaux Pluviales Urbaines",
  smallLabel: "GEPU",
  icon: EauxPluvialesIcon,
  competence: Competence.GEPU,
  contextSigPerimetre: "ASS%20-%20WEB%20-%20GEPU",
  keySigPerimetre: "PE_GEPU_COLLECT",
  keySigTerritoire: undefined,
  index: 3,
};

export const competencesAssociationList: Array<CompetenceAssociation> = [
  competenceEau,
  competenceAss,
  competenceGCE,
  competenceGepu,
];

const competenceMap = new Map<Competence, CompetenceAssociation>();
competenceMap.set(Competence.EAU, competenceEau);
competenceMap.set(Competence.ASS, competenceAss);
competenceMap.set(Competence.GCE, competenceGCE);
competenceMap.set(Competence.GEPU, competenceGepu);

export default competenceMap;
