import { apiConfig, config } from "config/app-config";
import { authenticationService } from "@sdeapps/react-core";
import { timeUtil } from "utils";
import type { AxiosHeaderValue } from "axios";

async function createHeaders(scopes: Array<string>): Promise<{
  [x: string]: AxiosHeaderValue | undefined;
  Accept: AxiosHeaderValue;
  Authorization: AxiosHeaderValue;
  "Content-Type"?: AxiosHeaderValue;
}> {
  const accessToken = await authenticationService.getAccessToken(scopes);

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
    "Ocp-Apim-Subscription-Key": apiConfig.apimSubscriptionKey,
    "Sdeapps-Version": config.appVersion,
    "Sdeapps-Application": config.appName,
    // "User-Oid": "***",
    // "User-Name": "***",
    // "User-Email": "***",
    // "User-Roles": "***",
  };
}

/**
 * Vérifie si un changement de données a bien été répliqué dans les bases de vues
 * @param asyncCallback Fonction asynchrone qui checke si la donnée a bien été répliquée en base; doit **résoudre** si c'est le cas, et **throw une erreur** si la donnée n'est pas répliquée
 * @param retryCount nombre de fois qu'on va réessayer de choper les données, par défaut: 15
 * @param onSuccess fonction optionnelle a exécuter lorsque les changements ont été répliqués dans les tables de vue
 * @param onError fonction optionnelle a exécuter lorsque le nombre maximum d'essais `retryCount` a été atteint sans succès
 * @returns `Promise<void>`
 */
async function waitForReplication(
  asyncCallback: () => Promise<void>,
  retryCount: number = 15,
  onSuccess?: VoidFunction,
  onError?: VoidFunction
): Promise<void> {
  // Condition de sortie : au bout de {retryCount} essais infructueux, on apelle onError et on quitte
  if (retryCount <= 0) {
    console.error("waitForReplication a atteint le nombre maximal d'essais sans succès.");
    if (onError != null) {
      onError();
    }
    return;
  }

  // Les 3 premiers essais sont toutes les secondes, puis toutes les demi-secondes
  await timeUtil.sleep(retryCount < 3 ? 1000 : 500);

  try {
    // On cherche si la donnée existe dans les tables de vues
    await asyncCallback();
    // Les tables de vues ont bien été répliquées : on apelle onSuccess puis on quitte
    if (onSuccess != null) {
      onSuccess();
    }
  } catch (error) {
    // Les tables de vues n'ont pas encore été répliquées : on réessaye en décrémentant retryCount
    await waitForReplication(asyncCallback, retryCount - 1, onSuccess, onError);
  }
}

export const networkService = {
  createHeaders,
  waitForReplication,
};
