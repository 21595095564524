import { Grid2 as Grid } from "@mui/material";
import Competence from "constants/Competence";
import { useEffect, type ReactNode, useCallback } from "react";
import CheckboxForArray from "./CheckboxForArray";

const porteesEau = [
  {
    libelle: "Contrôle, entretien et exploitation",
    portees: [
      { code: "EAU-CE-3", libelle: "distribution" },
      { code: "EAU-CE-4", libelle: "production" },
      { code: "EAU-CE-6", libelle: "transport" },
    ],
  },
  {
    libelle: "Amélioration des équipements publics",
    portees: [
      { code: "EAU-AM-3", libelle: "distribution" },
      { code: "EAU-AM-4", libelle: "production" },
      { code: "EAU-AM-6", libelle: "transport" },
      // { code: "EAU-AM-8", libelle: "limitée aux équipements publics de production" },
    ],
  },
  { code: "EAU-AD-0", libelle: "Assistance administrative" },
  {
    libelle: "Étude des équipements publics",
    portees: [
      { code: "EAU-ET-3", libelle: "distribution" },
      { code: "EAU-ET-4", libelle: "production" },
      { code: "EAU-ET-6", libelle: "transport" },
      // { code: "EAU-ET-8", libelle: "limitée aux équipements publics de production" },
    ],
  },
  {
    libelle: "Extension des équipements publics",
    portees: [
      { code: "EAU-EX-3", libelle: "distribution" },
      { code: "EAU-EX-4", libelle: "production" },
      { code: "EAU-EX-6", libelle: "transport" },
      // { code: "EAU-EX-10", libelle: "limitées aux branchements" },
    ],
  },
  { code: "EAU-MO-0", libelle: "Maîtrise d'ouvrage / Réalisation" },
  // {
  //   code: "EAU-MO-3",
  //   libelle: "Maîtrise d'ouvrage / Réalisation",
  //   portée: "distribution",
  // },
  {
    libelle: "Rénovation des équipements publics",
    portees: [
      { code: "EAU-RE-3", libelle: "distribution" },
      { code: "EAU-RE-4", libelle: "production" },
      { code: "EAU-RE-6", libelle: "transport" },
    ],
  },
  { code: "EAU-AB-0", libelle: "Gestion des abonnés" },
];

const porteesAss = [
  {
    libelle: "Contrôle, entretien et exploitation",
    portees: [
      { code: "ASS-CE-2", libelle: "collecte" },
      { code: "ASS-CE-5", libelle: "traitement" },
      { code: "ASS-CE-6", libelle: "transport" },
    ],
  },
  {
    libelle: "Amélioration des équipements publics",
    portees: [
      { code: "ASS-AM-2", libelle: "collecte" },
      { code: "ASS-AM-5", libelle: "traitement" },
      { code: "ASS-AM-6", libelle: "transport" },
    ],
  },
  {
    code: "ASS-AD-0",
    libelle: "Assistance administrative",
  },
  {
    libelle: "Systèmes d'assainissement non collectif",
    portees: [
      { code: "ASS-AC-1", libelle: "contrôle" },
      { code: "ASS-AC-7", libelle: "entretien" },
    ],
  },
  {
    libelle: "Étude des équipements publics",
    portees: [
      { code: "ASS-ET-2", libelle: "collecte" },
      { code: "ASS-ET-5", libelle: "traitement" },
      { code: "ASS-ET-6", libelle: "transport" },
    ],
  },
  {
    libelle: "Extension des équipements publics",
    portees: [
      { code: "ASS-EX-2", libelle: "collecte" },
      { code: "ASS-EX-5", libelle: "traitement" },
      { code: "ASS-EX-6", libelle: "transport" },
      // { code: "ASS-EX-10", libelle: "limitées aux branchements" },
      // { code: "ASS-EX-14", libelle: "limitée aux branchements des équipements publics de collecte" },
    ],
  },
  {
    code: "ASS-MO-0",
    libelle: "Maîtrise d'ouvrage / Réalisation",
  },
  {
    libelle: "Rénovation des équipements publics",
    portees: [
      { code: "ASS-RE-2", libelle: "collecte" },
      { code: "ASS-RE-5", libelle: "traitement" },
      { code: "ASS-RE-6", libelle: "transport" },
    ],
  },
  {
    code: "ASS-AB-0",
    libelle: "Gestion des abonnés",
  },
];

interface NewTransfertPorteesEauAssProps {
  value: Array<string>;
  onChange: (a: Array<string>) => void;
  competence: Competence;
}

function NewTransfertPorteesEauAss({
  value,
  onChange,
  competence,
}: Readonly<NewTransfertPorteesEauAssProps>): ReactNode {
  const resetAllPortees = useCallback((): void => {
    onChange([]);
  }, [onChange]);

  useEffect(() => {
    resetAllPortees();
  }, [onChange, resetAllPortees, competence]);

  return (
    <Grid container size={12}>
      {(competence === Competence.EAU ? porteesEau : porteesAss).map((sousCompetence) => (
        <Grid container key={sousCompetence.libelle} size={12} sx={{ alignItems: "center" }}>
          <Grid size={12} sx={{ alignItems: "center" }}>
            <CheckboxForArray
              value={value}
              onChange={onChange}
              codes={
                sousCompetence.portees == null
                  ? sousCompetence.code
                  : sousCompetence.portees.map((portee) => portee.code)
              }
              label={sousCompetence.libelle}
            />
          </Grid>
          {sousCompetence.portees?.map((portee) => (
            <Grid sx={{ marginLeft: 4 }} key={portee.code} size={12}>
              <CheckboxForArray
                value={value}
                onChange={onChange}
                codes={portee.code}
                label={portee.libelle}
              />
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
}

export default NewTransfertPorteesEauAss;
