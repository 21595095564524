import { useEffect } from "react";
import { communesService, epcisService, perimetresService, transfertsService } from "services";
import { porteesUtil } from "utils";
import { SdeappsErrorNotFound, useErrorHandler } from "utils/errorHandling";
import type { TransfertEditionViewModel } from "../models/TransfertEditionViewModel";
import { useTransfertsEditionContext } from "../store/transfertsEditionContext";
import { TransfertsEditionReducerActionType } from "../store/transfertsEditionTypes";
import type { Transfert, Commune, Epci } from "models";

function sortTransfert(a: TransfertEditionViewModel, b: TransfertEditionViewModel): number {
  const byCommune =
    a.commune != null && b.commune != null
      ? a.commune?.libelle.localeCompare(b.commune?.libelle)
      : 0;
  if (byCommune !== 0) return byCommune;

  const bySousCompetence =
    a.sousCompetenceLibelle != null && b.sousCompetenceLibelle != null
      ? a.sousCompetenceLibelle.localeCompare(b.sousCompetenceLibelle)
      : 0;
  if (bySousCompetence !== 0) return bySousCompetence;

  const byPortee =
    a.porteeLibelle != null && b.porteeLibelle != null
      ? a.porteeLibelle.localeCompare(b.porteeLibelle)
      : 0;
  if (byPortee !== 0) return byPortee;

  return 0;
}

function convertTransfertsToViewModels(
  transferts: Array<Transfert>,
  communes: Array<Commune>,
  epcis: Array<Epci>
): Array<TransfertEditionViewModel> {
  const transfertViewModels = transferts.map<TransfertEditionViewModel>((transfert) => {
    const commune = communes.find((commune) => commune.id === transfert.idCommune);
    const epci = epcis.find((epci) => epci.id === transfert.idEpci);
    const sousCompetenceLibelle = porteesUtil.getSousCompetenceLabel(transfert.code);
    const porteeLibelle = porteesUtil.getPorteeLabel(transfert.code);

    return {
      ...transfert,
      selected: false,
      commune,
      epci,
      sousCompetenceLibelle,
      porteeLibelle,
    };
  });

  transfertViewModels.sort(sortTransfert);
  return transfertViewModels;
}

function useTransfertsEditionViewModelLoader(idPerimetre?: string): void {
  const { catchErrors } = useErrorHandler();
  const { dispatch } = useTransfertsEditionContext();

  useEffect(() => {
    async function getPerimetreEtTransferts(): Promise<void> {
      if (idPerimetre != null) {
        const [perimetres, _transferts, _communes, _epcis] = await Promise.all([
          perimetresService.getAll(),
          transfertsService.getByPerimetre(idPerimetre),
          communesService.getAll(),
          epcisService.getAll(),
        ]);

        const transferts = convertTransfertsToViewModels(_transferts, _communes, _epcis);

        const perimetre = perimetres.find((p) => p.id === idPerimetre);
        if (perimetre == null) throw new SdeappsErrorNotFound("Perimetre pas trouvé");
        const sameCompetencePerimetres = perimetres.filter(
          (p) => p.competence === perimetre.competence && p.id !== idPerimetre
        );

        dispatch({
          type: TransfertsEditionReducerActionType.DATA_LOADED,
          payload: {
            perimetre,
            sameCompetencePerimetres,
            transferts,
          },
        });
      }
    }
    dispatch({ type: TransfertsEditionReducerActionType.INIT_DATA });
    void catchErrors(getPerimetreEtTransferts);
  }, [catchErrors, dispatch, idPerimetre]);
}

export default useTransfertsEditionViewModelLoader;
