import type { ReactNode } from "react";
import { Button, Grid2 as Grid, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { useErrorBoundary } from "react-error-boundary";

interface InformationsErrorProps {
  title?: string;
  caption?: string;
}

const DEFAULT_TITLE = "Une erreur est survenue.";
const DEFAULT_CAPTION =
  "Vous pouvez continuer à utiliser l'application, mais certaines fonctionnalités peuvent être temporairement dégradées.";

function InformationsError({
  title = DEFAULT_TITLE,
  caption = DEFAULT_CAPTION,
}: Readonly<InformationsErrorProps>): ReactNode {
  const { resetBoundary } = useErrorBoundary();

  return (
    <Grid container spacing={2} size={12} sx={{ alignItems: "center", justifyContent: "center" }}>
      <Grid size="auto">
        <WarningIcon color="warning" />
      </Grid>
      <Grid container size="grow">
        <Grid container spacing={2} size={12} sx={{ alignItems: "center" }}>
          <Grid>
            <Typography sx={{ fontWeight: "500" }}>{title}</Typography>
          </Grid>
          <Grid>
            <Button variant="text" size="small" onClick={resetBoundary}>
              Réessayer
            </Button>
          </Grid>
        </Grid>
        <Grid size={12}>
          <Typography variant="caption" sx={{ fontStyle: "italic" }}>
            {caption}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InformationsError;
