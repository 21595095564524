import type { ReactNode } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  useTheme,
  useMediaQuery,
  Link,
  Typography,
} from "@mui/material";
import { envConfig, routesConfig } from "config/app-config";
import UserMenu from "./UserMenu";
import HeaderAvatar from "./HeaderAvatar";
import TitleImage from "assets/header-logo-title.svg";
import LogoCollectivite from "assets/logo-collectivite-192x192-blanc.png";
import { useLocation } from "react-router-dom";
import { useContextualMenu } from "hooks";
import HeaderSearch from "./HeaderSearch";

function getEnvName(): string {
  const env = envConfig.name ?? "";
  if (env?.length > 0 && env?.toLocaleLowerCase() !== "prod") {
    return env.toLocaleUpperCase();
  }
  return "";
}

type HeaderProps = { unAuthenticated?: boolean };

function Header({ unAuthenticated = false }: Readonly<HeaderProps>): ReactNode {
  const location = useLocation();
  const { isOpen, handleOpen, handleClose, menuAnchor } = useContextualMenu();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar sx={{ mb: 4, position: "sticky", top: 0 }}>
      <Toolbar sx={{ gap: 1 }}>
        {location.pathname !== routesConfig.home.path && (
          <Link href="/" sx={{ marginRight: 1 }}>
            <img
              src={isSmallScreen ? LogoCollectivite : TitleImage}
              alt="Collectivités"
              width={isSmallScreen ? "40px" : "184px"}
              height={isSmallScreen ? "40px" : undefined}
              style={{ display: "block" }}
            />
          </Link>
        )}
        {location.pathname !== routesConfig.home.path && <HeaderSearch />}
        <div style={{ flexGrow: 1 }} />
        {!isSmallScreen && <Typography color="white">{getEnvName()}</Typography>}
        <IconButton edge="end" onClick={handleOpen} color="inherit">
          {!unAuthenticated ? <HeaderAvatar /> : <Avatar />}
        </IconButton>
      </Toolbar>
      {!unAuthenticated && <UserMenu open={isOpen} anchorEl={menuAnchor} onClose={handleClose} />}
    </AppBar>
  );
}

export default Header;
