import type { ReactNode } from "react";
import { Container, Grid2 as Grid } from "@mui/material";
import InformationBox from "components/InformationBox";
import PageTitle from "components/PageTitle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PerimetreForm from "./components/PerimetreForm";
import { Authorization } from "@sdeapps/react-core";
import { ApplicationRole } from "constants/ApplicationRole";
import { withPageErrorBoundary } from "utils/errorHandling";

function NewPerimetre(): ReactNode {
  return (
    <Container>
      <Authorization roles={ApplicationRole.ADMINISTRATOR}>
        <Grid container spacing={2}>
          <PageTitle title="Nouveau Périmètre" />
          <Grid container size={12}>
            <InformationBox icon={VisibilityIcon} articleTitle="Informations générales">
              <PerimetreForm />
            </InformationBox>
          </Grid>
        </Grid>
      </Authorization>
    </Container>
  );
}

const NewPerimetreWithErrorBoundary = withPageErrorBoundary(NewPerimetre);

export default NewPerimetreWithErrorBoundary;
