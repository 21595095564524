import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import CommunesListWithErrorBoundary from "components/CommunesList";
import type { Commune } from "models";
import { communesService, transfertsService } from "services";

interface PerimetreCommuneListProps {
  idPerimetre: string;
}

function PerimetreCommuneList({ idPerimetre }: Readonly<PerimetreCommuneListProps>): ReactNode {
  const [communes, setCommunes] = useState<Array<Commune>>([]);
  const [cadsDuPerimetreSet, setCadsDuPerimetreSet] = useState<Set<string>>();

  const { error, catchErrors, isLoading } = useErrorHandler();

  useEffect(() => {
    async function getCommunes(): Promise<void> {
      setCommunes([]);
      const [_communes, _transferts] = await Promise.all([
        communesService.getByPerimetre(idPerimetre),
        transfertsService.getByPerimetre(idPerimetre),
      ]);

      const _communesAssocieesDelegueesDuPerimetre = _transferts
        .flatMap((t) => t.affectationsCommunesAssocieesDeleguees)
        .filter(Boolean)
        .reduce((acc, id) => {
          if (id != null) {
            acc.add(id);
          }
          return acc;
        }, new Set<string>());

      setCommunes(_communes);
      setCadsDuPerimetreSet(_communesAssocieesDelegueesDuPerimetre);
    }
    if (idPerimetre != null) {
      void catchErrors(getCommunes);
    }
  }, [catchErrors, idPerimetre]);

  return (
    <CommunesListWithErrorBoundary
      communes={communes}
      communesAssocieesDelegueesToHighlight={cadsDuPerimetreSet}
      lengthSentence={`${communes.length} ${
        communes.length > 1 ? "communes sont affectées" : "commune est affectée"
      }.`}
      zeroSentence="Aucune commune n'est affectée à ce périmètre SDEA."
      isLoading={isLoading}
      error={error}
    />
  );
}

const PerimetreCommuneListWithErrorBoundary = withComponentErrorBoundary(PerimetreCommuneList);

export default PerimetreCommuneListWithErrorBoundary;
