import type { ReactNode } from "react";
import { TabContext, TabList } from "@mui/lab";
import { Grid2 as Grid, tabClasses, tabsClasses, type TabsProps } from "@mui/material";
import type Competence from "constants/Competence";
import competenceMap from "constants/CompetenceMap";

interface CompetenceTabsProps extends TabsProps {
  tabs: Array<ReactNode>;
  panels: Array<ReactNode>;
}

function CompetenceTabs({
  value,
  onChange,
  tabs,
  panels,
}: Readonly<CompetenceTabsProps>): ReactNode {
  return (
    <TabContext value={value}>
      <Grid container size={12}>
        <Grid size={12}>
          <TabList
            onChange={onChange}
            variant="scrollable"
            scrollButtons="auto"
            selectionFollowsFocus
            sx={{
              [`*.${tabClasses.root}`]: { color: `text.primary` },
              [`*.${tabClasses.selected}`]: {
                color: `${competenceMap.get(value as Competence)?.color}.main`,
              },
              [`*.${tabsClasses.indicator}`]: {
                backgroundColor: `${competenceMap.get(value as Competence)?.color}.main`,
              },
            }}>
            {tabs}
          </TabList>
        </Grid>
        <Grid container spacing={2} size={12}>
          {panels}
        </Grid>
      </Grid>
    </TabContext>
  );
}

export default CompetenceTabs;
