import type { PropsWithChildren, ReactNode } from "react";
import { MenuItem } from "@mui/material";
import InformationBox from "components/InformationBox";
import { useDisplayDates } from "hooks";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import RuleIcon from "@mui/icons-material/Rule";

interface CompetencesEtPorteesInformationBoxProps extends PropsWithChildren {
  additionalMenuItems?: ReactNode;
  noChildrenPadding: boolean;
}

function CompetencesEtPorteesInformationBox({
  children,
  additionalMenuItems,
  noChildrenPadding = false,
}: Readonly<CompetencesEtPorteesInformationBoxProps>): ReactNode {
  const { isDisplayingDates, setIsDisplayingDates } = useDisplayDates();

  return (
    <InformationBox
      hasBackground
      icon={RuleIcon}
      articleTitle="Compétences & Portées"
      noChildrenPadding={noChildrenPadding}
      actions={[
        <MenuItem
          key="display-dates"
          onClick={() => {
            setIsDisplayingDates(!isDisplayingDates);
          }}>
          {isDisplayingDates ? (
            <CalendarTodayIcon color="primary" sx={{ mr: 1 }} />
          ) : (
            <CalendarMonthIcon color="primary" sx={{ mr: 1 }} />
          )}
          {isDisplayingDates ? "Cacher" : "Afficher"} les dates
        </MenuItem>,
        additionalMenuItems,
      ]}>
      {children}
    </InformationBox>
  );
}

export default CompetencesEtPorteesInformationBox;
