import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Container, Grid2 as Grid, MenuItem } from "@mui/material";
import InformationBox from "components/InformationBox";
import PageTitle from "components/PageTitle";
import LoadingScreen from "components/Template/LoadingScreen";
import type { Perimetre, Transfert } from "models";
import { useNavigate } from "react-router-dom";
import { perimetresService, transfertsService } from "services";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddHomeIcon from "@mui/icons-material/AddHome";
import EditIcon from "@mui/icons-material/Edit";
import PerimetreSubTitle from "./components/PerimetreSubTitle";
import { PerimetreIcon } from "icons";
import PerimetreDeleguesSdeaWithErrorBoundary from "./components/Delegues/PerimetreDeleguesSdea";
import { competencesUtil } from "utils";
import { useErrorHandler, withPageErrorBoundary } from "utils/errorHandling";
import PerimetreInformations from "./components/PerimetreInformations";
import PerimetreCommuneList from "./components/PerimetreCommuneList";
import PerimetrePorteesInformationsBoxWithErrorBoundary from "./components/Transferts/PerimetrePorteesInformationsBox";
import { ApplicationRole } from "constants/ApplicationRole";
import { routesConfig } from "config/app-config";
import { useRequiredParams } from "hooks";

function PerimetrePage(): ReactNode {
  const { id } = useRequiredParams<{ id: string }>();
  const navigate = useNavigate();
  const [perimetre, setPerimetre] = useState<Perimetre>();
  const [transferts, setTransferts] = useState<Array<Transfert>>([]);

  const { catchErrors } = useErrorHandler();
  const {
    error: transfertsError,
    catchErrors: catchTransfertsError,
    isLoading: isTransfertsLoading,
  } = useErrorHandler(true);

  useEffect(() => {
    async function getPerimetre(): Promise<void> {
      setPerimetre(undefined);
      const _perimetre = await perimetresService.getById(id);
      document.title = _perimetre.libelle;
      setPerimetre(_perimetre);
    }

    async function getTransferts(): Promise<void> {
      setTransferts([]);
      const _transferts = await transfertsService.getByPerimetre(id);
      _transferts.sort(competencesUtil.sortTransfertByCompetence);
      setTransferts(_transferts);
    }

    void catchErrors(getPerimetre);
    void catchTransfertsError(getTransferts);
  }, [catchErrors, catchTransfertsError, id]);

  if (perimetre == null) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <PageTitle
          title={perimetre.libelle}
          icon={<PerimetreIcon />}
          subtitle="Périmètre"
          competences={perimetre.competence}
        />
        <PerimetreSubTitle perimetre={perimetre} />
        <Grid container spacing={2} size={12}>
          <InformationBox
            hasBackground
            icon={VisibilityIcon}
            articleTitle="Informations générales"
            subTitle={`Code SDEA : ${perimetre.code}`}
            actionsRoles={ApplicationRole.ADMINISTRATOR}
            actions={
              <MenuItem
                key="modifier-perimetre"
                onClick={() => {
                  navigate(routesConfig.perimetreModify.getParameterPath(perimetre.id));
                }}>
                <EditIcon color="primary" sx={{ mr: 1 }} />
                Modifier les informations du Périmètre
              </MenuItem>
            }>
            <PerimetreInformations perimetre={perimetre} />
          </InformationBox>
        </Grid>
        <Grid container spacing={2} size={12}>
          <InformationBox
            hasBackground
            icon={AddHomeIcon}
            articleTitle="Communes affectées"
            actionsRoles={ApplicationRole.ADMINISTRATOR}
            actions={
              <MenuItem
                key="modifier-cads"
                onClick={() => {
                  navigate(
                    routesConfig.perimetreCommunesAssocieesDeleguees.getParameterPath(perimetre.id)
                  );
                }}>
                <EditIcon color="primary" sx={{ mr: 1 }} />
                Affecter les communes fusionnées
              </MenuItem>
            }>
            <PerimetreCommuneList idPerimetre={perimetre.id} />
          </InformationBox>
        </Grid>
        <Grid container size={12}>
          <PerimetrePorteesInformationsBoxWithErrorBoundary
            transferts={transferts}
            perimetre={perimetre}
            isLoading={isTransfertsLoading}
            error={transfertsError}
          />
        </Grid>
        <Grid container spacing={2} size={12}>
          <InformationBox
            hasBackground
            icon={PerimetreIcon}
            articleTitle="Délégués du périmètre SDEA">
            <PerimetreDeleguesSdeaWithErrorBoundary perimetre={perimetre} />
          </InformationBox>
        </Grid>
      </Grid>
    </Container>
  );
}

const PerimetrePageWithErrorBoundary = withPageErrorBoundary(PerimetrePage);

export default PerimetrePageWithErrorBoundary;
