import type { ReactNode } from "react";
import { Box, Typography } from "@mui/material";

interface CollectiviteNonAdherenteSubtitleProps {
  message?: string;
}

function CollectiviteNonAdherenteSubtitle({
  message = "Cette commune n'a pas adhéré au SDEA",
}: Readonly<CollectiviteNonAdherenteSubtitleProps>): ReactNode {
  return (
    <Box sx={{ backgroundColor: "warning.light", px: 2, py: 1, borderRadius: "5px" }}>
      <Typography component="span" sx={{ color: "warning.dark", fontWeight: "600" }}>
        {message}
      </Typography>
    </Box>
  );
}

export default CollectiviteNonAdherenteSubtitle;
