import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import { Authorization, dateUtil } from "@sdeapps/react-core";
import { useNavigate } from "react-router-dom";
import { ApplicationRole } from "constants/ApplicationRole";
import PageTitle from "components/PageTitle";
import { routesConfig } from "config/app-config";
import ControlledDateTime from "components/inputs/ControlledDateTime";
import { useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import LoadingButton from "components/LoadingButton";
import type { DissolutionPerimetre, Perimetre } from "models";
import { networkService, perimetresService } from "services";
import {
  useErrorHandler,
  withPageErrorBoundary,
  ErrorNames,
  SdeappsError,
} from "utils/errorHandling";
import ControlledPerimetreAutoComplete from "components/inputs/ControlledPerimetreAutoComplete";
import { enqueueSnackbar } from "notistack";
import ToastMessages from "constants/ToastMessages";
import { useSearch } from "providers";
import LoadingScreen from "components/Template/LoadingScreen";

type DissoudrePerimetreFormData = {
  dateDissolution: Date | null;
  perimetre: Perimetre | null;
};

const DEFAULT_VALUES: DissoudrePerimetreFormData = {
  dateDissolution: null,
  perimetre: null,
};

function DissoudrePerimetreForm(): ReactNode {
  const navigate = useNavigate();
  const { update } = useSearch();
  const { catchErrors } = useErrorHandler();
  const { catchErrors: catchDissolutionErrors, isLoading: isSending } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: false,
    [ErrorNames.BadRequest]: (error) => {
      // Le périmètre n'a pas été dissous à cause d'une faute dans la requête,
      // on précise laquelle à l'utilisateur
      enqueueSnackbar({
        variant: "error",
        message: error.message,
      });
    },
    default: () => {
      // Le périmètre n'a pas été dissous, on affiche une snackbar d'erreur à l'utilisateur
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  const [isPerimetresLoading, setIsPerimetresLoading] = useState(true);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [perimetresSansTransfertsActifs, setPerimetresSansTransfertsActifs] = useState<
    Array<Perimetre>
  >([]);
  useEffect(() => {
    async function getPerimetres(): Promise<void> {
      const _perimetres = await perimetresService.getAllSansTransfertsActifs();
      _perimetres.sort((a, b) => a.libelle.localeCompare(b.libelle));
      setPerimetresSansTransfertsActifs(_perimetres);
      setIsPerimetresLoading(false);
    }
    void catchErrors(getPerimetres);
  }, [catchErrors]);

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<DissoudrePerimetreFormData>({
    shouldFocusError: false,
    defaultValues: DEFAULT_VALUES,
    mode: "onChange",
  });

  const sendData: SubmitHandler<DissoudrePerimetreFormData> = async function ({
    dateDissolution,
    perimetre,
  }: DissoudrePerimetreFormData): Promise<void> {
    if (dateDissolution == null || perimetre == null) {
      return;
    }

    await catchDissolutionErrors(async () => {
      const payload: DissolutionPerimetre = {
        date: dateUtil.format(dateDissolution, "yyyy-MM-dd"),
      };
      await perimetresService.dissoudre(payload, perimetre.territoire.id, perimetre.id);

      await networkService.waitForReplication(
        async () => {
          try {
            await perimetresService.getById(perimetre.id);
          } catch (error) {
            return; // périmètre non trouvé, ça veut dire qu'il est bien supprimé, donc on return pour tomber dans le cas de succès.
          }
          throw new SdeappsError();
        },
        20,
        update,
        () => {
          enqueueSnackbar({
            variant: "warning",
            message: ToastMessages.LONG_REPLICATION,
          });
          update();
        }
      );

      enqueueSnackbar({
        variant: "success",
        message: ToastMessages.DISSOLVED_PERIMETRE,
      });
      navigate(routesConfig.admin.path);
    });
  };

  function openConfirmationDialog(): void {
    setIsConfirmationDialogOpen(true);
  }

  function closeConfirmationDialog(): void {
    setIsConfirmationDialogOpen(false);
  }

  if (isPerimetresLoading) return <LoadingScreen />;

  return (
    <Container>
      <Authorization roles={ApplicationRole.ADMINISTRATOR}>
        <PageTitle title="Dissolution de périmètre" />
        {perimetresSansTransfertsActifs.length > 0 ? (
          <Grid container spacing={2} component="form" onSubmit={openConfirmationDialog} size={12}>
            <Grid size={12}>
              <Typography variant="subtitle1">Choix du périmètre à dissoudre</Typography>
            </Grid>

            <Grid size={{ xs: 12, sm: 6 }}>
              <ControlledDateTime
                name="dateDissolution"
                control={control}
                rules={{
                  required: "Choisir une date d'effet de dissolution est obligatoire",
                  validate: (value: Date | null) =>
                    dateUtil.isValid(value) || "Veuillez renseigner une date valide",
                }}
                label="Date d'effet de la dissolution *"
              />
            </Grid>

            <Grid container spacing={2} size={{ xs: 12, md: 6 }}>
              <Grid size={12}>
                <ControlledPerimetreAutoComplete
                  name="perimetre"
                  label="Périmètre *"
                  rules={{
                    required: "Ce champ est obligatoire",
                  }}
                  control={control}
                  perimetres={perimetresSansTransfertsActifs}
                />
              </Grid>
            </Grid>

            <Grid container sx={{ justifyContent: "flex-end" }} size={12}>
              <Button href={routesConfig.admin.path} color="error">
                Annuler
              </Button>
              <Button variant="contained" onClick={openConfirmationDialog} disabled={!isValid}>
                Dissoudre
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Typography>Il n'existe pas de périmètres sans transferts affectés.</Typography>
        )}

        <Dialog open={isConfirmationDialogOpen} onClose={closeConfirmationDialog}>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr de vouloir dissoudre ce périmètre SDEA ? Le périmètre dissous ne sera
              plus affiché dans l'application.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeConfirmationDialog} color="error">
              Annuler
            </Button>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <LoadingButton onClick={handleSubmit(sendData)} variant="contained" loading={isSending}>
              Confirmer
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Authorization>
    </Container>
  );
}

const DissoudrePerimetreWithErrorBoundary = withPageErrorBoundary(DissoudrePerimetreForm);

export default DissoudrePerimetreWithErrorBoundary;
