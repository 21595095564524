import { useEffect } from "react";
import type { ReactNode } from "react";
import CompetencesEtPorteesInformationBox from "components/Informations/SousCompetences/CompetencesEtPorteesInformationBox";
import PerimetrePorteesInformationsWithErrorBoundary from "./PerimetrePorteesInformations";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import type { SdeappsError } from "utils/errorHandling";
import type { Transfert, Perimetre } from "models";
import LoadingScreen from "components/Template/LoadingScreen";
import { DisplayDatesProvider } from "providers";
import { MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "config/app-config";
import { Authorization } from "@sdeapps/react-core";
import { ApplicationRole } from "constants/ApplicationRole";

interface PerimetrePorteesInformationsProps {
  transferts: Array<Transfert>;
  perimetre: Perimetre;
  isLoading?: boolean;
  error?: SdeappsError;
}

function PerimetrePorteesInformationsBox({
  transferts,
  perimetre,
  isLoading = false,
  error,
}: Readonly<PerimetrePorteesInformationsProps>): ReactNode {
  const { setOriginalError } = useErrorHandler();
  useEffect(() => {
    if (error != null) {
      setOriginalError(error);
    }
  }, [error, setOriginalError]);

  const navigate = useNavigate();

  return (
    <DisplayDatesProvider>
      <CompetencesEtPorteesInformationBox
        noChildrenPadding={false}
        additionalMenuItems={
          <Authorization roles={ApplicationRole.ADMINISTRATOR} key="modification">
            <MenuItem
              onClick={() => {
                navigate(routesConfig.perimetreEditTransferts.getParameterPath(perimetre.id));
              }}>
              <EditIcon color="primary" sx={{ mr: 1 }} />
              Modification
            </MenuItem>
          </Authorization>
        }>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <PerimetrePorteesInformationsWithErrorBoundary
            transferts={transferts}
            perimetre={perimetre}
          />
        )}
      </CompetencesEtPorteesInformationBox>
    </DisplayDatesProvider>
  );
}

const PerimetrePorteesInformationsBoxWithErrorBoundary = withComponentErrorBoundary(
  PerimetrePorteesInformationsBox
);

export default PerimetrePorteesInformationsBoxWithErrorBoundary;
