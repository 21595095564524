import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Grid2 as Grid } from "@mui/material";
import { arrayUtil } from "@sdeapps/react-core";
import type { Transfert } from "models";
import CommuneSousCompetencesAccordionSummary from "./CommuneSousCompetencesAccordionSummary";
import EauAssGepuSousCompetencesAccordion from "components/Informations/SousCompetences/EauAssGepuSousCompetencesAccordion";

interface CommuneEauAssGepuSousCompetencesProps {
  transferts: Array<Transfert>;
}

function CommuneEauAssGepuSousCompetences({
  transferts,
}: Readonly<CommuneEauAssGepuSousCompetencesProps>): ReactNode {
  const [transfertsByPerimetre, setTransfertsByPerimetre] = useState<Array<Array<Transfert>>>([]);

  useEffect(() => {
    const sCbP = arrayUtil.groupByArray(transferts, (t) => `${t.idPerimetre}${t.idEpci}`);

    setTransfertsByPerimetre(sCbP);
  }, [transferts]);

  return (
    <Grid container spacing={2} size={12}>
      {transfertsByPerimetre.map((_transferts) => (
        <EauAssGepuSousCompetencesAccordion
          key={`${_transferts[0]?.idPerimetre}${_transferts[0]?.idEpci}`}
          defaultExpanded={transfertsByPerimetre.length === 1}
          transferts={_transferts}
          summary={
            <CommuneSousCompetencesAccordionSummary
              epci={_transferts[0].epci}
              perimetre={_transferts[0].perimetre}
            />
          }
        />
      ))}
    </Grid>
  );
}

export default CommuneEauAssGepuSousCompetences;
