import type { ReactNode } from "react";
import { Paper } from "@mui/material";
import type { Adresse } from "models";
import EtablissementPublicMapSkeleton from "./EtablissementPublicMapSkeleton";
import InformationsError from "components/Errors/InformationsError";

interface EtablissementPublicMapProps {
  adresse: Adresse | null;
  isLoading?: boolean;
  error?: boolean;
}

function EtablissementPublicMap({
  adresse,
  isLoading = false,
  error = false,
}: Readonly<EtablissementPublicMapProps>): ReactNode {
  if (isLoading) {
    return <EtablissementPublicMapSkeleton />;
  }

  if (error) {
    return (
      <Paper elevation={1} sx={{ m: 0, p: 2, width: "100%" }}>
        <InformationsError />
      </Paper>
    );
  }

  return (
    <Paper elevation={1} sx={{ m: 0, p: 0, width: "100%", height: "20rem" }}>
      <iframe
        src={`https://maps.google.com/maps?q=${adresse?.numeroVoie}+${adresse?.commune?.replace("cedex", "")}+France&z=16&output=embed`}
        loading="lazy"
        allowFullScreen
        width="100%"
        height="100%"
        style={{ border: "1px solid rgba(0,0,0,0.2)" }}
        title="Google Maps"
      />
    </Paper>
  );
}

export default EtablissementPublicMap;
