import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import {
  Button,
  Grid2 as Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import TelephoneSpan from "components/Span/TelephoneSpan";
import AdresseSpan from "components/Span/AdresseSpan";
import { elusService } from "services";
import { useErrorHandler, withComponentErrorBoundary, ErrorNames } from "utils/errorHandling";
import type { PersonneInfosPrivees, Personne } from "models";
import { Authorization, dateUtil } from "@sdeapps/react-core";
import MailSpan from "components/Span/MailSpan";
import { ApplicationRole } from "constants/ApplicationRole";

interface EluContactInformationsPriveesProps {
  elu: Personne;
  setIsModifying: (m: boolean) => void;
}

function EluContactInformationsPrivees({
  elu,
  setIsModifying,
}: Readonly<EluContactInformationsPriveesProps>): ReactNode {
  const [eluInfosPrivees, setEluInfosPrivees] = useState<PersonneInfosPrivees>();

  const { catchErrors, isLoading } = useErrorHandler();

  useEffect(() => {
    async function getElu(): Promise<void> {
      const _eluInfosPrivees = await elusService.getPersonneInfosPriveesById(elu.id);

      setEluInfosPrivees(_eluInfosPrivees);
    }

    void catchErrors(getElu);
  }, [catchErrors, elu]);

  return (
    <Grid container size={12}>
      <Grid
        container
        columnSpacing={1}
        sx={{ alignItems: "center", justifyContent: "center" }}
        size={12}>
        <Grid size="auto">
          <WarningAmberIcon color="warning" />
        </Grid>
        <Grid>
          <Typography sx={{ fontWeight: "500" }}>
            Attention ! Ces données sont à caractère personnel et confidentiel, merci de ne pas les
            diffuser.
          </Typography>
        </Grid>
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 4 }}>
        <List>
          <ListItem>
            <ListItemIcon>
              <CallOutlinedIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: "bold" }}>Téléphone</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <TelephoneSpan
                telephone={eluInfosPrivees?.telephonePersonnel}
                label="Personnel"
                hasLink
                isLoading={isLoading}
              />
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <TelephoneSpan
                telephone={eluInfosPrivees?.telephonePortable}
                label="Portable"
                hasLink
                isLoading={isLoading}
              />
            </ListItemText>
          </ListItem>
        </List>
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 4 }}>
        <List>
          <ListItem>
            <ListItemIcon>
              <MailOutlinedIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: "bold" }}>Adresse</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <AdresseSpan
                adresse={{
                  numeroVoie: eluInfosPrivees?.rue,
                  codePostal: eluInfosPrivees?.codePostal,
                  commune: eluInfosPrivees?.commune,
                }}
                isLoading={isLoading}
              />
            </ListItemText>
          </ListItem>
        </List>
      </Grid>
      <Grid size={{ xs: 12, lg: 4 }}>
        <List>
          <ListItem>
            <ListItemIcon>
              <AlternateEmailOutlinedIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: "bold" }}>E-mail</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <MailSpan mail={eluInfosPrivees?.emailPrincipal} isLoading={isLoading} />
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <MailSpan mail={eluInfosPrivees?.emailSecondaire} isLoading={isLoading} />
            </ListItemText>
          </ListItem>
        </List>
      </Grid>
      {elu.dateNaissance != null && (
        <Grid size={{ xs: 12, md: 6, lg: 4 }}>
          <List>
            <ListItem>
              <Typography>
                Né{elu.sexe === "F" && "e"} le{" "}
                <Typography component="span" sx={{ fontWeight: 600 }}>
                  {dateUtil.format(elu.dateNaissance, "dd/MM/yyyy")}
                </Typography>{" "}
                (
                {Math.floor(
                  dateUtil.differenceInMinutes(undefined, elu.dateNaissance) / 60 / 24 / 365
                )}{" "}
                ans)
              </Typography>
            </ListItem>
          </List>
        </Grid>
      )}
      <Authorization roles={ApplicationRole.ELUS_WRITE_ALL}>
        <Grid container sx={{ justifyContent: "flex-end" }} size={{ xs: 12, md: 6, lg: 8 }}>
          <Grid>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setIsModifying(true);
              }}>
              Modifier les données privées
            </Button>
          </Grid>
        </Grid>
      </Authorization>
    </Grid>
  );
}

const EluContactInformationsPriveesWithErrorBoundary = withComponentErrorBoundary(
  EluContactInformationsPrivees,
  {
    [ErrorNames.Forbidden]: {
      fallback: () => (
        <Grid size={12}>
          <Typography>
            Vous ne disposez pas des droits nécessaires à la consultation des données privées.
            <br />
            Adressez-vous au service juridique pour plus d'informations.
          </Typography>
        </Grid>
      ),
    },
  }
);

export default EluContactInformationsPriveesWithErrorBoundary;
