import { useEffect, useState } from "react";
import type { PropsWithChildren, ReactNode } from "react";
import { Grid2 as Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import type { TypographyProps } from "@mui/material";
import BackButton from "./BackButton";
import type Competence from "constants/Competence";
import CompetenceChip from "./Typography/CompetenceChip";

interface PageTitleProps extends PropsWithChildren {
  noBackButton?: boolean;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  icon?: string | ReactNode;
  typographyProps?: TypographyProps;
  competences?: Array<Competence> | Competence;
}

function PageTitle({
  noBackButton = false,
  children,
  title,
  subtitle,
  icon,
  typographyProps,
  competences: originalCompetences,
}: Readonly<PageTitleProps>): ReactNode {
  const [competences, setCompetences] = useState<Array<Competence>>([]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (originalCompetences != null) {
      setCompetences(
        Array.isArray(originalCompetences) ? originalCompetences : [originalCompetences]
      );
    } else {
      setCompetences([]);
    }
  }, [originalCompetences]);

  return (
    <Grid
      container
      size={12}
      sx={{
        alignItems: "center",
        py: 2,
        mt: -2,
        background: "white",
        position: "sticky",
        top: { xs: "54px", sm: "62px" },
        zIndex: 10,
      }}>
      {!noBackButton && (
        <Grid>
          <BackButton sx={{ ml: -2 }} />
        </Grid>
      )}
      {icon != null && (
        <Grid sx={{ paddingRight: 1 }}>
          <Typography>{icon}</Typography>
        </Grid>
      )}
      <Grid size="grow">
        <Typography variant={isSmallScreen ? "h3" : "h2"} {...typographyProps}>
          {title}
        </Typography>
      </Grid>
      {(subtitle != null || competences != null) && (
        <Grid container spacing={1} size={12}>
          <Grid>
            <Typography variant="caption" sx={{ fontSize: { xs: ".9rem", md: "1rem" }, ml: 3.5 }}>
              {subtitle}
            </Typography>
          </Grid>
          {competences.map((competence) => (
            <Grid key={competence}>
              <CompetenceChip competence={competence} />
            </Grid>
          ))}
        </Grid>
      )}
      {children}
    </Grid>
  );
}

export default PageTitle;
