import type { ReactNode } from "react";
import { Grid2 as Grid, List, ListItem, Skeleton } from "@mui/material";

function ElusInformationsSkeleton(): ReactNode {
  return (
    <List sx={{ width: "100%" }}>
      {[0, 1, 2].map((i) => (
        <ListItem key={i} sx={{ display: "inline-block" }}>
          <Skeleton variant="text" animation="wave" sx={{ height: "30px", width: "50%" }} />
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid size="grow">
              <Skeleton variant="text" animation="wave" sx={{ height: "30px", width: "50%" }} />
            </Grid>
            <Grid container spacing={1} size="grow" sx={{ justifyContent: "flex-end" }}>
              <Grid size="auto">
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ width: "35px", height: "30px", textAlign: "right" }}
                />
              </Grid>
              <Grid size="auto">
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ width: "35px", height: "30px", textAlign: "right" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </List>
  );
}

export default ElusInformationsSkeleton;
