import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import type { Personne, EtablissementPublic } from "models";
import EluContactInformationsPubliques from "./EluContactInformationsPubliques";
import EluContactInformationsPriveesWithErrorBoundary from "./EluContactInformationsPriveesWithErrorBoundary";
import EluContactInformationsPriveesModificationWithErrorBoundary from "./EluContactInformationsPriveesModificationWithErrorBoundary";
import { etablissementUtil } from "utils";
import { useErrorHandler, withErrorBoundary } from "utils/errorHandling";
import { etablissementsPublicsService } from "services";
import { dateUtil } from "@sdeapps/react-core";

interface EluContactInformationsProps {
  elu: Personne;
  isModifying: boolean;
  setIsModifying: (m: boolean) => void;
}

function EluContactInformations({
  elu,
  isModifying,
  setIsModifying,
}: Readonly<EluContactInformationsProps>): ReactNode {
  const [etablissementPublicOpenData, setEtablissementPublicOpenData] = useState<
    EtablissementPublic | undefined
  >();
  const [etablissementPublicSdea, setEtablissementPublicSdea] = useState<
    EtablissementPublic | undefined
  >();

  const { error: etablissementError, catchErrors, isLoading } = useErrorHandler();

  useEffect(() => {
    async function getAdresse(): Promise<void> {
      let mandat = elu.mandats?.find((m) => m.typeMandat === "commune");
      mandat ??= elu.mandats?.find((m) => m.typeMandat === "epci");
      if (mandat?.codeCollectivite != null) {
        const _etablissementsPublics =
          mandat.typeMandat === "commune"
            ? await etablissementsPublicsService.getMairiesByCodeInsee(mandat.codeCollectivite)
            : await etablissementsPublicsService.getEpciSiegesBySiren(mandat.codeCollectivite);
        const [openData, sdea] =
          etablissementUtil.getEtablissementPublicFromSource(_etablissementsPublics);
        setEtablissementPublicOpenData(openData);
        setEtablissementPublicSdea(sdea);
      }
    }

    void catchErrors(getAdresse);
  }, [catchErrors, elu]);

  return (
    <Grid container spacing={2} size={12}>
      <Grid size={12}>
        <Typography color="primary" sx={{ fontWeight: "bold" }}>
          Coordonnées publiques
        </Typography>
        <Typography variant="caption">
          Source : données publiques. Mise à jour le{" "}
          {dateUtil.format(etablissementPublicOpenData?.dateModification, "dd/MM/yyyy")}
        </Typography>
        <EluContactInformationsPubliques
          etablissement={etablissementPublicOpenData}
          isLoading={isLoading}
          etablissementError={etablissementError}
        />
      </Grid>
      {etablissementPublicSdea != null && (
        <Grid size={12}>
          <Typography variant="caption">
            Source : données SDEA. Mise à jour par {etablissementPublicSdea.modifiedBy?.name} le{" "}
            {dateUtil.format(etablissementPublicSdea?.dateModification, "dd/MM/yyyy")}
          </Typography>
          <EluContactInformationsPubliques
            etablissement={etablissementPublicSdea}
            isLoading={isLoading}
            etablissementError={etablissementError}
          />
        </Grid>
      )}
      <Grid size={12}>
        <Typography color="primary" sx={{ fontWeight: "bold" }}>
          Coordonnées privées
        </Typography>
      </Grid>
      {isModifying ? (
        <EluContactInformationsPriveesModificationWithErrorBoundary
          elu={elu}
          setIsModifying={setIsModifying}
        />
      ) : (
        <EluContactInformationsPriveesWithErrorBoundary elu={elu} setIsModifying={setIsModifying} />
      )}
    </Grid>
  );
}

const EluContactInformationsWithErrorBoundary = withErrorBoundary(EluContactInformations);

export default EluContactInformationsWithErrorBoundary;
