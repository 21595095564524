import Competence from "constants/Competence";
import competenceMap from "constants/CompetenceMap";
import GceContrats from "constants/GceContrats";
import { GepuSousCompetencesId } from "constants/GepuSousCompetencesId";
import { EauAssPorteesId, EauAssSousCompetencesId } from "constants/PorteesEauAss";
import type { Transfert } from "models";
import { SdeappsError } from "utils/errorHandling";

function getCompetence(transfert: Transfert | string): Competence {
  const transfertCode: string = isinstanceOfTransfert(transfert) ? transfert?.code : transfert;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, _competence, _sousCompetenceId, _porteeId] = decomposePorteeCode(transfertCode);

  const competence = competenceMap.get(_competence as Competence);

  if (competence == null) {
    throw new SdeappsError(`Competence non trouvée dans le code transfert '${transfertCode}'`);
  }

  return competence.competence;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isinstanceOfTransfert(object: any): object is Transfert {
  return typeof object !== "string";
}

function getSousCompetenceId(transfert: Transfert | string): string {
  const transfertCode: string = isinstanceOfTransfert(transfert) ? transfert?.code : transfert;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, _competence, _sousCompetenceId, _porteeId] = decomposePorteeCode(transfertCode);

  if (_sousCompetenceId === "") {
    throw new SdeappsError(`SousCompetence non trouvée dans le code transfert ${transfertCode}`);
  }

  return _sousCompetenceId;
}

function getSousCompetenceLabel(transfert: Transfert | string): string {
  let sousCompetenceLabel: string | undefined;

  switch (getCompetence(transfert)) {
    case Competence.EAU:
    case Competence.ASS:
      sousCompetenceLabel = EauAssSousCompetencesId[getSousCompetenceId(transfert)];
      break;
    case Competence.GCE:
      sousCompetenceLabel = GceContrats.find(
        (c) => c.code === porteesUtil.getSousCompetenceId(transfert)
      )?.nom;
      break;
    case Competence.GEPU:
      sousCompetenceLabel = GepuSousCompetencesId[getSousCompetenceId(transfert)];
      break;
  }

  return sousCompetenceLabel ?? "";
}

function getPorteeId(transfert: Transfert | string): number {
  const transfertCode: string = isinstanceOfTransfert(transfert) ? transfert?.code : transfert;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, _competence, _sousCompetenceId, _porteeId] = decomposePorteeCode(transfertCode);

  if (_porteeId === "") {
    throw new SdeappsError(`Portée non trouvée dans le code transfert ${transfertCode}`);
  }

  return parseInt(_porteeId, 10);
}

function getPorteeLabel(transfert: Transfert | string): string {
  let porteeLabel: string = "";

  switch (getCompetence(transfert)) {
    case Competence.EAU:
    case Competence.ASS:
      porteeLabel = EauAssPorteesId[getPorteeId(transfert)];
      break;
    case Competence.GCE:
    case Competence.GEPU:
      break;
  }

  return porteeLabel;
}

function decomposePorteeCode(
  codeTransfert: string
): [_: string, competence: string, sousCompetenceId: string, porteeId: string] {
  return (/^([A-Z]+)-((?:[A-Z]|\d)+)-(\d+)$/gi.exec(codeTransfert) ?? ["", "", "", ""]) as [
    _: string,
    competence: string,
    sousCompetenceId: string,
    porteeId: string,
  ];
}

export const porteesUtil = {
  getCompetence,
  getSousCompetenceId,
  getSousCompetenceLabel,
  getPorteeId,
  getPorteeLabel,
};
