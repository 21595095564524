import type { ReactNode } from "react";
import { Grid2 as Grid, Typography, Link } from "@mui/material";
import { grey } from "@mui/material/colors";
import type { EtablissementPublic } from "models";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EtablissementPublicSkeleton from "./EtablissementPublicSkeleton";
import InformationsError from "components/Errors/InformationsError";
import AdresseSpan from "components/Span/AdresseSpan";
import EtablissementPublicTitle from "./EtablissementPublicTitle";
import LanguageIcon from "@mui/icons-material/Language";

interface EtablissementPublicInformationProps {
  etablissementPublic: EtablissementPublic;
  isLoading?: boolean;
  error?: boolean;
}

function EtablissementPublicInformation({
  etablissementPublic,
  isLoading = false,
  error = false,
}: Readonly<EtablissementPublicInformationProps>): ReactNode {
  if (isLoading) {
    return <EtablissementPublicSkeleton />;
  }

  if (error) {
    return <InformationsError />;
  }

  return (
    <Grid container spacing={1} size={12}>
      <Grid size={12}>
        <EtablissementPublicTitle etablissementPublic={etablissementPublic} />
      </Grid>
      {etablissementPublic?.adresse != null && (
        <Grid container size={12} sx={{ gap: 1, alignItems: "center" }}>
          <MailOutlinedIcon fontSize="small" sx={{ color: grey[700] }} />
          <Typography>
            <AdresseSpan adresse={etablissementPublic?.adresse} />
          </Typography>
        </Grid>
      )}
      {etablissementPublic?.telephone != null && (
        <Grid container size={12} sx={{ gap: 1, alignItems: "center" }}>
          <LocalPhoneOutlinedIcon fontSize="small" sx={{ color: grey[700] }} />
          <Link target="_blank" href={"tel:" + etablissementPublic?.telephone.trim()}>
            {etablissementPublic?.telephone}
          </Link>
        </Grid>
      )}
      {etablissementPublic?.url != null && (
        <Grid container size={12} sx={{ gap: 1, alignItems: "center" }}>
          <LanguageIcon fontSize="small" sx={{ color: grey[700] }} />
          <Link target="_blank" href={etablissementPublic.url}>
            {etablissementPublic.url.replace(/https?:\/\/(www\.)?/i, "").replace(/\/$/i, "")}
          </Link>
        </Grid>
      )}
      {etablissementPublic?.email != null && (
        <Grid container size={12} sx={{ gap: 1, alignItems: "center" }}>
          <AlternateEmailIcon fontSize="small" sx={{ color: grey[700] }} />
          <Link href={"mailto:" + etablissementPublic?.email}>{etablissementPublic?.email}</Link>
        </Grid>
      )}
    </Grid>
  );
}

export default EtablissementPublicInformation;
