import { Endpoints } from "constants/endpoints";
import type {
  PerimetreModelForm,
  Perimetre,
  FusionPerimetres,
  Transfert,
  AssociationPerimetreCommunesAssocieesDelegueesDto,
  PatchData,
  DissolutionPerimetre,
} from "models";
import { arrayUtil, dateUtil } from "@sdeapps/react-core";
import { SdeappsError } from "utils/errorHandling";
import { collectivitesApiClient } from "./apiClients";

/**
 * Récupère tous les périmètres du SDEA
 * @returns une liste de périmètres
 */
async function getAll(): Promise<Array<Perimetre>> {
  const url = `/${Endpoints.PERIMETRES}`;
  return collectivitesApiClient.get(url);
}

/**
 * Récupère tous les périmètres sans transferts actifs du SDEA
 * @returns une liste de périmètres
 */
async function getAllSansTransfertsActifs(): Promise<Array<Perimetre>> {
  const url = `/${Endpoints.PERIMETRES_SANS_TRANSFERTS_ACTIFS}`;
  return collectivitesApiClient.get(url);
}

/**
 * Récupère un périmètre en fonction de son id
 * @param id l'id du périmètre recherché
 * @returns un périmètre
 */
async function getById(id: string): Promise<Perimetre> {
  const url = `/${Endpoints.PERIMETRES}/${id}`;
  return collectivitesApiClient.get(url);
}

/**
 * Récupère les périmètres concernés par une liste de Transferts
 * @param transferts la liste de Transferts
 * @returns une liste de périmètres
 */
async function getByTransferts(transferts: Array<Transfert>): Promise<Array<Perimetre>> {
  const _transferts = arrayUtil.dedupArray(
    transferts,
    (transfert: Transfert) => transfert.idPerimetre
  );
  const _perimetres = await Promise.all(
    _transferts.map(async (transfert: Transfert) => getById(transfert.idPerimetre))
  );

  return _perimetres;
}

/**
 * Récupère les périmètres d'un territoire
 * @param idTerritoire l'id du territoire
 * @returns Une liste de périmètres
 */
async function getByTerritoire(idTerritoire: string): Promise<Array<Perimetre>> {
  const url = `/${Endpoints.TERRITOIRES}/${idTerritoire}/${Endpoints.PERIMETRES}`;
  return collectivitesApiClient.get(url);
}

/**
 * Crée un nouveau périmètre
 * @param model les informations du périmètre à créer
 * @returns Le territoire contenant le périmètre créé
 */
async function create(model: PerimetreModelForm): Promise<Perimetre> {
  const url = `/${Endpoints.TERRITOIRES}/${model.idTerritoire}/${Endpoints.PERIMETRE}`;

  if (model?.dateMembre == null) {
    throw new SdeappsError("La date ne peut pas être null");
  }

  const sendData = {
    ...model,
    dateMembre: dateUtil.format(model.dateMembre, "yyyy-MM-dd"),
  };
  return collectivitesApiClient.post(url, sendData);
}

/**
 * Modifie un périmètre
 * @param data `Array<PatchData>` les PatchData représentant les modifications à effectuer sur le périmètre
 * @param idTerritoireInitial `string` l'id du territoire du périmètre avant modification
 * @param idPerimetre `string` l'id du périmètre
 * @returns Le territoire contenant le périmètre modifié
 */
async function modify(
  data: Array<PatchData>,
  idTerritoireInitial: string,
  idPerimetre: string
): Promise<void> {
  const url = `/${Endpoints.TERRITOIRES}/${idTerritoireInitial}/${Endpoints.PERIMETRES}/${idPerimetre}`;

  return collectivitesApiClient.patch(url, data);
}

/**
 * Fusionne 2 périmètres
 * @param model les informations de la fusion de périmètres
 */
async function fusion(model: FusionPerimetres): Promise<void> {
  const url = `/${Endpoints.FUSIONS}`;
  return collectivitesApiClient.post(url, model);
}

/**
 * Dissout un périmètre
 * @param model les informations de la dissolution de périmètre
 */
async function dissoudre(
  model: DissolutionPerimetre,
  idTerritoire: string,
  idPerimetre: string
): Promise<void> {
  const url = `${Endpoints.TERRITOIRES}/${idTerritoire}/${Endpoints.PERIMETRES}/${idPerimetre}/${Endpoints.DISSOUDRE}`;
  return collectivitesApiClient.put(url, model);
}

/**
 * Associe un périmètre aux communes associées déléguées d'une commune chef lieu
 * @param payload les informations du périmètre, de la commune chef lieu et des affectations des communes associées déléguées
 */
async function associerPerimetreCommunesAssocieesDeleguees(
  payload: AssociationPerimetreCommunesAssocieesDelegueesDto
): Promise<void> {
  const url = `/${Endpoints.ASSOCIER_PERIMETRE_COMMUNES_ASSOCIEES_DELEGUEES}`;

  return collectivitesApiClient.post(url, payload);
}

export const perimetresService = {
  getAll,
  getAllSansTransfertsActifs,
  getById,
  getByTransferts,
  getByTerritoire,
  create,
  modify,
  fusion,
  dissoudre,
  associerPerimetreCommunesAssocieesDeleguees,
};
