import type { ReactNode } from "react";
import { Grid2 as Grid } from "@mui/material";
import type { Personne } from "models";
import ElusInformationsSkeleton from "./ElusInformationsSkeleton";
import InformationsError from "components/Errors/InformationsError";
import EluLink from "components/Links/EluLink";
import EluInformationsFonction from "./EluInformationsFonction";

interface ElusInformationsProps {
  elus: Array<Personne>;
  isLoading?: boolean;
  error?: boolean;
}

function ElusInformations({
  elus,
  isLoading = false,
  error = false,
}: Readonly<ElusInformationsProps>): ReactNode {
  if (isLoading) {
    return <ElusInformationsSkeleton />;
  }

  if (error) {
    return <InformationsError />;
  }

  return (
    <Grid container spacing={2} size={12} sx={{ alignContent: "flex-start" }}>
      {elus.map((p) => (
        <Grid container key={p.id} size={12} spacing={0}>
          <Grid size={12}>
            <EluLink elu={p} fontWeight="600" />
          </Grid>
          {p.mandats?.map((mandat) => {
            if (mandat?.libelleFonction == null) {
              return null;
            }
            return (
              <EluInformationsFonction
                key={`${mandat?.libelleFonction}${mandat?.idPersonne}`}
                mandat={mandat}
              />
            );
          })}
        </Grid>
      ))}
    </Grid>
  );
}

export default ElusInformations;
