import type Fonction from "../models/Fonction";

const fonctions: Array<Fonction> = [
  { id: "7", libelle: "Président du SDEA" },
  {
    id: "8",
    libelle: "Représentant à la Commission Permanente",
    displayFields: { territoire: true },
  },
  { id: "16", libelle: "Président du Conseil Territorial", displayFields: { territoire: true } },
  { id: "18", libelle: "Conseiller Territorial", displayFields: { territoire: true } },
  {
    id: "19",
    libelle: "Délégué à l'Assemblée Générale",
    displayFields: {
      competence: true,
      territoire: true,
      perimetre: true,
      commune: true,
    },
  },
  {
    id: "20",
    libelle: "Président de la Commission Locale",
    displayFields: {
      competence: true,
      territoire: true,
      perimetre: true,
    },
  },
  { id: "48", libelle: "Vice-Président Honoraire du SDEA" },
  { id: "49", libelle: "Administrateur Honoraire du SDEA" },
  { id: "50", libelle: "Président Honoraire du SDEA" },
  { id: "51", libelle: "Vice-Président du SDEA" },
  { id: "52", libelle: "Administrateur du SDEA", displayFields: { territoire: true } },
  {
    id: "53",
    libelle: "Président de la Commission d'Appel d'Offres",
    displayFields: { territoire: true },
  },
  { id: "54", libelle: "Titulaire" },
  { id: "55", libelle: "Suppléant" },
  { id: "57", libelle: "Pilote Elu" },
  { id: "60", libelle: "Président de Territoire", displayFields: { territoire: true } },
  {
    id: "61",
    libelle: "Président de la Commission des Marchés",
    displayFields: { territoire: true },
  },
  {
    id: "62",
    libelle: "Suppléant au Président de la Commission des Marchés",
    displayFields: { territoire: true },
  },
  {
    id: "64",
    libelle: "Suppléant au Président de la Commission Locale",
    displayFields: {
      competence: true,
      territoire: true,
      perimetre: true,
      commune: true,
    },
  },
  {
    id: "67",
    libelle: "Suppléant au Président de Territoire",
    displayFields: { territoire: true },
  },
  { id: "68", libelle: "Titulaire à la Commission Administrative Paritaire CAT A" },
  { id: "69", libelle: "Suppléant à la Commission Administrative Paritaire CAT A" },
  { id: "70", libelle: "Titulaire à la Commission Administrative Paritaire CAT B" },
  { id: "71", libelle: "Suppléant à la Commission Administrative Paritaire CAT B" },
  { id: "72", libelle: "Titulaire à la Commission Administrative Paritaire CAT C" },
  { id: "73", libelle: "Suppléant à la Commission Administrative Paritaire CAT C" },
  { id: "79", libelle: "Titulaire à la Commission de Réforme" },
  { id: "80", libelle: "Suppléant à la Commission de Réforme" },
  { id: "81", libelle: "Titulaire à la Commission Consultative des Services Publics Locaux" },
  { id: "82", libelle: "Suppléant à la Commission Consultative des Services Publics Locaux" },
  { id: "83", libelle: "Membre de la Commission Thématique Amélioration Continue" },
  { id: "84", libelle: "Membre de la Commission Thématique Commande Publique" },
  { id: "85", libelle: "Membre de la Commission Thématique Communication" },
  { id: "86", libelle: "Membre de la Commission Thématique Développement Durable" },
  { id: "87", libelle: "Membre de la Commission Thématique Energie" },
  { id: "88", libelle: "Membre de la Commission Thématique Finances" },
  { id: "89", libelle: "Membre de la Commission Thématique Gestion de Crise - risque et sureté" },
  { id: "90", libelle: "Membre de la Commission Thématique Innovation" },
  { id: "91", libelle: "Membre de la Commission Thématique relations avec les usagers" },
  { id: "92", libelle: "Membre de la Commission Thématique Ressources Humaines" },
  {
    id: "93",
    libelle:
      "Membre de la Commission Thématique Schémas Directeurs AEP - Expertise / Veille Technique",
  },
  {
    id: "94",
    libelle: "Suppléant de la Commission d'Appel d'Offres",
    displayFields: { territoire: true },
  },
  {
    id: "95",
    libelle: "Titulaire de la Commission d'Appel d'Offres",
    displayFields: { territoire: true },
  },

  { id: "153", libelle: "Membre de la Commission Thématique Transition écologique et énergétique" },
  { id: "154", libelle: "Membre de la Commission Thématique Eau et urbanisme" },
  {
    id: "155",
    libelle: "Membre de la Commission Thématique Eau, agriculture, préservation de la ressource",
  },
  { id: "156", libelle: "Membre de la Commission Thématique Inondation et coulées d'eau boueuses" },
  {
    id: "157",
    libelle: "Membre de la Commission Thématique Gestion des risques, crises et sûreté",
  },
  {
    id: "158",
    libelle:
      "Membre de la Commission Thématique Prospective, gestion durable eau et assainissement",
  },
  { id: "159", libelle: "Membre de la Commission Thématique Communication" },
  { id: "160", libelle: "Membre de la Commission Thématique Achat et Patrimoine Foncier" },
  { id: "161", libelle: "Membre de la Commission Thématique Coopération décentralisée" },
  { id: "162", libelle: "Membre de la Commission Thématique GEPU" },
  { id: "163", libelle: "Titulaire au Comité Social Territorial" },
  { id: "164", libelle: "Suppléant au Comité Social Territorial" },
  { id: "165", libelle: "Président du Comité Social Territorial" },
];

export default fonctions;
