export enum Endpoints {
  COMMUNES = "communes",
  COMMUNES_ASSOCIEES_DELEGUEES = "communes-associees-deleguees",
  MAIRIES = "mairies",
  REGIONS = "regions",
  DEPARTEMENTS = "departements",
  ARRONDISSEMENTS = "arrondissements",
  CANTONS = "cantons",
  EPCIS = "epcis",
  SIEGES = "sieges",
  FUSIONS = "fusions",
  DISSOUDRE = "dateDissolution",
  PERSONNES = "personnes",
  TERRITOIRES = "territoires",
  PERIMETRES = "perimetres",
  PERIMETRES_SANS_TRANSFERTS_ACTIFS = "perimetres-sans-transferts-actifs",
  PERIMETRE = "perimetre",
  TRANSFERTS = "transferts",
  TRANSFERTS_AFFECTATION_EPCI = "affectation-epci",
  TRANSFERTS_AFFECTATION_PERIMETRE = "affectation-perimetre",
  TRANSFERTS_RETRAIT = "retrait",
  TRANSFERTS_SUPPRESSION = "suppression",
  POPULATION = "population",
  MANDATS = "mandats",
  DELEGUES = "delegues-assemblee-generale",
  ASSOCIER_PERIMETRE_COMMUNES_ASSOCIEES_DELEGUEES = "associer-perimetre-communes-associees-deleguees",
  GENERER_ETATS_PREFECTURE = "/etat-prefecture/generate",
}
