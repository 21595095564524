import type { ReactNode } from "react";
import { appiConfig, authenticationConfig, config } from "config/app-config";
import { AuthenticationProvider, AppInsightsProvider } from "@sdeapps/react-core";
import authenticatedRouter from "routes/authenticatedRouter";
import { RouterProvider } from "react-router-dom";
import { SDEAppsThemeProvider } from "providers";
import unAuthenticatedRouter from "routes/unAuthenticatedRouter";
import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import { SnackbarProvider } from "notistack";
import { SEARCH_HIGHTLIGHT_CLASS_NAME } from "components/Search";
import { ErrorPage } from "pages/errors";

function App(): ReactNode {
  return (
    <Box
      className="App"
      sx={{
        minHeight: "100vh",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        [`.${SEARCH_HIGHTLIGHT_CLASS_NAME}`]: { color: "info.main" },
      }}>
      <SDEAppsThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
          <AppInsightsProvider
            onError={ErrorPage}
            connectionString={appiConfig.connectionString}
            appVersion={config.appVersion}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              preventDuplicate>
              <AuthenticationProvider
                unauthorizedPage={<RouterProvider router={unAuthenticatedRouter} />}
                config={authenticationConfig}>
                <RouterProvider router={authenticatedRouter} />
              </AuthenticationProvider>
            </SnackbarProvider>
          </AppInsightsProvider>
        </LocalizationProvider>
      </SDEAppsThemeProvider>
    </Box>
  );
}

export default App;
