import { useMemo, useState } from "react";
import type { PropsWithChildren, ReactNode } from "react";
import DisplayDatesContext from "contexts/DisplayDatesContext";
import type { DisplayDatesContextData } from "contexts/DisplayDatesContext";

export function DisplayDatesProvider({ children }: Readonly<PropsWithChildren>): ReactNode {
  const [isDisplayingDates, setIsDisplayingDates] = useState(false);

  const data: DisplayDatesContextData = useMemo(
    () => ({
      isDisplayingDates,
      setIsDisplayingDates,
    }),
    [isDisplayingDates, setIsDisplayingDates]
  );

  return <DisplayDatesContext.Provider value={data}>{children}</DisplayDatesContext.Provider>;
}
