import { useState, useCallback } from "react";
import type { ReactNode, PropsWithChildren } from "react";
import { Backdrop, Box, Grid2 as Grid } from "@mui/material";
import type { SxProps, Theme } from "@mui/material";
import { SearchCategories, SearchInput, SearchResults } from "components/Search/";
import CloseIcon from "@mui/icons-material/Close";

interface SearchInputsContainerProps extends PropsWithChildren {
  isOpen?: boolean;
  sx?: SxProps<Theme>;
}

function SearchInputsContainer({
  isOpen = false,
  sx,
  children,
}: Readonly<SearchInputsContainerProps>): ReactNode {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: { xs: isOpen ? 0 : "147px", md: "216px" },
        right: { xs: isOpen ? 0 : "80px", md: "80px" },
        height: isOpen ? "auto" : { sm: "64px", xs: "56px" },
        overflow: "visible",
        transition: "left .15s ease-out, right .15s ease-out",
        ...sx,
      }}>
      <Grid
        container
        size={12}
        sx={{
          height: { sm: "64px", xs: "56px" },
          background: isOpen ? "white" : "transparent",
          transition: "background 0.1s ease-out",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Grid container size={{ xs: 11, md: 10 }} sx={{ justifyContent: "center" }} spacing={0}>
          <Grid container size={{ xs: 12, md: 10 }}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

interface CloseSearchProps {
  isOpen: boolean;
  handleClose: () => void;
}

function CloseSearch({ isOpen, handleClose }: Readonly<CloseSearchProps>): ReactNode {
  return (
    <>
      <Grid size="auto" sx={{ visibility: { xs: isOpen ? "visible" : "collapse" } }}>
        <Box width="30px" />
      </Grid>
      <Box
        sx={{
          position: "absolute",
          visibility: { xs: isOpen ? "visible" : "collapse" },
          top: { xs: "8px", sm: "12px" },
          right: { xs: "8px", sm: "12px" },
          cursor: "pointer",
        }}
        onClick={handleClose}>
        <CloseIcon color="error" fontSize="large" />
      </Box>
    </>
  );
}

function HeaderSearch(): ReactNode {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <SearchInputsContainer isOpen={isOpen} sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
        <SearchCategories
          onFocus={handleOpen}
          size={{
            sm: isOpen ? 4 : undefined,
            md: 4,
          }}
          sx={{ width: "70px" }}
          inputSize="small"
        />
        <SearchInput onFocus={handleOpen} size={{ xs: "grow", md: 8 }} inputSize="small" />
        <CloseSearch isOpen={isOpen} handleClose={handleClose} />
      </SearchInputsContainer>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isOpen}
        onClick={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: { xs: isOpen ? 0 : "147px", md: "216px" },
            right: { xs: isOpen ? 0 : "80px", md: "80px" },
            transition: "left .15s ease-out, right .15s ease-out",
            background: "none",
            overflowY: "visible",
          }}>
          <Grid
            container
            size={12}
            sx={{
              height: "auto",
              maxHeight: isOpen ? "80vh" : 0,
              marginTop: { xs: "56px", sm: "64px" },
              paddingTop: isOpen ? 1 : 0,
              paddingBottom: isOpen ? 2 : 0,
              overflowY: "auto",
              background: "white",
              transition: "max-height 0.25s ease-out",
              justifyContent: "center",
            }}>
            <Grid container size={{ xs: 11, md: 10 }} justifyContent="center" spacing={2}>
              <SearchResults size={12} />
            </Grid>
          </Grid>
        </Box>
      </Backdrop>
    </>
  );
}

export default HeaderSearch;
