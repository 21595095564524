import type { ReactNode } from "react";
import { CircularProgress, Grid2 as Grid, Typography } from "@mui/material";
import type { Grid2Props as GridProps, SxProps } from "@mui/material";
import { useSearch } from "providers";
import { LazyLoadedSearchResults, BestSearchResults } from "./";

interface SearchResultsProps extends GridProps {
  sx?: SxProps;
}

/**
 * Affiche l'interface (messages, CircularProgress) et les résultats de la recherche.
 * A entourer d'un SearchProvider.
 * @param sx?: SxProps
 */
export function SearchResults({ sx, ...props }: Readonly<SearchResultsProps>): ReactNode {
  const { isDirty, isSearching, isLoading, isNoMatch, filteredResults, bestResults } = useSearch();

  return (
    <Grid {...props} container sx={{ position: "relative", ...sx }} spacing={1}>
      {(isSearching || (isLoading && (isDirty || isNoMatch))) && (
        <Grid
          container
          size={12}
          sx={{
            justifyContent: "center",
            position: "absolute",
            zIndex: (theme) => theme.zIndex.drawer + 3,
          }}>
          <CircularProgress />
        </Grid>
      )}
      {!isNoMatch && filteredResults.length === 0 && bestResults.length === 0 && (
        <Grid size={12} sx={{ textAlign: "center" }}>
          <Typography color="grey">
            Recherchez une Commune, un EPCI, un Périmètre, un Territoire, un Élu...
          </Typography>
        </Grid>
      )}
      <BestSearchResults />
      <LazyLoadedSearchResults initialResultsNumber={40} step={30} />
      {!isLoading && isNoMatch && (
        <Grid size={12} sx={{ textAlign: "center" }}>
          <Typography>
            Nous n'avons trouvé aucun résultat correspondant à votre recherche.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
