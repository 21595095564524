import type { ReactNode } from "react";
import { Button, Grid2 as Grid } from "@mui/material";
import ToastMessages from "constants/ToastMessages";
import { enqueueSnackbar } from "notistack";
import { useErrorHandler } from "utils/errorHandling";
import { excelUtil } from "utils";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

function AgExportButton(): ReactNode {
  const { catchErrors, isLoading } = useErrorHandler({
    dontThrow: true,
    defaultIsLoading: false,
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_RETRY,
      });
    },
  });

  return (
    <Grid>
      <Button
        onClick={() => {
          void catchErrors(excelUtil.createExportAssembleeGenerale);
        }}
        loading={isLoading}
        size="small"
        variant="text"
        color="inherit"
        loadingPosition="start"
        startIcon={<FileDownloadIcon />}>
        Délégués à l'Assemblée Générale
      </Button>
    </Grid>
  );
}

export default AgExportButton;
