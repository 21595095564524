import { Fragment } from "react";
import type { ReactNode } from "react";
import { Box, Grid2 as Grid, Link, Typography } from "@mui/material";
import { routesConfig } from "config/app-config";
import type { Commune, CommuneAssocieeDeleguee } from "models";
import InputSkeleton from "./InputSkeleton";
import type { SdeappsError } from "utils/errorHandling";
import InformationsError from "./Errors/InformationsError";

type CommunePrincipaleProps = {
  commune: Commune;
  communesAssocieesDeleguees?: never;
  isLoading?: boolean;
  error?: SdeappsError;
};

type CommuneFusionneeProps = {
  commune?: never;
  communesAssocieesDeleguees: Array<CommuneAssocieeDeleguee>;
  isLoading?: boolean;
  error?: SdeappsError;
};

type CommuneLieesSubtitleProps = CommunePrincipaleProps | CommuneFusionneeProps;

function CommuneLieesSubtitle({
  commune,
  communesAssocieesDeleguees,
  isLoading = false,
  error,
}: Readonly<CommuneLieesSubtitleProps>): ReactNode {
  if (error != null) {
    return <InformationsError />;
  }

  if (isLoading) {
    return <InputSkeleton />;
  }

  if (commune == null && communesAssocieesDeleguees.length < 1) {
    return null;
  }

  return (
    <Grid container size={12}>
      <Box sx={{ backgroundColor: "info.light", px: 2, py: 1, borderRadius: "5px" }}>
        <Typography component="span" sx={{ fontWeight: "600" }}>
          {commune != null ? (
            <>
              Commune chef-lieu :{" "}
              <Link href={routesConfig.commune.getParameterPath(commune.id)}>
                {commune.libelle}
              </Link>
            </>
          ) : (
            <>
              Communes associées et déléguées :{" "}
              {communesAssocieesDeleguees.map((commune, i, a) => (
                <Fragment key={commune.id}>
                  <Link href={routesConfig.communeAssocieeDeleguee.getParameterPath(commune.id)}>
                    {commune.libelle}
                  </Link>
                  {i < a.length - 1 && ", "}
                </Fragment>
              ))}
            </>
          )}
        </Typography>
      </Box>
    </Grid>
  );
}

export default CommuneLieesSubtitle;
