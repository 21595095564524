import { useEffect, useState } from "react";
import type { SyntheticEvent, ReactNode } from "react";
import { Grid2 as Grid } from "@mui/material";
import Competence from "constants/Competence";
import { arrayUtil } from "@sdeapps/react-core";
import { competencesUtil, porteesUtil } from "utils";
import { withComponentErrorBoundary, useErrorHandler } from "utils/errorHandling";
import type { Transfert } from "models";
import LoadingScreen from "components/Template/LoadingScreen";
import CompetenceTabs from "components/CompetenceTabs";
import { TabContext, TabPanel } from "@mui/lab";
import CompetenceTab from "components/CompetenceTab";
import competenceMap from "constants/CompetenceMap";
import CommuneGceSousCompetences from "./CommuneGceSousCompetences";
import CommuneEauAssGepuSousCompetences from "./CommuneEauAssSousCompetences";

interface CommunePorteesInformationsProps {
  transferts: Array<Transfert>;
}

function getDefaultSelectedTab(transferts: Array<Transfert>): Competence {
  return (
    porteesUtil.getCompetence(
      [...transferts].sort(competencesUtil.sortTransfertByCompetence)?.[0]
    ) ?? Competence.EAU
  );
}

function CommunePorteesInformations({
  transferts,
}: Readonly<CommunePorteesInformationsProps>): ReactNode {
  const [transfertsByCompetence, setTransfertsByCompetence] =
    useState<Record<Competence, Array<Transfert>>>();
  const [tabValue, setTabValue] = useState<Competence>(getDefaultSelectedTab(transferts));
  const [panels, setPanels] = useState<Array<ReactNode>>([]);
  const [tabs, setTabs] = useState<Array<ReactNode>>([]);

  const { catchErrors, isLoading } = useErrorHandler();

  useEffect(() => {
    // Désactivation de la règle ESLint car catchErrors requiert une promesse.
    // eslint-disable-next-line @typescript-eslint/require-await
    async function getPerimetres(): Promise<void> {
      setTransfertsByCompetence(undefined);
      transferts
        .sort((a, b) =>
          `${a.idPerimetre}${a.idEpci ?? ""}`.localeCompare(`${b.idPerimetre}${b.idEpci ?? ""}`)
        )
        .sort(competencesUtil.sortTransfertByCompetence);

      const _transfertsByCompetence = arrayUtil.groupBy(transferts, (o) => o.perimetre.competence);
      setTransfertsByCompetence(_transfertsByCompetence);

      const _panels: Array<ReactNode> = [];
      const _tabs: Array<ReactNode> = [];
      Object.values(Competence).forEach((competence) => {
        const transferts = _transfertsByCompetence[competence];

        if (transferts != null) {
          switch (competence) {
            case Competence.EAU:
            case Competence.ASS:
            case Competence.GEPU:
              _panels.push(
                <TabPanel key={competence} value={competence} sx={{ width: "100%", p: 1 }}>
                  <CommuneEauAssGepuSousCompetences transferts={transferts} />
                </TabPanel>
              );
              break;
            case Competence.GCE:
              _panels.push(
                <TabPanel key={competence} value={competence} sx={{ width: "100%", p: 1 }}>
                  <CommuneGceSousCompetences transferts={transferts} />
                </TabPanel>
              );
              break;
          }

          const cA = competenceMap.get(competence);
          if (cA != null) {
            _tabs.push(
              <CompetenceTab competenceAssociation={cA} value={competence} key={competence} />
            );
          }
        }
      });
      setPanels(_panels);
      setTabs(_tabs);
    }

    void catchErrors(getPerimetres);
  }, [catchErrors, transferts]);

  if (transfertsByCompetence == null) {
    return null;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  function handleTabValueChange(_: SyntheticEvent, newValue: Competence): void {
    setTabValue(newValue);
  }

  return (
    <Grid container spacing={2} size={12}>
      <TabContext value={tabValue}>
        <CompetenceTabs
          value={tabValue}
          onChange={handleTabValueChange}
          panels={panels}
          tabs={tabs}
        />
      </TabContext>
    </Grid>
  );
}

const CommunePorteesInformationsWithErrorBoundary = withComponentErrorBoundary(
  CommunePorteesInformations
);

export default CommunePorteesInformationsWithErrorBoundary;
