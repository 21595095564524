import type { ReactNode } from "react";
import { Grid2 as Grid } from "@mui/material";
import type { Personne } from "models";
import EluLink from "components/Links/EluLink";

interface ElusListProps {
  elus: Array<Personne>;
}

function ElusList({ elus }: Readonly<ElusListProps>): ReactNode {
  return (
    <Grid container spacing={1} size={12} sx={{ alignContent: "flex-start" }}>
      {elus.map((elu) => (
        <Grid key={elu.id} size={12}>
          <EluLink elu={elu} />
        </Grid>
      ))}
    </Grid>
  );
}

export default ElusList;
