import type { ReactNode } from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import { elusUtil } from "utils";
import type Competence from "constants/Competence";
import CompetenceChip from "components/Typography/CompetenceChip";
import type { Mandat } from "models";

interface EluInformationsFonctionProps {
  mandat: Mandat;
  competences?: Array<Competence>;
}

function EluInformationsFonction({
  mandat,
  competences = [],
}: Readonly<EluInformationsFonctionProps>): ReactNode {
  return (
    <Grid container size={12}>
      <Grid size={12}>
        <Typography sx={{ lineHeight: "24px", verticalAlign: "baseline" }}>
          {elusUtil.getLibelleFonction(mandat)}
        </Typography>
      </Grid>
      {competences.length > 0 && (
        <Grid container spacing={1} size={12}>
          {competences?.map((c) => (
            <Grid key={c} size="auto">
              <CompetenceChip competence={c} />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
}

export default EluInformationsFonction;
