import type { ReactNode } from "react";
import { Grid2 as Grid, Skeleton } from "@mui/material";

function EtablissementPublicSkeleton(): ReactNode {
  return (
    <Grid container spacing={2} size={12}>
      <Grid size={12}>
        <Skeleton variant="text" animation="wave" sx={{ height: "30px", width: "100%" }} />
      </Grid>
      <Grid container size={12} sx={{ gap: 1 }}>
        <Skeleton variant="text" animation="wave" sx={{ height: "30px", width: "100%" }} />
      </Grid>
      <Grid container size={12} sx={{ gap: 1 }}>
        <Skeleton variant="text" animation="wave" sx={{ height: "30px", width: "100%" }} />
      </Grid>
      <Grid container size={12} sx={{ gap: 1 }}>
        <Skeleton variant="text" animation="wave" sx={{ height: "30px", width: "100%" }} />
      </Grid>
    </Grid>
  );
}

export default EtablissementPublicSkeleton;
