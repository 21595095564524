import type { ReactNode } from "react";
import { TableCell, TableRow, Typography } from "@mui/material";
import CommuneLink from "components/Links/CommuneLink";
import EluLink from "components/Links/EluLink";
import type Fonctions from "constants/Fonctions";
import type { PerimetreDelegueRow } from "./PerimetreDeleguesSdeaHeader";

interface PerimetreDeleguesSdeaRowProps {
  data: PerimetreDelegueRow;
  importantFonctions?: Array<Fonctions>;
}

function PerimetreDeleguesSdeaRow({
  data,
  importantFonctions = [],
}: Readonly<PerimetreDeleguesSdeaRowProps>): ReactNode {
  return (
    <TableRow>
      <TableCell>
        <EluLink
          elu={data.elu}
          sx={{
            fontWeight:
              (data.mandats?.some((mandat) =>
                importantFonctions?.includes(mandat.idFonction as Fonctions)
              ) ?? false)
                ? "700"
                : undefined,
          }}
        />
      </TableCell>
      <TableCell>
        {data.mandats?.map((mandat) => (
          <Typography
            key={`${data.elu.id}${mandat.idFonction}`}
            sx={{
              fontWeight: importantFonctions?.includes(mandat.idFonction as Fonctions)
                ? "700"
                : undefined,
            }}>
            {mandat.libelleFonction}
          </Typography>
        ))}
      </TableCell>
      <TableCell>{data.commune != null && <CommuneLink commune={data.commune} />}</TableCell>
    </TableRow>
  );
}

export default PerimetreDeleguesSdeaRow;
