import type { ReactNode, PropsWithChildren } from "react";
import { Grid2 as Grid } from "@mui/material";

function BlueChip({ children }: Readonly<PropsWithChildren>): ReactNode {
  return (
    <Grid
      container
      size="auto"
      sx={{
        gap: 1,
        alignItems: "center",
        backgroundColor: "info.light",
        padding: 0.5,
        borderRadius: 1,
      }}>
      {children}
    </Grid>
  );
}

export default BlueChip;
