import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableBodyClasses,
  tableCellClasses,
  tableContainerClasses,
  tableRowClasses,
} from "@mui/material";
import MandatSdeaTableRow from "./MandatSdeaTableRow";
import type { Mandat } from "models";
import { elusUtil } from "utils";
import type Competence from "constants/Competence";
import competenceMap from "constants/CompetenceMap";
import MandatInstitutionnelTableRow from "./MandatInstitutionnelTableRow";
import { Authorization, dateUtil } from "@sdeapps/react-core";
import NewMandatSdeaTableRow from "./NewMandatSdeaTableRow";
import { ApplicationRole } from "constants/ApplicationRole";

interface MandatsInformationsListProps {
  mandats?: Array<Mandat>;
  typeMandats: "SDEA" | "institutionnels";
  noMandatsFallback?: ReactNode;
  cancelDisplayCreateNewMandat?: () => void;
  displayCreateNewMandat?: boolean;
  createMandatEluId?: string;
}

function MandatsInformationsList({
  mandats,
  typeMandats,
  noMandatsFallback,
  displayCreateNewMandat = false,
  createMandatEluId,
  cancelDisplayCreateNewMandat,
}: Readonly<MandatsInformationsListProps>): ReactNode {
  const [orderedMandats, setOrderedMandats] = useState<Array<Mandat>>([]);

  useEffect(() => {
    if (mandats != null) {
      setOrderedMandats(
        [...mandats]
          .sort((a, b) => dateUtil.compareDesc(a.dateDebutMandat, b.dateDebutMandat))
          .sort((a, b) =>
            `${a.libelleTerritoire}${a.libellePerimetre}${a.libelleCollectivite}`.localeCompare(
              `${b.libelleTerritoire}${b.libellePerimetre}${b.libelleCollectivite}`
            )
          )
          .sort(
            (a, b) =>
              // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
              (competenceMap.get(a?.competence as Competence)?.index ?? 0) -
              // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
              (competenceMap.get(b?.competence as Competence)?.index ?? 0)
          )
          .sort(elusUtil.sortMandatsByImportance)
      );
    } else {
      setOrderedMandats([]);
    }
  }, [mandats]);

  if (orderedMandats.length === 0 && !displayCreateNewMandat) {
    return (
      <Typography>
        {noMandatsFallback ?? "Cette personne ne possède aucun mandat de ce type."}
      </Typography>
    );
  }

  return (
    <TableContainer
      sx={{
        [`&.${tableContainerClasses.root} .${tableBodyClasses.root} .${tableRowClasses.root}:last-child .${tableCellClasses.root}`]:
          { border: "none" },
      }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography sx={{ fontWeight: 600 }}>Fonction</Typography>
            </TableCell>
            {typeMandats === "SDEA" && (
              <TableCell>
                <Typography sx={{ fontWeight: 600 }}>Compétence</Typography>
              </TableCell>
            )}
            <TableCell>
              <Typography sx={{ fontWeight: 600 }}>Collectivité</Typography>
            </TableCell>
            <TableCell>
              <Typography sx={{ fontWeight: 600 }}>Période</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {typeMandats === "SDEA"
            ? orderedMandats.map((mandatSdea: Mandat) => (
                <MandatSdeaTableRow
                  key={`${mandatSdea.libelleFonction}${mandatSdea.competence}${mandatSdea.codeCollectivite}${mandatSdea.idPerimetre}${mandatSdea.idTerritoire}${mandatSdea.dateDebutMandat}${mandatSdea.dateFinMandat}`}
                  mandatSdea={mandatSdea}
                />
              ))
            : orderedMandats.map((mandat: Mandat) => (
                <MandatInstitutionnelTableRow
                  key={`${mandat.codeCollectivite}${mandat.libelleFonction}${mandat.dateDebutMandat}`}
                  mandat={mandat}
                />
              ))}
          <Authorization roles={ApplicationRole.ADMINISTRATOR}>
            {displayCreateNewMandat && createMandatEluId != null && (
              <NewMandatSdeaTableRow
                idElu={createMandatEluId}
                cancel={cancelDisplayCreateNewMandat}
              />
            )}
          </Authorization>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MandatsInformationsList;
