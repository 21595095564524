import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Grid2 as Grid } from "@mui/material";
import ConseilMunicipalInformations from "components/Informations/Elus/ConseilMunicipalInformations";
import ElusCommuneInformations from "components/Informations/Elus/ElusCommuneInformations";
import ElusInformationsSkeleton from "components/Informations/Elus/ElusInformationsSkeleton";
import type { Commune, Personne } from "models";
import { elusService } from "services";
import { elusUtil } from "utils";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";

interface CommuneElusInformationsProps {
  commune: Commune;
}

function CommuneElusInformations({ commune }: Readonly<CommuneElusInformationsProps>): ReactNode {
  const [elus, setElus] = useState<Array<Personne>>([]);

  const { catchErrors, isLoading } = useErrorHandler();

  useEffect(() => {
    async function getElus(): Promise<void> {
      const _elus = await elusService.getPersonnesByCommune(commune.id);
      const activeElus = elusUtil.getActiveElusAndMandats(_elus);
      setElus(activeElus);
    }

    void catchErrors(getElus);
  }, [catchErrors, commune]);

  if (isLoading) {
    return <ElusInformationsSkeleton />;
  }

  return (
    <Grid container spacing={2} size={12} sx={{ alignContent: "flex-start" }}>
      <ElusCommuneInformations elus={elus} commune={commune} />
      <ConseilMunicipalInformations elus={elus} />
    </Grid>
  );
}

const CommuneElusInformationsWithErrorBoundary =
  withComponentErrorBoundary(CommuneElusInformations);

export default CommuneElusInformationsWithErrorBoundary;
