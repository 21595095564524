/* eslint-disable max-lines */
import ExcelJS from "exceljs";
import type { Workbook } from "exceljs";
import { SdeappsError } from "./errorHandling/SdeappsError";
import type {
  Commune,
  DelegueAssembleeGenerale,
  EtablissementPublic,
  Mandat,
  Personne,
  PersonneInfosPrivees,
  Perimetre,
  Territoire,
} from "models";
import { arrayUtil, dateUtil } from "@sdeapps/react-core";
import competenceMap from "constants/CompetenceMap";
import { elusService, etablissementsPublicsService, perimetresService } from "services";
import Fonctions from "constants/Fonctions";
import base64Image from "assets/base64LogoSdea";
import Competence from "constants/Competence";
import TypeTransfert from "constants/TypeTransfert";
import { competencesUtil, etablissementUtil, fileUtil } from "utils";

const narrowMargins = {
  left: 0.25,
  right: 0.25,
  top: 0.75,
  bottom: 0.75,
  header: 0.3,
  footer: 0.3,
};

const thinBorder: Partial<ExcelJS.Borders> = {
  top: { style: "thin" },
  left: { style: "thin" },
  bottom: { style: "thin" },
  right: { style: "thin" },
};

const mediumBorder: Partial<ExcelJS.Borders> = {
  top: { style: "medium" },
  left: { style: "medium" },
  bottom: { style: "medium" },
  right: { style: "medium" },
};

async function createFeuillePresencePerimetre(
  perimetre: Perimetre,
  elus: Array<Personne>,
  communes: Array<Commune>
): Promise<void> {
  const _communes = [...communes];
  _communes.sort((a, b) => a.libelle.localeCompare(b.libelle));

  const elusInfosPriveesPromises = elus.map(async (elu) =>
    elusService.getPersonneInfosPriveesById(elu.id)
  );

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Liste de Présence");
  // Marges étroites pour l'impression
  worksheet.pageSetup.margins = narrowMargins;

  // Création et nommage des colonnes
  worksheet.columns = [
    { width: 4 },
    { header: "Commune", key: "commune", width: 20.9, style: { font: { name: "Arial" } } },
    { header: "Name", key: "name", width: 28.1, style: { font: { name: "Arial" } } },
    {
      header: "Fonction",
      key: "fonction",
      width: 12.9,
      outlineLevel: 1,
      style: { font: { name: "Arial" } },
    },
    {
      header: "DateNaissance",
      key: "dateNaissance",
      width: 13.4,
      style: { font: { name: "Arial" } },
    },
    { header: "Signature", key: "signature", width: 19, style: { font: { name: "Arial" } } },
    { header: "Mail", key: "mail", width: 40.5, style: { font: { name: "Arial" } } },
    { header: "Mail Mairie", key: "mailMairie", width: 40, style: { font: { name: "Arial" } } },
  ];

  worksheet.spliceRows(1, 1);
  worksheet.addRow({});
  worksheet.addRow({});

  // TITRES
  const comLocRow = worksheet.addRow({ commune: "COMMISSION LOCALE" });
  comLocRow.font = {
    size: 16,
    bold: true,
    name: "Arial",
  };
  comLocRow.alignment = { horizontal: "center" };
  worksheet.addRow(
    { commune: competenceMap.get(perimetre.competence)?.label.toLocaleUpperCase() },
    "i"
  );
  worksheet.addRow(
    {
      commune: `DU ${perimetre.libelle
        .toLocaleUpperCase()
        .replace("COMMISSION LOCALE", "PERIMETRE")}`,
    },
    "i"
  );
  worksheet.addRow({}, "i");
  const reuRow = worksheet.addRow({ commune: "REUNION DU XX.XX.XX" });
  reuRow.font = {
    size: 12,
    bold: true,
    name: "Arial",
  };
  reuRow.alignment = { horizontal: "center" };
  worksheet.addRow({ commune: "" }, "i");
  worksheet.addRow({ commune: "LISTE DE PRESENCE" }, "i");

  for (let index = 3; index < 10; index++) {
    worksheet.mergeCells(index, 2, index, 6);
  }

  worksheet.addRow({});

  // TABLEAU DES ELUS
  const titleRow = worksheet.addRow({
    commune: "COMMUNE",
    name: "NOM et PRENOM",
    fonction: "FONCTION",
    dateNaissance: "DATE DE NAISSANCE",
    signature: "SIGNATURE",
    mail: "Mail",
    mailMairie: "Mail mairie",
  });
  titleRow.height = 30;
  titleRow.font = {
    bold: true,
    name: "Arial",
  };
  titleRow.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
  setBorderInRowCells(titleRow, 2, 8, thinBorder);

  setPatternInRowCells(titleRow, 2, 8);

  const idElusDejaAffiches: Array<string> = [];
  const elusInfosPrivees = await Promise.all(elusInfosPriveesPromises);

  const allEtablissementsPublics = await etablissementsPublicsService.getAllMairies();

  const etablissementsPublics: Array<EtablissementPublic> = [];

  // On récupère une mairie par commune
  communes.forEach(({ id: communeId }) => {
    const mairies = allEtablissementsPublics.filter((e) => e.codeInsee === communeId);
    if (mairies != null) {
      const [openData, sdea] = etablissementUtil.getEtablissementPublicFromSource(mairies);
      etablissementsPublics.push(MairieForExport(openData, sdea));
    }
  });

  const _elus = [...elus];

  /**
   * Cette fonction créée une nouvelle ligne décrivant l'élu et son rôle
   */
  function setEluRow(elu: Personne, commune?: Commune): void {
    let eluInfosPrivee = elusInfosPrivees.find((infosPrivees) => infosPrivees.id === elu.id);
    eluInfosPrivee ??= { id: elu.id } satisfies PersonneInfosPrivees;
    const row = worksheet.addRow({
      commune: commune?.libelle.toUpperCase(),
      name: `${elu.nom.toLocaleUpperCase()} ${elu.prenom}`,
      fonction: getPerimetreListePresenceRelevantEluFonctionLabel(elu),
      dateNaissance: dateUtil.format(elu.dateNaissance, "dd/MM/yyyy"),
      signature: "",
      mail: getEluMailsLabel(eluInfosPrivee),
      mailMairie: etablissementsPublics.find((ep) => ep.codeInsee === commune?.id)?.email ?? "",
    });
    row.font = { name: "Arial" };
    row.height = 30;
    row.alignment = { vertical: "middle" };
    row.getCell("commune").alignment = { vertical: "middle", wrapText: true };
    row.getCell("name").alignment = { vertical: "middle", wrapText: true };
    row.getCell("dateNaissance").alignment = { vertical: "middle", horizontal: "center" };
    setBorderInRowCells(row, 2, 6, thinBorder);
  }

  // On crée une ligne pour chaque élu qui a une commune
  _communes.forEach((commune) => {
    _elus
      .filter(
        (elu) => elu.mandats?.find((mandat) => mandat.codeCollectivite === commune.id) != null
      )
      .forEach((elu) => {
        idElusDejaAffiches.push(elu.id);
        setEluRow(elu, commune);
      });
  });

  // Puis pour chaque élu qui n'en a pas et qui n'a pas encore de ligne
  _elus
    .filter(
      (elu) =>
        !idElusDejaAffiches.includes(elu.id) &&
        elu.mandats?.find((mandat) => mandat.codeCollectivite == null) != null
    )
    .forEach((elu) => {
      setEluRow(elu);
    });

  worksheet.addRow({});
  const sdeaRow = worksheet.addRow({
    commune: "SDEA",
    name: "NOM et PRENOM",
    signature: "SIGNATURE",
  });
  sdeaRow.font = {
    bold: true,
    name: "Arial",
  };
  sdeaRow.alignment = { vertical: "middle", horizontal: "center" };
  setPatternInRowCells(sdeaRow, 2, 6);
  setBorderInRowCells(sdeaRow, 2, 6, mediumBorder);

  // DEUXIEME TABLEAU
  const dRow = worksheet.addRow({ commune: "Directeur" });
  dRow.height = 30;
  dRow.font = { name: "Arial" };
  dRow.alignment = { vertical: "middle", wrapText: true };
  dRow.font = {
    bold: true,
    name: "Arial",
  };
  setBorderInRowCells(dRow, 2, 6, mediumBorder);

  const gafRow = worksheet.addRow({ commune: "Gestion Administrative et Financière" });
  gafRow.height = 45.75;
  gafRow.alignment = { vertical: "middle", wrapText: true };
  gafRow.font = {
    bold: true,
    name: "Arial",
  };
  setBorderInRowCells(gafRow, 2, 6, mediumBorder);

  const serviceRow = worksheet.addRow({ commune: "Services Techniques" });
  serviceRow.height = 30;
  serviceRow.alignment = { vertical: "middle", wrapText: true };
  serviceRow.font = {
    bold: true,
    name: "Arial",
  };
  setBorderInRowCells(serviceRow, 2, 6, mediumBorder);
  setBorderInRowCells(worksheet.addRow({}, "i"), 2, 6, mediumBorder);
  setBorderInRowCells(worksheet.addRow({}, "i"), 2, 6, mediumBorder);

  for (let index = sdeaRow.number; index < sdeaRow.number + 6; index++) {
    worksheet.mergeCells(index, 3, index, 5);
  }
  worksheet.mergeCells(serviceRow.number, 2, serviceRow.number + 2, 2);

  worksheet.addRow({});

  // TROISIEME TABLEAU
  const invitesRow = worksheet.addRow({
    commune: "INVITES",
    name: "NOM et PRENOM",
    signature: "SIGNATURE",
  });
  invitesRow.font = {
    bold: true,
    name: "Arial",
  };
  invitesRow.alignment = { vertical: "middle", horizontal: "center" };
  setPatternInRowCells(invitesRow, 2, 6);
  setBorderInRowCells(invitesRow, 2, 6, mediumBorder);

  const emptyRow = worksheet.addRow({});
  emptyRow.height = 22.5;
  setBorderInRowCells(emptyRow, 2, 6, mediumBorder);
  setBorderInRowCells(worksheet.addRow({}, "i"), 2, 6, mediumBorder);
  setBorderInRowCells(worksheet.addRow({}, "i"), 2, 6, mediumBorder);
  setBorderInRowCells(worksheet.addRow({}, "i"), 2, 6, mediumBorder);

  for (let index = invitesRow.number; index < invitesRow.number + 5; index++) {
    worksheet.mergeCells(index, 3, index, 5);
  }

  const imageId1 = workbook.addImage({
    base64: base64Image,
    extension: "jpeg",
  });
  worksheet.addImage(imageId1, {
    tl: { col: 1, row: 0 },
    ext: { width: 112, height: 112 },
  });

  await downloadWorkbook(`feuille_presence_${perimetre.libelle.replace(" ", "_")}.xlsx`, workbook);
}

function setBorderInRowCells(
  row: ExcelJS.Row,
  startCell: number,
  stopCell: number,
  border: Partial<ExcelJS.Borders>
): void {
  for (let index = startCell; index < stopCell + 1; index++) {
    row.getCell(index).border = border;
  }
}

function setPatternInRowCells(row: ExcelJS.Row, startCell: number, stopCell: number): void {
  for (let index = startCell; index < stopCell + 1; index++) {
    row.getCell(index).fill = {
      type: "pattern",
      pattern: "gray125",
    };
  }
}

function getEluMailsLabel(eluInfosPrivee: PersonneInfosPrivees): string {
  const mails: Array<string> = [];
  [eluInfosPrivee?.emailPrincipal, eluInfosPrivee?.emailSecondaire].forEach((mail) => {
    if (mail != null) {
      mails.push(mail);
    }
  });
  return mails.join("; ");
}

function getPerimetreListePresenceRelevantEluFonctionLabel(elu: Personne): string {
  if (elu.mandats == null) {
    throw new SdeappsError(`L'elu ${elu.id} n'a pas de mandats !`);
  }
  if (elu.mandats?.some((mandat) => mandat.idFonction === Fonctions.PRESIDENT_COMMISSION_LOCALE)) {
    return "Président";
  }
  if (
    elu.mandats?.some(
      (mandat) => mandat.idFonction === Fonctions.SUPPLEANT_PRESIDENT_COMMISSION_LOCALE
    )
  ) {
    return "Suppléant";
  }
  return "Délégué";
}

/**
 * Permet de faire télécharger à l'utilisateur un workbook Excel.
 * @param filename le nom du fichier que l'utilisateur va télécharger (inclure l'extension .xlsx)
 * @param workbook le workbook à transformer en fichier excel
 */
async function downloadWorkbook(filename: string, workbook: Workbook): Promise<void> {
  const buffer = await workbook.xlsx.writeBuffer();

  fileUtil.downloadStreamAsFile(buffer, filename);
}

interface Delegue {
  personne: DelegueAssembleeGenerale;
  voix: Array<Voix>;
}

interface Voix {
  perimetres: Array<Partial<Perimetre>>;
  telMairie?: string;
  emailmairie?: string;
}

interface FullMandat extends Mandat {
  perimetre?: Perimetre;
  telMairie?: string;
  emailmairie?: string;
}

function MairieForExport(
  etablissementOpenData: EtablissementPublic | undefined,
  etablissementSdea: EtablissementPublic | undefined
): EtablissementPublic {
  if (etablissementOpenData == null && etablissementSdea == null) {
    throw new SdeappsError("L'établissement n'a ni données Open Data, ni données SDEA");
  } else if (etablissementSdea == null) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return etablissementOpenData!;
  } else if (etablissementOpenData == null) {
    return etablissementSdea;
  }

  const mixed: EtablissementPublic = {
    ...etablissementOpenData,
    email: etablissementSdea?.email ?? etablissementOpenData.email,
    telephone: etablissementSdea?.telephone ?? etablissementOpenData.telephone,
    adresse: etablissementSdea?.adresse ?? etablissementOpenData.adresse,
    modifiedBy: etablissementSdea?.modifiedBy ?? undefined,
    source: etablissementSdea?.source ?? etablissementOpenData.source,
    dateModification: etablissementSdea?.dateModification ?? etablissementOpenData.dateModification,
  };
  return mixed;
}

function getFullMandat(
  mandat: Mandat,
  perimetres: Array<Perimetre>,
  etablissementPublics: Array<EtablissementPublic> = []
): FullMandat {
  return {
    ...mandat,
    perimetre: perimetres.find((p) => p.id === mandat.idPerimetre),
    telMairie: etablissementPublics.find((e) => e.codeInsee === mandat.codeCollectivite)?.telephone,
    emailmairie: etablissementPublics.find((e) => e.codeInsee === mandat.codeCollectivite)?.email,
  };
}

// le modèle a plus de trucs que juste Personne, infos privées
async function createExportAssembleeGenerale(): Promise<void> {
  const [elus, perimetres, allEtablissementsPublics] = await Promise.all([
    elusService.getDeleguesAssembleeGenerale(),
    perimetresService.getAll(),
    etablissementsPublicsService.getAllMairies(),
  ]);

  const communesIds: Array<string> = [];
  arrayUtil
    .dedupArray(
      elus.map((e) => e.mandatsDelegueAssembleeGenerale?.map((m) => m.codeCollectivite)).flat()
    )
    .forEach((ci) => {
      if (ci != null) {
        communesIds.push(ci);
      }
    });

  const workbook = new ExcelJS.Workbook();
  const worksheetDAG = workbook.addWorksheet("Délégués à l'Assemblée Générale");
  const worksheetVoix = workbook.addWorksheet("Nombre de voix des délégués");
  const worksheetDAGTerrComp = workbook.addWorksheet("Délégués à l'AG avec territoires");

  // Création et nommage des colonnes
  worksheetDAG.columns = [
    { header: "NomPrenom", key: "nomprenom" },
    { header: "Nom", key: "nom" },
    { header: "Prénom", key: "prenom" },
    { header: "Civilité", key: "civilite" },
    { header: "Civilité 2", key: "civilite2" },
    { header: "Téléphone personnel", key: "tel_perso" },
    { header: "Téléphone portable", key: "tel_port" },
    { header: "Téléphone professionnel", key: "tel_pro" },
    { header: "E-mail", key: "mail1" },
    { header: "E-mail Mairie", key: "mail_mairie" },
    { header: "Adresse", key: "adresse" },
    { header: "CP", key: "cp" },
    { header: "Ville", key: "ville" },
    { header: "Commission Locale / Collectivité", key: "comloc" },
  ];
  worksheetVoix.columns = [
    { header: "Nom", key: "nom" },
    { header: "Prénom", key: "prenom" },
    { header: "Nb de voix", key: "nbVoix" },
  ];
  worksheetDAGTerrComp.columns = [
    { header: "Civilité", key: "civilite" },
    { header: "NomPrenom", key: "nomprenom" },
    { header: "Nom", key: "nom" },
    { header: "Prénom", key: "prenom" },
    { header: "Date de naissance", key: "dateDeNaissance" },
    { header: "Civilité 2", key: "civilite2" },
    { header: "Téléphone personnel", key: "tel_perso" },
    { header: "Téléphone portable", key: "tel_port" },
    { header: "Téléphone professionnel", key: "tel_pro" },
    { header: "E-mail", key: "mail1" },
    { header: "E-mail Mairie", key: "mail_mairie" },
    { header: "Adresse", key: "adresse" },
    { header: "CP", key: "cp" },
    { header: "Ville", key: "ville" },
    { header: "Commission Locale / Collectivité", key: "comloc" },
    { header: "Territoires", key: "territoires" },
  ];

  const etablissementsPublics: Array<EtablissementPublic> = [];

  // On récupère une mairie par commune
  communesIds.forEach((communeId) => {
    const mairies = allEtablissementsPublics.filter((e) => e.codeInsee === communeId);
    if (mairies != null) {
      const [openData, sdea] = etablissementUtil.getEtablissementPublicFromSource(mairies);
      etablissementsPublics.push(MairieForExport(openData, sdea));
    }
  });

  const delegues: Array<Delegue> = [];

  elus.forEach((elu) => {
    const voix = getEluVoix(
      elu.mandatsDelegueAssembleeGenerale ?? [],
      perimetres,
      etablissementsPublics
    );
    if (voix.length > 0) {
      delegues.push({
        personne: elu,
        voix,
      });
    }
  });

  delegues.sort((d1, d2) =>
    `${d1.personne.nom} ${d1.personne.prenom}`
      .toLowerCase()
      .localeCompare(`${d2.personne.nom} ${d2.personne.prenom}`.toLowerCase())
  );

  delegues.forEach((delegue) => {
    delegue.voix.forEach((voix) => {
      const perimetresDisplay = arrayUtil
        .groupByArray(voix.perimetres, (p) => p.libelle ?? "autre")
        .map(getPerimetreAndCompetencesLibelle)
        .join(", ");

      worksheetDAG.addRow({
        nomprenom: `${delegue.personne.nom} ${delegue.personne.prenom}`,
        nom: delegue.personne.nom,
        prenom: delegue.personne.prenom,
        civilite: delegue.personne.sexe === "F" ? "Mme" : "M",
        civilite2: delegue.personne.sexe === "F" ? "Madame la Déléguée" : "Monsieur le Délégué",
        adresse: delegue.personne.infosPrivees?.rue,
        cp: delegue.personne.infosPrivees?.codePostal,
        ville: delegue.personne.infosPrivees?.commune,
        tel_perso: delegue.personne.infosPrivees?.telephonePersonnel,
        tel_port: delegue.personne.infosPrivees?.telephonePortable,
        tel_pro: voix?.telMairie,
        mail1: delegue.personne.infosPrivees?.emailPrincipal,
        mail_mairie: voix?.emailmairie,
        comloc: perimetresDisplay,
      });
    });

    worksheetVoix.addRow({
      nom: delegue.personne.nom,
      prenom: delegue.personne.prenom,
      nbVoix: delegue.voix.length,
    });

    const telPro = delegue.voix[0].telMairie;
    const mailMairie = delegue.voix[0].emailmairie;

    const perimetres: Array<Partial<Perimetre>> = delegue.voix.flatMap((v) => v.perimetres);
    const perimetresDisplay = arrayUtil
      .groupByArray(perimetres, (perimetre) => perimetre.libelle ?? "autre")
      .map(getPerimetreAndCompetencesLibelle)
      .sort((perimetreA, perimetreB) => perimetreA.localeCompare(perimetreB))
      .join(", ");
    const territoires = delegue.voix.flatMap((v) =>
      v.perimetres
        .flatMap((perimetre) => perimetre.territoire)
        .filter((territoire): territoire is Territoire => territoire != null)
    );
    const territoiresDisplay = arrayUtil
      .groupByArray(territoires, (territoire) => territoire.libelle)
      .map((territoire) => territoire[0].libelle)
      .sort((territoireA, territoireB) => territoireA.localeCompare(territoireB))
      .join("; ");

    worksheetDAGTerrComp.addRow({
      nomprenom: `${delegue.personne.nom} ${delegue.personne.prenom}`,
      nom: delegue.personne.nom,
      prenom: delegue.personne.prenom,
      dateDeNaissance: dateUtil.format(delegue.personne.dateNaissance, "dd/MM/yyyy"),
      civilite: delegue.personne.sexe === "F" ? "Mme" : "M",
      civilite2: delegue.personne.sexe === "F" ? "Madame la Déléguée" : "Monsieur le Délégué",
      adresse: delegue.personne.infosPrivees?.rue,
      cp: delegue.personne.infosPrivees?.codePostal,
      ville: delegue.personne.infosPrivees?.commune,
      tel_perso: delegue.personne.infosPrivees?.telephonePersonnel,
      tel_port: delegue.personne.infosPrivees?.telephonePortable,
      tel_pro: telPro,
      mail1: delegue.personne.infosPrivees?.emailPrincipal,
      mail_mairie: mailMairie,
      comloc: perimetresDisplay,
      territoires: territoiresDisplay,
    });
  });

  await downloadWorkbook(
    `delegues_assemblee_generale_${dateUtil.format(undefined, "yyyy-MM-dd")}.xlsx`,
    workbook
  );
}

function getPerimetreAndCompetencesLibelle(arrp: Array<Partial<Perimetre>>): string {
  return `${arrp[0].libelle} (${arrp.map((p) => p?.competence?.toUpperCase()).join(", ")})`;
}

/**
 * Détermine le nombre de voix d'un élu à partir de ses mandats de délégué à l'Assemblée Générale
 * @param mandats liste des Mandats de Délégué à l'Assemblée Générale de l'élu
 * @param perimetres liste des Périmètres concernés
 * @returns La liste des voix de l'élu
 */
function getEluVoix(
  mandats: Array<Mandat>,
  perimetres: Array<Perimetre>,
  etablissementsPublics: Array<EtablissementPublic> = []
): Array<Voix> {
  const fullMandats = mandats
    .map((m) => getFullMandat(m, perimetres, etablissementsPublics))
    .sort(competencesUtil.sortMandatsByCompetence);

  let voix: Array<Voix> = [];

  // Une voix par compétence parmis les Périmètres PI dans les mandats de délégué à l'AG de l'élu.
  voix = voix.concat(getPIVoix(fullMandats));

  // Une voix par groupe de Périmètre(s) PPI avec le même nom, si une compétence correspondante
  // n'existe pas déjà dans les voix PI.
  addPPIVoix(fullMandats, voix);

  // Exception departement du bas-rhin : une seule voix quel que soit le nombre de mandats (> 0).
  voix = voix.concat(getBasRhinVoix(fullMandats));

  return voix;
}

/**
 * Calcul du nombre de voix d'un élu pour les périmètres PI sur la base de ses mandats:
 *
 * Une voix par compétence parmis les Périmètres PI dans les mandats de délégué à l'AG de l'élu.
 * @param fullMandats liste des Mandats de Délégué à l'Assemblée Générale (avec leurs périmètres) de l'élu
 * @returns La liste des voix PI de l'élu
 */
function getPIVoix(fullMandats: Array<FullMandat>): Array<Voix> {
  const voix: Array<Voix> = [];

  Object.values(Competence).forEach((competence) => {
    const competencePIMandats = fullMandats.filter(
      (m) =>
        m.perimetre?.typeTransfert === TypeTransfert.PI && m.perimetre?.competence === competence
    );

    if (competencePIMandats.length > 0) {
      voix.push({
        perimetres: competencePIMandats.map(getPerimetreFromFullMandat),
        emailmairie: competencePIMandats?.[0]?.emailmairie,
        telMairie: competencePIMandats?.[0]?.telMairie,
      });
    }
  });

  return voix;
}

/**
 * Calcul du nombre de voix d'un élu pour les périmètres PPI sur la base de ses mandats:
 *
 * Une voix par groupe de Périmètre(s) PPI avec le même nom, si une compétence correspondante n'existe pas déjà dans les voix PI.
 * Dans le cas contraire, on ajoute le périmètre dans la voix préexistante.
 * @param fullMandats liste des Mandats de Délégué à l'Assemblée Générale (avec leurs périmètres) de l'élu
 * @param voix liste des voix des périmètres PI pour cet élu
 * @returns La liste des voix PPI de l'élu
 */
function addPPIVoix(fullMandats: Array<FullMandat>, voix: Array<Voix> = []): void {
  const _PPIMandats = fullMandats.filter((m) => m.perimetre?.typeTransfert === TypeTransfert.PPI);

  // On regroupe les mandats par noms de périmètre
  const PPIMandatsByPerimetre = arrayUtil.groupByArray(
    _PPIMandats,
    (m) => m.perimetre?.libelle ?? ""
  );

  // Pour chaque groupe
  PPIMandatsByPerimetre.forEach((PPIMandats) => {
    if (PPIMandats.length > 0) {
      const _competencesPPI: Array<Competence> = [];
      PPIMandats.forEach((m) => {
        if (m.perimetre?.competence != null) {
          _competencesPPI.push(m.perimetre.competence);
        }
      });

      const previousVoixCompetences: Array<Competence | undefined> = voix.map(
        (v) => v.perimetres[0]?.competence
      );
      let hasPPIvoix = false;
      _competencesPPI.forEach((competencePPI) => {
        hasPPIvoix ||= !previousVoixCompetences.includes(competencePPI);
      });

      // Si une des compétences du groupe n'est pas présente dans les PI, on ajoute le groupe comme nouvelle voix
      if (hasPPIvoix) {
        voix.push({
          perimetres: PPIMandats.map(getPerimetreFromFullMandat),
          emailmairie: PPIMandats?.[0]?.emailmairie,
          telMairie: PPIMandats?.[0]?.telMairie,
        });
      } else {
        // Sinon, on ajoute les périmètres du groupe aux voix préexistantes des compétences correspondantes
        PPIMandats.forEach((m) => {
          const voixConcerneeIndex = voix.findIndex(
            (v) => v.perimetres[0].competence === m.perimetre?.competence
          );

          if (voixConcerneeIndex !== -1) {
            voix[voixConcerneeIndex].perimetres.push(getPerimetreFromFullMandat(m));
          }
        });
      }
    }
  });
}

/**
 * Calcul du nombre de voix d'un élu pour le département du Bas-Rhin (périmètre bas-rhin) sur la base de ses mandats:
 * Méthode provisoire tant que l'application ne gère pas les transferts sur les régions / départements.
 * Exception departement du Bas-Rhin : une seule voix quel que soit le nombre de mandats (> 0).
 * @param fullMandats liste des Mandats de Délégué à l'Assemblée Générale (avec leurs périmètres) de l'élu
 * @returns La liste des voix du département du Bas-Rhin de l'élu
 */
function getBasRhinVoix(fullMandats: Array<FullMandat>): Array<Voix> {
  const voix: Array<Voix> = [];

  const basRhinMandats = fullMandats.filter(
    (m) => m.idPerimetre === "59009" || m.idPerimetre === "59010"
  );
  if (basRhinMandats.length > 0) {
    voix.push({
      perimetres: basRhinMandats.map(getPerimetreFromFullMandat),
      emailmairie: basRhinMandats?.[0]?.emailmairie,
      telMairie: basRhinMandats?.[0]?.telMairie,
    });
  }

  return voix;
}

function getPerimetreFromFullMandat(mandat: FullMandat): Partial<Perimetre> {
  return (
    mandat.perimetre ?? {
      id: mandat.idPerimetre,
      libelle: mandat.libellePerimetre,
      competence: mandat.competence,
    }
  );
}

async function createExportMandatsAssembleeGeneraleAvecPerimetreDissous(
  elus: Array<DelegueAssembleeGenerale>,
  mandatsAuxPerimetresDissous: Array<Mandat>
): Promise<void> {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Mandats de DAG avec Perimetre dissous");

  // Création et nommage des colonnes
  worksheet.columns = [
    { header: "Nom", key: "nom" },
    { header: "Prénom", key: "prenom" },
    { header: "Ville", key: "ville" },
    { header: "Commission Locale / Collectivité", key: "comloc" },
  ];

  mandatsAuxPerimetresDissous.forEach((mandat) => {
    const personne = elus.find((e) => e.id === mandat.idPersonne);
    worksheet.addRow({
      nom: personne?.nom,
      prenom: personne?.prenom,
      ville: personne?.infosPrivees?.commune,
      comloc: mandat.libellePerimetre,
    });
  });

  await downloadWorkbook(
    `mandats_dag_au_perimetres_dissous_${dateUtil.format(undefined, "yyyy-MM-dd")}.xlsx`,
    workbook
  );
}

export const excelUtil = {
  createFeuillePresencePerimetre,
  createExportAssembleeGenerale,
  createExportMandatsAssembleeGeneraleAvecPerimetreDissous,
};
