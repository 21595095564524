import { useEffect, useRef, useState } from "react";
import type { ReactNode } from "react";
import { MenuItem, TextField } from "@mui/material";
import { useController } from "react-hook-form";
import type { FieldValues, FieldPath } from "react-hook-form";
import type { ControlledInput } from "utils";

export type ControlledTextFieldOptions = Array<{
  active: boolean;
  value: string;
  label: string;
}>;

interface ControlledTextFieldProps<T extends FieldValues, Name extends FieldPath<T>>
  extends ControlledInput<T, Name> {
  label: string;
  placeholder?: string;
  readOnly?: boolean;
  defaultHelperText?: ReactNode;
  select?: boolean;
  options?: ControlledTextFieldOptions;
  children?: ReactNode;
  variant?: "outlined" | "standard" | "filled";
  type?: React.HTMLInputTypeAttribute;
  multiline?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

/**
 * Un Textfield (ou Select) de mui controllé par react-hook-forms.
 * @param name obligatoire - Nom unique de l'input
 * @param control obligatoire - objet Control du formulaire react-hook-forms
 * https://react-hook-form.com/api/useform/control
 * @param label obligatoire - label de l'input
 * @param defaultValue valeur par défaut de l'input
 * @param placeholder placeholder de l'input, par défaut même valeur que le label
 * @param readOnly booléen déterminant si l'input en lecture seule
 * @param rules règles de validation de l'input au même format que
 * les options de la méthode register() de react-hook-forms
 * https://react-hook-form.com/api/useform/register#options
 * @param defaultHelperText - texte d'aide à afficher lorsque le champ n'est
 * pas en erreur
 * @param select - booléen déterminant si l'input est un select ou un champ texte
 * @param options - un array de type ControlledTextFieldOptions contenant les différentes
 * options disponibles pour le select, pouvant contenir d'anciennes données (active:false)
 * qui ne seront pas proposées aux utilisateurs dans la liste, mais dont le label sera tout
 * de même affiché si la valeur a été sélectionnée dans le passé
 * @param children - les éléments enfants de ce composant sont passés au TextField
 * (pratique pour les MenuItem d'un Select par exemple)
 * @param variant - variante graphique de l'input "outlined" | "standard" |
 * "filled", par défaut "outlined"
 * @param multiline - booléen déterminant si l'input peut s'étendre sur
 * plusieurs lignes
 * @param fullWidth - booléen déterminant si l'input prend toute la place
 * horizontale disponible
 * @returns Un TextField ou Select de mui controllé par react-hook-forms
 */
function ControlledTextField<T extends FieldValues, Name extends FieldPath<T>>({
  name,
  control,
  label,
  defaultValue,
  placeholder = label,
  readOnly = false,
  rules,
  defaultHelperText,
  select = false,
  options,
  children,
  variant = "outlined",
  type,
  multiline = true,
  fullWidth = true,
  disabled = false,
}: Readonly<ControlledTextFieldProps<T, Name>>): ReactNode {
  const [open, setOpen] = useState(false);
  const previousOpen = useRef(false);

  const {
    field: { value, onChange, ref },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  useEffect(() => {
    previousOpen.current = open;
  }, [open]);

  function handleClose(): void {
    setOpen(false);
  }

  function handleOpen(): void {
    if (!readOnly) {
      setOpen(true);
    }
  }

  return (
    <TextField
      disabled={disabled}
      value={value ?? ""}
      onChange={onChange}
      inputRef={ref}
      label={label}
      type={type}
      placeholder={placeholder}
      {...(select && {
        SelectProps: {
          open,
          onFocus: () => {
            if (!previousOpen.current) {
              handleOpen();
            }
          },
          onBlur: handleClose,
          onClose: handleClose,
          onOpen: handleOpen,
        },
        select: true,
      })}
      slotProps={{ input: { readOnly } }}
      error={error != null}
      helperText={error?.message ?? defaultHelperText}
      variant={variant}
      multiline={multiline}
      fullWidth={fullWidth}>
      {children}
      {options
        ?.filter((o) => o.active || o.value === value)
        ?.map((ouvrageType) => (
          <MenuItem key={ouvrageType.value} value={ouvrageType.value}>
            {ouvrageType.label}
          </MenuItem>
        ))}
    </TextField>
  );
}

export default ControlledTextField;
