import type { ReactNode } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Grid2 as Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import type { Transfert } from "models";
import EauAssGepuSousCompetencesAccordionDetailsWithDates from "./EauAssGepuSousCompetencesAccordionDetailsWithDates";
import EauAssGepuSousCompetencesAccordionDetails from "./SousCompetenceAccordionRow/EauAssGepuSousCompetencesAccordionDetails";
import { useDisplayDates } from "hooks";

interface EauAssGepuSousCompetencesAccordionProps {
  transferts: Array<Transfert>;
  summary: ReactNode;
  defaultExpanded?: boolean;
}

function EauAssGepuSousCompetencesAccordion({
  transferts,
  summary,
  defaultExpanded = false,
}: Readonly<EauAssGepuSousCompetencesAccordionProps>): ReactNode {
  const { isDisplayingDates } = useDisplayDates();

  return (
    <Grid size={12}>
      <Accordion sx={{ width: "100%" }} defaultExpanded={defaultExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ flexDirection: "row-reverse" }}>
          {summary}
        </AccordionSummary>
        <AccordionDetails>
          {isDisplayingDates ? (
            <EauAssGepuSousCompetencesAccordionDetailsWithDates transferts={transferts} />
          ) : (
            <EauAssGepuSousCompetencesAccordionDetails transferts={transferts} />
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

export default EauAssGepuSousCompetencesAccordion;
