import { Fragment } from "react";
import type { ReactNode } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableBodyClasses,
  tableCellClasses,
  tableContainerClasses,
  tableRowClasses,
} from "@mui/material";
import type { Transfert } from "models";
import GceContrats from "constants/GceContrats";
import { porteesUtil } from "utils";
import { dateUtil } from "@sdeapps/react-core";

interface GceSousCompetencesAccordionDetailsProps {
  transfertsByBassinVersants: Array<Array<Transfert>>;
}

function GceSousCompetencesAccordionDetailsWithDates({
  transfertsByBassinVersants,
}: Readonly<GceSousCompetencesAccordionDetailsProps>): ReactNode {
  return (
    <TableContainer
      sx={{
        [`&.${tableContainerClasses.root} .${tableBodyClasses.root} .${tableRowClasses.root}:last-child .${tableCellClasses.root}`]:
          { border: "none" },
      }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width="25px" sx={{ paddingRight: 0 }} />
            <TableCell>
              <Typography sx={{ fontWeight: "600" }}>Alinéas</Typography>
            </TableCell>
            <TableCell width="200px">
              <Typography sx={{ fontWeight: "600" }}>Délibération</Typography>
            </TableCell>
            <TableCell width="200px">
              <Typography sx={{ fontWeight: "600" }}>Début de transfert</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transfertsByBassinVersants.map((transfertByBassinVersant) => (
            <Fragment key={transfertByBassinVersant[0].id}>
              <TableRow>
                <TableCell sx={{ paddingRight: 0 }} />
                <TableCell colSpan={3}>
                  <Typography sx={{ fontWeight: "600" }}>
                    {transfertByBassinVersant[0].bassinVersant != null
                      ? `Bassin versant ${transfertByBassinVersant[0].bassinVersant} `
                      : "Tous les bassins versants"}
                  </Typography>
                </TableCell>
              </TableRow>
              {transfertByBassinVersant.map((transfert) => {
                const gceContrat = GceContrats.find(
                  (c) => c.code === porteesUtil.getSousCompetenceId(transfert.code)
                );
                return (
                  <TableRow hover key={transfert.id}>
                    <TableCell sx={{ paddingRight: 0 }}>
                      <Typography component="span" sx={{ color: "gce.dark", fontWeight: 800 }}>
                        {gceContrat?.numero}°
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{gceContrat?.label}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {dateUtil.format(transfert?.dateDeliberation, "dd/MM/yyyy")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {dateUtil.format(transfert?.datePremierTransfert, "dd/MM/yyyy")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default GceSousCompetencesAccordionDetailsWithDates;
