import type { ReactNode } from "react";
import { Grid2 as Grid, Skeleton } from "@mui/material";

function ElusListSkeleton(): ReactNode {
  return (
    <Grid container spacing={2} size={12}>
      {[0, 1, 2, 3].map((v) => (
        <Grid container key={v} size={12}>
          <Grid container size={12}>
            <Skeleton variant="text" sx={{ width: "50%" }} />
          </Grid>
          <Grid container size={12}>
            <Skeleton variant="text" sx={{ width: "30%" }} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default ElusListSkeleton;
