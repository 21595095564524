import type { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import { SearchProvider } from "providers";
import { useDocumentTitle } from "hooks";

function Root(): ReactNode {
  useDocumentTitle();

  return (
    <SearchProvider>
      <Header />
      <Outlet />
      <Footer />
    </SearchProvider>
  );
}

export default Root;
