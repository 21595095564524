import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import EpciLink from "components/Links/EpciLink";
import TerritoireLink from "components/Links/TerritoireLink";
import competenceMap from "constants/CompetenceMap";
import { competencesUtil } from "utils";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import type { SdeappsError } from "utils/errorHandling";
import type { Transfert } from "models";
import PerimetreLinkWithErrorBoundary from "./Links/PerimetreLink";
import { arrayUtil } from "@sdeapps/react-core";

function dedupTransferts(transferts: Array<Transfert>): Array<Transfert> {
  const _dedupTransferts = arrayUtil.dedupArray(transferts, (t) => `${t.idPerimetre}${t.idEpci}`);
  return _dedupTransferts;
}

interface PerimetresInformationProps {
  transferts: Array<Transfert>;
  error?: SdeappsError;
  pointOfView: "commune" | "epci";
}

function PerimetresInformations({
  transferts: originalTransferts,
  error,
  pointOfView,
}: Readonly<PerimetresInformationProps>): ReactNode {
  const [transferts, setTransferts] = useState<Array<Transfert>>([]);

  const { setOriginalError } = useErrorHandler();

  useEffect(() => {
    if (error != null) {
      setOriginalError(error);
    }
  }, [error, setOriginalError]);

  useEffect(() => {
    const _transferts = [...originalTransferts];
    _transferts
      .sort((a, b) =>
        `${a?.perimetre?.libelle ?? a.idPerimetre}${
          a?.epci?.libelle ?? a.idEpci ?? ""
        }`.localeCompare(
          `${b?.perimetre?.libelle ?? b.idPerimetre}${b?.epci?.libelle ?? b.idEpci ?? ""}`
        )
      )
      .sort(competencesUtil.sortTransfertByCompetence);

    setTransferts(dedupTransferts(_transferts));
  }, [originalTransferts]);

  return (
    <Grid container spacing={2} size={12}>
      {transferts.map((transfert) => {
        const competenceAssoc = competenceMap.get(transfert.perimetre?.competence);
        if (competenceAssoc == null) {
          return null;
        }

        return (
          <Grid
            key={`${competenceAssoc.competence}${transfert?.idEpci}${transfert.idPerimetre}`}
            container
            size={12}
            sx={{ columnGap: 2, alignItems: "center" }}>
            <Grid size="auto">
              <competenceAssoc.icon />
            </Grid>
            <Grid container size="grow">
              <Grid size={12}>
                <PerimetreLinkWithErrorBoundary
                  color={`${competenceAssoc.competence.toLowerCase()}.dark`}
                  perimetre={transfert.perimetre}
                />
                <Typography>
                  Adhésion{" "}
                  {pointOfView === "commune" && transfert?.idEpci != null ? (
                    <>
                      <strong>indirecte</strong> via{" "}
                      <EpciLink id={transfert.idEpci} libelle={transfert?.epci?.libelle} />
                    </>
                  ) : (
                    <strong>directe</strong>
                  )}
                </Typography>
                <TerritoireLink fontSize="0.9rem" territoire={transfert?.perimetre?.territoire} />
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}

const PerimetresInformationsWithErrorBoundary = withComponentErrorBoundary(PerimetresInformations);

export default PerimetresInformationsWithErrorBoundary;
