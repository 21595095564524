/* eslint-disable @typescript-eslint/no-empty-function */
import { useContext, useEffect, useMemo, useState, useCallback, createContext } from "react";
import type { PropsWithChildren, ReactNode } from "react";
import { useDebounce } from "use-debounce";
import type {
  CollectiviteSearchItem,
  Perimetre,
  Personne,
  Territoire,
  Epci,
  CommuneAssocieeDeleguee,
  Commune,
} from "models";
import {
  communesService,
  epcisService,
  territoiresService,
  perimetresService,
  elusService,
  communesAssocieesDelegueesService,
} from "services";
import { epcisUtil } from "utils";
import { useErrorHandler } from "utils/errorHandling";
import type { ErrorHandlingConfig, SdeappsError } from "utils/errorHandling";
import ToastMessages from "constants/ToastMessages";
import { enqueueSnackbar } from "notistack";
import Fuse from "fuse.js";
import type { FuseResult } from "fuse.js";
import { stringUtil } from "@sdeapps/react-core";

export interface ISearchData {
  isDirty: boolean;
  isLoading: boolean;
  isSearching: boolean;
  isNoMatch: boolean;
  filteredResults: Array<FuseResult<CollectiviteSearchItem>>;
  bestResults: Array<FuseResult<CollectiviteSearchItem>>;
  searchString: string;
  setSearchString: (s: string) => void;
  searchCategories: Array<CollectiviteSearchItem["type"]>;
  setSearchCategories: (a: Array<CollectiviteSearchItem["type"]>) => void;
  addSearchCategories: (
    a: Array<CollectiviteSearchItem["type"]> | CollectiviteSearchItem["type"]
  ) => void;
  removeSearchCategories: (
    a: Array<CollectiviteSearchItem["type"]> | CollectiviteSearchItem["type"]
  ) => void;
  toggleSearchCategories: (
    a: Array<CollectiviteSearchItem["type"]> | CollectiviteSearchItem["type"]
  ) => void;
  debouncedSearchString: string;
  update: VoidFunction;
  addOptimisticPerimetre: (p: Perimetre) => void;
  error: SdeappsError | undefined;
}

const data: ISearchData = {
  isDirty: false,
  isLoading: true,
  isSearching: false,
  isNoMatch: false,
  filteredResults: [],
  bestResults: [],
  searchString: "",
  setSearchString: () => {},
  searchCategories: [],
  setSearchCategories: () => {},
  addSearchCategories: () => {},
  removeSearchCategories: () => {},
  toggleSearchCategories: () => {},
  debouncedSearchString: "",
  update: () => {},
  addOptimisticPerimetre: () => {},
  error: undefined,
};

const SearchContext = createContext(data);

interface SearchProviderProps extends PropsWithChildren {}

const MIN_SEARCH_CHAR_NUMBER = 1;

function perimetreToSearchItem(perimetre: Perimetre): CollectiviteSearchItem {
  return {
    type: "PERIMETRE",
    data: perimetre,
    libelle: perimetre.libelle,
    libelleNormalise: stringUtil.normalize(perimetre.libelle),
    key: perimetre.id,
  };
}

function communeToSearchItem(commune: Commune): CollectiviteSearchItem {
  return {
    type: "COMMUNE",
    data: commune,
    libelle: commune.libelle,
    libelleNormalise: stringUtil.normalize(commune.libelle),
    key: `${commune.id}${commune.libelle}COMMUNE`,
  };
}

function communeAssDegToSearchItem(communeAssDeg: CommuneAssocieeDeleguee): CollectiviteSearchItem {
  return {
    type: "COMMUNEASSDEG",
    data: communeAssDeg,
    libelle: communeAssDeg.libelle,
    libelleNormalise: stringUtil.normalize(communeAssDeg.libelle),
    key: `${communeAssDeg.id}${communeAssDeg.libelle}COMMUNEASSDEG`,
  };
}

function epciToSearchItem(epci: Epci): CollectiviteSearchItem {
  return {
    type: "EPCI",
    data: epci,
    libelle: epcisUtil.ToFullName(epci.libelle),
    libelleNormalise: stringUtil.normalize(epcisUtil.ToFullName(epci.libelle)),
    key: epci.id,
  };
}

function territoireToSearchItem(territoire: Territoire): CollectiviteSearchItem {
  return {
    type: "TERRITOIRE",
    data: territoire,
    libelle: territoire.libelle,
    libelleNormalise: stringUtil.normalize(territoire.libelle),
    key: territoire.id,
  };
}

function eluToSearchItem(elu: Personne): CollectiviteSearchItem {
  return {
    type: "ELU",
    data: elu,
    libelle: `${elu.nom} ${elu.prenom}`,
    libelleNormalise: stringUtil.normalize(`${elu.nom} ${elu.prenom}`),
    key: elu.id,
  };
}

function getCommonErrorHandlerOptions(toastMessage: string): ErrorHandlingConfig {
  return {
    dontThrow: true,
    default: (_error) => {
      enqueueSnackbar({
        variant: "error",
        message: toastMessage,
      });
      console.error(_error);
    },
  };
}

const csi: Array<CollectiviteSearchItem> = [];
const fuse = new Fuse(csi, {
  keys: ["libelleNormalise"],
  threshold: 0.35,
  distance: 300,
  includeMatches: true,
  includeScore: true,
  useExtendedSearch: true,
});

export function SearchProvider({ children }: Readonly<SearchProviderProps>): ReactNode {
  const [searchString, setSearchString] = useState<string>("");
  const [debouncedSearchString] = useDebounce(searchString, 500);
  const [filteredResults, setFilteredResults] = useState<Array<FuseResult<CollectiviteSearchItem>>>(
    []
  );
  const [bestResults, setBestResults] = useState<Array<FuseResult<CollectiviteSearchItem>>>([]);

  const [isDirty, setIsDirty] = useState(false);

  const [searchCategories, setSearchCategories] = useState<Array<CollectiviteSearchItem["type"]>>(
    []
  );
  const [searchCommunes, setSearchCommunes] = useState<Array<CollectiviteSearchItem>>([]);
  const [searchCommunesAssDeg, setSearchCommunesAssDeg] = useState<Array<CollectiviteSearchItem>>(
    []
  );
  const [searchEpcis, setSearchEpcis] = useState<Array<CollectiviteSearchItem>>([]);
  const [searchTerritoires, setSearchTerritoires] = useState<Array<CollectiviteSearchItem>>([]);
  const [searchPerimetres, setSearchPerimetres] = useState<Array<CollectiviteSearchItem>>([]);
  const [searchElus, setSearchElus] = useState<Array<CollectiviteSearchItem>>([]);

  const {
    error: communesError,
    catchErrors: catchCommunesErrors,
    isLoading: isCommunesLoading,
  } = useErrorHandler(getCommonErrorHandlerOptions(ToastMessages.SEARCH_ERROR_COMMUNES));
  const {
    error: communesAssDegError,
    catchErrors: catchCommunesAssDegErrors,
    isLoading: isCommunesAssDegLoading,
  } = useErrorHandler(getCommonErrorHandlerOptions(ToastMessages.SEARCH_ERROR_COMMUNES));
  const {
    error: epcisError,
    catchErrors: catchEpcisErrors,
    isLoading: isEpcisLoading,
  } = useErrorHandler(getCommonErrorHandlerOptions(ToastMessages.SEARCH_ERROR_EPCIS));
  const {
    error: territoiresError,
    catchErrors: catchTerritoiresErrors,
    isLoading: isTerritoiresLoading,
  } = useErrorHandler(getCommonErrorHandlerOptions(ToastMessages.SEARCH_ERROR_TERRITOIRES));
  const {
    error: perimetresError,
    catchErrors: catchPerimetresErrors,
    isLoading: isPerimetresLoading,
  } = useErrorHandler(getCommonErrorHandlerOptions(ToastMessages.SEARCH_ERROR_PERIMETRES));
  const {
    error: elusError,
    catchErrors: catchElusErrors,
    isLoading: isElusLoading,
  } = useErrorHandler(getCommonErrorHandlerOptions(ToastMessages.SEARCH_ERROR_ELUS));

  const addSearchCategories = useCallback(
    (categories: Array<CollectiviteSearchItem["type"]> | CollectiviteSearchItem["type"]): void => {
      if (Array.isArray(categories)) {
        setSearchCategories([...searchCategories, ...categories]);
      } else {
        setSearchCategories([...searchCategories, categories]);
      }
    },
    [searchCategories]
  );

  const removeSearchCategories = useCallback(
    (categories: Array<CollectiviteSearchItem["type"]> | CollectiviteSearchItem["type"]): void => {
      if (Array.isArray(categories)) {
        const _searchCategories = [...searchCategories];
        categories.forEach((element) => {
          _searchCategories.splice(_searchCategories.indexOf(element), 1);
        });
        setSearchCategories(_searchCategories);
      } else {
        const _searchCategories = [...searchCategories];
        _searchCategories.splice(searchCategories.indexOf(categories), 1);
        setSearchCategories(_searchCategories);
      }
    },
    [searchCategories]
  );

  const toggleSearchCategories = useCallback(
    (categories: Array<CollectiviteSearchItem["type"]> | CollectiviteSearchItem["type"]): void => {
      if (
        Array.isArray(categories)
          ? searchCategories.includes(categories[0])
          : searchCategories.includes(categories)
      ) {
        removeSearchCategories(categories);
      } else {
        addSearchCategories(categories);
      }
    },
    [addSearchCategories, removeSearchCategories, searchCategories]
  );

  useEffect(() => {
    const searchItems = [
      ...searchCommunes,
      ...searchCommunesAssDeg,
      ...searchEpcis,
      ...searchTerritoires,
      ...searchPerimetres,
      ...searchElus,
    ].sort((a, b) => a.libelle.localeCompare(b.libelle));

    fuse.setCollection(searchItems);
  }, [
    searchCommunes,
    searchCommunesAssDeg,
    searchElus,
    searchEpcis,
    searchPerimetres,
    searchTerritoires,
  ]);

  useEffect(() => {
    if (debouncedSearchString.length > MIN_SEARCH_CHAR_NUMBER) {
      const fuzzyResults = fuse
        .search(stringUtil.normalize(debouncedSearchString))
        .filter(
          (result) => searchCategories.length === 0 || searchCategories.includes(result.item.type)
        );

      setFilteredResults(fuzzyResults);
      setBestResults(fuzzyResults.filter(({ score }) => (score ?? 99) < 0.1).slice(0, 3));
    } else {
      setFilteredResults([]);
      setBestResults([]);
    }
  }, [
    debouncedSearchString,
    searchCategories,
    isCommunesLoading,
    searchCommunes,
    searchCommunesAssDeg,
    searchElus,
    searchEpcis,
    searchPerimetres,
    searchTerritoires,
  ]);

  const updateAll = useCallback((): void => {
    async function getCommunes(): Promise<void> {
      const communes = await communesService.getAllInAlsaceMoselle();
      setSearchCommunes(communes.map(communeToSearchItem));
    }

    async function getCommunesAssDeg(): Promise<void> {
      const communesAssDeg = await communesAssocieesDelegueesService.getAll();
      setSearchCommunesAssDeg(communesAssDeg.map(communeAssDegToSearchItem));
    }

    async function getEpcis(): Promise<void> {
      const epcis = await epcisService.getAll();
      setSearchEpcis(
        epcis
          .filter(
            (e) => e.departementSiege != null && ["57", "67", "68"].includes(e.departementSiege.id)
          )
          .map(epciToSearchItem)
      );
    }

    async function getTerritoires(): Promise<void> {
      const territoires = await territoiresService.getAll();
      setSearchTerritoires(territoires.map(territoireToSearchItem));
    }

    async function getPerimetres(): Promise<void> {
      const perimetres = await perimetresService.getAll();
      setSearchPerimetres(perimetres.map(perimetreToSearchItem));
    }

    async function getElus(): Promise<void> {
      const elus = await elusService.getAllPersonnes();
      setSearchElus(elus.map(eluToSearchItem));
    }

    void catchCommunesErrors(getCommunes);
    void catchCommunesAssDegErrors(getCommunesAssDeg);
    void catchEpcisErrors(getEpcis);
    void catchTerritoiresErrors(getTerritoires);
    void catchPerimetresErrors(getPerimetres);
    void catchElusErrors(getElus);
  }, [
    catchCommunesAssDegErrors,
    catchCommunesErrors,
    catchElusErrors,
    catchEpcisErrors,
    catchPerimetresErrors,
    catchTerritoiresErrors,
  ]);

  const addOptimisticPerimetre = useCallback(
    (optimisticPerimetre: Perimetre): void => {
      setSearchPerimetres([...searchPerimetres, perimetreToSearchItem(optimisticPerimetre)]);
    },
    [searchPerimetres]
  );

  const isSearching = useMemo(
    () => searchString.length > MIN_SEARCH_CHAR_NUMBER && debouncedSearchString !== searchString,
    [searchString, debouncedSearchString]
  );

  const isNoMatch = useMemo(
    () =>
      debouncedSearchString === searchString &&
      filteredResults.length === 0 &&
      bestResults.length === 0 &&
      searchString.length > MIN_SEARCH_CHAR_NUMBER,
    [debouncedSearchString, searchString, filteredResults.length, bestResults.length]
  );

  useEffect(() => {
    if (isSearching && !isDirty) {
      setIsDirty(true);
    }
  }, [isDirty, isSearching]);

  useEffect(() => {
    updateAll();
  }, [updateAll]);

  const data: ISearchData = useMemo(
    () => ({
      isDirty,
      isLoading:
        isCommunesLoading ||
        isCommunesAssDegLoading ||
        isEpcisLoading ||
        isTerritoiresLoading ||
        isPerimetresLoading ||
        isElusLoading,
      isSearching,
      isNoMatch,
      filteredResults,
      bestResults,
      searchString,
      setSearchString,
      searchCategories,
      setSearchCategories,
      addSearchCategories,
      removeSearchCategories,
      toggleSearchCategories,
      debouncedSearchString,
      update: updateAll,
      addOptimisticPerimetre,
      error:
        communesError ??
        communesAssDegError ??
        epcisError ??
        territoiresError ??
        perimetresError ??
        elusError,
    }),
    [
      isDirty,
      isCommunesLoading,
      isCommunesAssDegLoading,
      isEpcisLoading,
      isTerritoiresLoading,
      isPerimetresLoading,
      isElusLoading,
      isSearching,
      isNoMatch,
      filteredResults,
      bestResults,
      searchString,
      searchCategories,
      addSearchCategories,
      removeSearchCategories,
      toggleSearchCategories,
      debouncedSearchString,
      updateAll,
      addOptimisticPerimetre,
      communesError,
      communesAssDegError,
      epcisError,
      territoiresError,
      perimetresError,
      elusError,
    ]
  );

  return <SearchContext.Provider value={data}>{children}</SearchContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useSearch(): ISearchData {
  const context = useContext(SearchContext);

  if (context == null) {
    throw new Error("useSearch must be used within a SearchProvider");
  }

  return context;
}
