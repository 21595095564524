import type { ReactNode } from "react";
import { Container } from "@mui/material";
import MainSearch from "./components/MainSearch";

function HomePage(): ReactNode {
  return (
    <Container maxWidth="xl">
      <MainSearch />
    </Container>
  );
}

export default HomePage;
