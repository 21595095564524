import type { ReactNode } from "react";
import { Skeleton, Typography } from "@mui/material";
import type { SxProps, TypographyProps } from "@mui/material";

interface TypographySkeletonProps extends TypographyProps {
  sx?: SxProps;
}

function TypographySkeleton({
  sx,
  ...typographyProps
}: Readonly<TypographySkeletonProps>): ReactNode {
  return (
    <Typography component="span" {...typographyProps}>
      <Skeleton sx={sx} />
    </Typography>
  );
}

export default TypographySkeleton;
