import type { ReactNode } from "react";
import { Button, Link } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

interface NavigationButtonProps {
  libelle: string;
  icon?: ReactNode;
  href: string;
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  variant?: "text" | "outlined" | "contained";
}
export function NavigationButton({
  libelle,
  icon,
  href,
  color = "primary",
  variant = "contained",
}: Readonly<NavigationButtonProps>): ReactNode {
  return (
    <Button
      LinkComponent={Link}
      href={href}
      variant={variant}
      color={color}
      startIcon={icon ?? <AddIcon />}>
      {libelle}
    </Button>
  );
}
