import type { ReactNode } from "react";
import { Link, MenuItem, Typography } from "@mui/material";
import type { MenuProps } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import LogoutIcon from "@mui/icons-material/Logout";
import { Authorization, authenticationService } from "@sdeapps/react-core";
import { ApplicationRole } from "constants/ApplicationRole";
import ContextualMenu from "../ContextualMenu";
import { apiConfig, config, routesConfig } from "config/app-config";
import { grey } from "@mui/material/colors";

function logout(): void {
  void authenticationService.logout();
}

/** Menu contextuel du profil utilisateur */
function UserMenu(props: Readonly<MenuProps>): ReactNode {
  function close(): void {
    try {
      if (props?.onClose != null) {
        props?.onClose(new Event("navigate"), "backdropClick");
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <ContextualMenu {...props}>
      <Authorization roles={ApplicationRole.ADMINISTRATOR}>
        <MenuItem component={Link} href={routesConfig.admin.path} onClick={close}>
          <AdminPanelSettingsIcon color="primary" sx={{ mr: 1 }} />
          Administration
        </MenuItem>
      </Authorization>
      <Authorization roles={ApplicationRole.REPORTS_READ_ALL}>
        <MenuItem
          component={Link}
          href={apiConfig.powerBiUrls.activeData}
          target="_blank"
          onClick={close}>
          <AnalyticsIcon color="warning" sx={{ mr: 1 }} />
          Power BI
        </MenuItem>
        <MenuItem
          component={Link}
          href={apiConfig.powerBiUrls.transfersHistory}
          target="_blank"
          onClick={close}>
          <AnalyticsIcon color="warning" sx={{ mr: 1 }} />
          Historique des transferts
        </MenuItem>
      </Authorization>
      <Authorization roles={ApplicationRole.ADMINISTRATOR}>
        <MenuItem
          component={Link}
          href={apiConfig.powerBiUrls.anomalies}
          target="_blank"
          onClick={close}>
          <AnalyticsIcon color="warning" sx={{ mr: 1 }} />
          Tableau de bord des anomalies
        </MenuItem>
      </Authorization>
      <MenuItem onClick={logout}>
        <LogoutIcon color="error" sx={{ mr: 1 }} />
        Déconnexion
      </MenuItem>
      <Typography variant="body2" sx={{ color: grey[500], paddingX: 2 }}>
        Version de build : {config.appVersion ?? "local"}
      </Typography>
    </ContextualMenu>
  );
}

export default UserMenu;
