import type { ReactNode } from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import type { Grid2Props as GridProps, SxProps } from "@mui/material";
import { useSearch } from "providers";
import { SearchResult } from "./";

interface BestSearchResultsProps extends GridProps {
  sx?: SxProps;
}

/**
 * Affiche les meilleurs résultats de la recherche.
 * A entourer d'un SearchProvider.
 * @param sx?: SxProps
 */
export function BestSearchResults({ sx, ...props }: Readonly<BestSearchResultsProps>): ReactNode {
  const { bestResults } = useSearch();

  if (bestResults.length === 0) {
    return null;
  }

  return (
    <Grid {...props} container spacing={1} size={12}>
      <Grid size={12}>
        <Typography sx={{ fontWeight: "600" }}>
          {bestResults.length > 1 ? "Meilleurs résultats" : "Meilleur résultat"}
        </Typography>
      </Grid>
      <Grid container spacing={1} size={12}>
        {bestResults.map((f) => (
          <SearchResult key={f.item.key} result={f} />
        ))}
      </Grid>
    </Grid>
  );
}
