import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Grid2 as Grid, Skeleton, type SxProps, Typography } from "@mui/material";
import { useSearch } from "providers/SearchProvider";
import { LazyLoader, SearchResult } from "./";

interface LazyLoadedSearchResultsProps {
  sx?: SxProps;
  initialResultsNumber?: number;
  step?: number;
}

const DEFAULT_NUMBER_OF_RESULTS_TO_DISPLAY = 20;
const INCREASE_STEP_NUMBER_OF_RESULTS_TO_DISPLAY = 20;

/**
 * Affiche l'interface (messages, CircularProgress) et les résultats de la recherche.
 * A entourer d'un SearchProvider.
 * @param sx?: SxProps
 */
export function LazyLoadedSearchResults({
  sx,
  initialResultsNumber = DEFAULT_NUMBER_OF_RESULTS_TO_DISPLAY,
  step = INCREASE_STEP_NUMBER_OF_RESULTS_TO_DISPLAY,
}: Readonly<LazyLoadedSearchResultsProps>): ReactNode {
  const { filteredResults, bestResults } = useSearch();
  const [numberOfResultsToDisplay, setNumberOfResultsToDisplay] = useState(initialResultsNumber);

  useEffect(() => {
    setNumberOfResultsToDisplay(initialResultsNumber);
  }, [filteredResults, initialResultsNumber]);

  function increaseNumberOfResultsToDisplay(): void {
    if (numberOfResultsToDisplay === filteredResults.length) {
      return;
    }

    const filteredLength: number = filteredResults.length;
    const newNumberOfResultsToDisplay: number =
      numberOfResultsToDisplay > filteredLength ? filteredLength : numberOfResultsToDisplay + step;

    setNumberOfResultsToDisplay(newNumberOfResultsToDisplay);
  }

  return (
    <>
      {filteredResults.length > 0 && (
        <Grid container size={12}>
          <Typography>
            <Typography component="span" sx={{ fontWeight: "600" }}>
              {filteredResults.length - bestResults.length}
            </Typography>{" "}
            {bestResults.length > 0 && (filteredResults.length > 1 ? "autres " : "autre ")}
            {filteredResults.length > 1 ? "résultats trouvés" : "résultat trouvé"}
          </Typography>
        </Grid>
      )}
      <Grid container spacing={1} sx={{ position: "relative", ...sx }} size={12}>
        {filteredResults.slice(bestResults.length, numberOfResultsToDisplay).map((fuseResult) => (
          <SearchResult key={fuseResult.item.key} result={fuseResult} />
        ))}
        <LazyLoader lazyLoad={increaseNumberOfResultsToDisplay} />
        {filteredResults.length > numberOfResultsToDisplay &&
          [0, 1, 2, 3, 4, 5, 6].map((i) => (
            <Grid key={i} size={12}>
              <Skeleton variant="rounded" sx={{ width: "100%", height: "80px" }} />
            </Grid>
          ))}
        <LazyLoader lazyLoad={increaseNumberOfResultsToDisplay} sx={{ bottom: "10vh" }} />
      </Grid>
    </>
  );
}
