import { TableCell, Typography } from "@mui/material";
import type { ReactNode } from "react";
import { dateUtil } from "@sdeapps/react-core";

interface DatesRowsProps {
  dates: Array<string | Date | undefined>;
}

function displayDateIfNotUndefined(date: string | Date | undefined): ReactNode {
  return <>{date != null && dateUtil.format(date, "dd/MM/yyyy")}</>;
}

function DatesRows({ dates }: Readonly<DatesRowsProps>): ReactNode {
  return (
    <>
      {dates.map((date) => (
        <TableCell key={String(date)}>
          <Typography>{displayDateIfNotUndefined(date)}</Typography>
        </TableCell>
      ))}
    </>
  );
}

export default DatesRows;
